import { baseColors } from 'src/utils/constants/baseStyles';

class WrapperViewModel {
    private styles = {
        whiteBox: {
            backgroundColor: baseColors.white,
            height: '90vh',
            // minHeight: '100%',
            width: '100%',
            padding: 2,
            boxSizing: 'border-box',
            overflowY: 'auto',
            borderRadius: 3,
        },
    };

    public getStyles() {
        return this.styles;
    }
}

export default WrapperViewModel;
