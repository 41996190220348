import { Avatar, Box, IconButton } from '@mui/material';
import { createRef, useContext, useEffect, useState } from 'react';
import defaultImage from 'src/assets/icons/default-avatar.jpg';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { ALLOWED_PROFILE_IMAGE_EXTENSION } from 'src/utils/constants/constants';

const UploadPhoto = (props: any) => {
    let { urlPhoto, onUploadedPhoto } = props;
    const [file, setFile] = useState<any>();
    const [docType, setDocType] = useState<string>('');
    const [photo, setPhoto] = useState<string>(() => defaultImage);
    const documentRef = createRef<HTMLInputElement>();
    const reader = new FileReader();
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);

    const styles = {
        avatar: {
            margin: 'auto',
            width: '120px',
            height: '120px',
        },
    };
    useEffect(() => {
        urlPhoto && setPhoto(urlPhoto);
    }, [urlPhoto]);

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        // console.log(files);
        if (files && files?.length > 0) {
            let tempFile = files[0];
            if (!ALLOWED_PROFILE_IMAGE_EXTENSION.includes(tempFile.type)) {
                showErrorSnackBar('Image non supportée (veuillez importer des fichier .jpeg, .jpg, .png, .ico, .svg, .gif');
            } else {
                setFile(tempFile);
                setDocType(tempFile.type)
            }
        }
    };

    useEffect(() => {
        if (file) {
            // console.log("teset 2");
            reader.onload = _handleDocReaderLoaded;
            reader.readAsBinaryString(file as Blob);
        }
    }, [docType, file]);

    const _handleDocReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = 'data:' + docType + ';base64,' + btoa(binaryString);
        onUploadedPhoto(fileContent);
        setPhoto(fileContent);
    };
    return (
        <>
            <Box>
                <IconButton onClick={() => documentRef.current?.click()}>
                    {photo && <Avatar alt="Remy Sharp" src={photo} sx={styles.avatar} />}
                </IconButton>
            </Box>
            <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
        </>
    );
};

export { UploadPhoto };