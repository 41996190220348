import { Box } from '@mui/system';
import { CustomButton } from 'src/components/Button/CustomButton';
import ModuleListViewModel from './ModuleListViewModel';
import { v4 as uuid } from 'uuid';

export const ModuleCreationButtonPanel = (props: any) => {
    const { handleClick } = props;
    const vm = new ModuleListViewModel();
    const styles = vm.getStyles();
    const id = uuid();
    const label = 'Créer un module';

    return (
        <Box sx={styles.buttonPanel}>
            <Box sx={styles.buttonContainer}>
                <CustomButton
                    label={label}
                    id={label + id}
                    width="100%"
                    onClick={handleClick}
                    fontSize={12}
                    lower={true}
                />
            </Box>
        </Box>
    );
};
