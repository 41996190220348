import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { CustomCheckbox } from 'src/components/Checkbox/CustomCheckbox';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

interface QuizzProps {
    quizz: any;
    trameField: Array<any>;
    triggerInput: Function;
}
const EducativeBilanQuizzTypeCheckbox: FC<QuizzProps> = (props: any) => {
    const { quizz, trameField, triggerInput } = props;
    let numberCheckbox: any;

    numberCheckbox = quizz.choices.map((choice: any) => {
        return trameField.answer.includes(choice.text);
    });

    const [checkboxValues, setCheckboxValues] = useState(numberCheckbox);

    const handleCheckboxChange = (event: any, i: any) => {
        let newCheckboxValues = [...checkboxValues];
        newCheckboxValues[i] = event.target.checked;
        setCheckboxValues(newCheckboxValues);
    };

    const formAnswer = (arrayChoices: [any], arrayValues: [boolean]) => {
        let obj = arrayChoices.filter((choice, i) => arrayValues[i]);
        return arrayChoices.filter((choice, i) => arrayValues[i]);
    };

    useEffect(() => {
        let objs = formAnswer(quizz.choices, checkboxValues);
        let answers: [String?];
        answers = [];
        objs.forEach((element: any) => {
            answers.push(element.text);
        });
        triggerInput(answers, quizz.id);
    }, [checkboxValues]);

    return (
        <>
            <Box
                p="25px"
                bgcolor={baseColors.green.light}
                borderRadius="10px"
                sx={{
                    fontFamily: baseFontFamily.OpenSansBold,
                    fontSize: 14,
                }}
            >
                {quizz.text}
            </Box>
            <Spacer height={10} />
            {quizz.choices.map((choice: any, i: number) => (
                <>
                    <CustomCheckbox
                        key={i}
                        label={choice.text}
                        value={checkboxValues[i]}
                        handleChange={(event: any) => handleCheckboxChange(event, i)}
                        color={baseColors.green.primary}
                    ></CustomCheckbox>
                    <Spacer height={10} />
                </>
            ))}
            <Spacer  height={15}/>
        </>
    );
};

export default EducativeBilanQuizzTypeCheckbox;
