import { baseColors } from 'src/utils/constants/baseStyles';

class HeaderViewModel {
    private style = {
        appBar: {
            display: 'flex',
            height: 75,
            backgroundColor: 'white',
            justifyContent: 'space-between',
            flexDirection: 'row',
            boxShadow: 'none',
        },
        logo: {
            //     backgroundColor: 'red',
            width: '30vh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            paddingTop: '5px',
        },
        rightMenu: {
            width: '60vh',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
                // backgroundColor: 'red',
        },
        profile: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        profileImage: {
            width: 50,
            height: 50,
            marginTop: 1,
        },
        userName: {
            color: baseColors.green.dark,
            fontWeight: 'bold',
            fontSize: '12pt',
            transform: 'translate(-6.5%, 0%)',
            //     backgroundColor: 'yellow',
        },
        expand: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'stretch',
            flexDirection: 'column',
            //     backgroundColor: 'yellow',
        },
        expandMoreIcon: {
            color: baseColors.green.dark,
        },
    };

    public getStyles() {
        return this.style;
    }
}

export default HeaderViewModel;
