import { createContext, useContext } from 'react';
import { MenuIconName } from 'src/utils/constants/constants';

export type SidebarContent = {
    selectedPanel: string;
    setSelectedPanel?: any;
};

export const SidebarContext = createContext<SidebarContent>({
    selectedPanel: MenuIconName.BILAN,
    setSelectedPanel: '',
});

export const useSidebarContext = () => useContext(SidebarContext);
