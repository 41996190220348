import { baseColors } from 'src/utils/constants/baseStyles';

export class QuizzPanelViewModel {
    private styles = {
        addFieldBox: {
            textAlign: 'center'
        }
    };

    public getStyles() {
        return this.styles;
    }
}
