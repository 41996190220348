import { BilanIcon } from 'src/components/Icon/BilanIcon';
import { SelectedDashboardIcon } from 'src/components/Icon/SelectedDashboardIcon';
import { EducativeBilanIcon } from 'src/components/Icon/EducativeBilanIcon';
import { SelectedEducativeBilanIcon } from 'src/components/Icon/SelectedEducativeBilanIcon';
import { StudentProgressIcon } from 'src/components/Icon/StudentProgressIcon';
import { SelectedToolBoxIcon } from 'src/components/Icon/SelectedToolBoxIcon';
import { SelectedWorkshopIcon } from 'src/components/Icon/SelectedWorkshopIcon';
import { ToolBoxIcon } from 'src/components/Icon/ToolBoxIcon';
import { WorkshopIcon } from 'src/components/Icon/WorkshopIcon';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { MenuIconName } from 'src/utils/constants/constants';
import { SettingIcon } from 'src/components/Icon/SettingIcon';
import { SelectedSettingIcon } from 'src/components/Icon/SelectedSettingIcon';
import { UserSettingIcon } from 'src/components/Icon/UserSettingIcon';

class SidebarMenuViewModel {
    private style = {
        sidebarMenuItem: {
            display: 'flex',
            flexDirection: 'row',
            // margin: '1px',
            marginY: '10px',
            marginLeft: '0px',
            marginRight: '0px',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: baseColors.dark.light,
            },
        },
        sidebarMenuItemSelected: {
            display: 'flex',
            flexDirection: 'row',
            // margin: '1px',
            marginY: '10px',
            marginLeft: '0px',
            marginRight: '0px',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        title: {
            fontFamily: baseFontFamily.OpenSansRegular,
            fontSize: 12,
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            marginLeft: '12px',
            marginBottom: '7px',
        },
        selectedTitle: {
            fontFamily: baseFontFamily.OpenSansSemiBold,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            marginLeft: '12px',
            marginBottom: '7px',
        },
        titleBlock: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '10px',
            marginY: '5px',
        },
        indicator: {
            width: '4px',
            height: '75%',
            backgroundColor: baseColors.green.dark,
            borderRadius: '2px',
        },
        hiddenIndicator: {
            width: '5px',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: '2px',
        },
    };

    public parseIcon(icon: string) {
        let menuIcon = null;
        switch (icon) {
            case MenuIconName.DASHBOARD:
                menuIcon = <SelectedDashboardIcon width="45" height="45" color=' #AEDDDD' />;
                break;  
            case MenuIconName.SELECTED_DASHBOARD:
                menuIcon = <SelectedDashboardIcon width="45" height="45" color={baseColors.green.primary}/>;
                break;
            case MenuIconName.BILAN:
                menuIcon = <SelectedEducativeBilanIcon width="45" height="45" />;
                break;
            case MenuIconName.SELECTED_BILAN:
                menuIcon = <SelectedEducativeBilanIcon width="45" height="45" color={baseColors.green.primary} />;
                break;
            case MenuIconName.WORKSHOP:
                menuIcon = <SelectedWorkshopIcon width="45" height="45" color={baseColors.green.light} />;
                break;
            case MenuIconName.SELECTED_WORKSHOP:
                menuIcon = <SelectedWorkshopIcon width="45" height="45" color={baseColors.green.primary} />;
                break;
            case MenuIconName.TOOL_BOX:
                menuIcon = <SelectedToolBoxIcon width="45" height="45" color={baseColors.green.light} />;
                break;
            case MenuIconName.SELECTED_TOOL_BOX:
                menuIcon = <SelectedToolBoxIcon width="45" height="45" color={baseColors.green.primary} />;
                break;
            case MenuIconName.STUDENT_PROGRESS:
                menuIcon = <StudentProgressIcon width="45" height="45" />;
                break;
            case MenuIconName.STUDENT_LIST:
                menuIcon = <StudentProgressIcon width="45" height="45" />;
                break;
            case MenuIconName.SETTING:
                menuIcon = <SelectedSettingIcon width="45" height="45" color={baseColors.green.light} />;
                break;
            case MenuIconName.SELECTED_SETTING:
                menuIcon = <SelectedSettingIcon width="45" height="45" color={baseColors.green.primary} />;
                break;
            case MenuIconName.USER:
                menuIcon = <UserSettingIcon width="45" height="45" />;
                break;
            case MenuIconName.SELECTED_USER:
                menuIcon = <UserSettingIcon width="45" height="45" />;
                break;

            default:
                menuIcon = <BilanIcon width="45" height="45" />;
                break;
        }
        return menuIcon;
    }

    public getStyles() {
        return this.style;
    }

    public handleIndicator(selected: boolean) {
        return selected ? this.style.indicator : this.style.hiddenIndicator;
    }
}

export default SidebarMenuViewModel;
