import { CustomButton } from 'src/components/Button/CustomButton';
import { InputField } from 'src/components/InputField/InputField';

interface Props {
    title: string;
    description: string;
    onTitleChange: any;
    onDescriptionChange: any;
    saveModification: any;
    setModifyModule: any;
}

const UpdateModuleInformation = (props: Props) => {
    const { title, description, onTitleChange, onDescriptionChange, saveModification, setModifyModule } = props;

    const annulerModification = () => {
        setModifyModule(false);
    };
    return (
        <>
            <div>
                <InputField
                    label={'Titre du module : '}
                    placeholder="Titre"
                    value={title}
                    onChange={onTitleChange}
                    width={'50%'}
                    height={'70px'}
                    labelSize={'1rem'}
                />

                <InputField
                    label={'Description : '}
                    placeholder="Description"
                    value={description}
                    onChange={onDescriptionChange}
                    width={'50%'}
                    height={'200px'}
                    labelSize={'1rem'}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <CustomButton
                    width={'15%'}
                    label={'Annuler'}
                    fontSize={16}
                    lower={true}
                    onClick={annulerModification}
                ></CustomButton>
                <CustomButton
                    width={'15%'}
                    label={'Enregistrer'}
                    fontSize={16}
                    lower={true}
                    onClick={saveModification}
                ></CustomButton>
            </div>
        </>
    );
};

export default UpdateModuleInformation;
