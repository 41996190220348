import { Box } from '@mui/system';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { GET_MODULE } from 'src/graphql/queries/module.queries';
import ModuleDetailHeader from './ModuleDetailHeader';
import ModuleDetailTab from './ModuleDetailTab';
import ModuleDetailViewModel from './ModuleDetailViewModel';
import { Paper, Typography } from '@mui/material';
import { TabPanelContainer } from './TabPanel/TabPanelContainer';
import { useQuery } from '@apollo/client';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { AuthorizationCode } from 'src/utils/constants/constants';
import ModuleDetailStudentPanel from './ModuleDetailStudent/ModuleDetailStudentPanel';
import { ModuleType } from 'src/utils/constants/constants';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import ModuleDetailTabCreate from './ModuleDetailTabCreate';
import { ModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import { useSelector } from 'react-redux';
import { selectCurrentModule } from 'src/redux/features/listModules/listModulesSlice';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
interface IModuleDetailPanelProps {
    moduleType: ModuleType;
    // moduleId: string;
    setSelectedModuleId: Dispatch<SetStateAction<string>>;
    disableSoumettreButton?: boolean;
    setDisableSoumettreButton?: Dispatch<SetStateAction<boolean>>;
}

const moduleDetailPanelProps: IModuleDetailPanelProps = {
    moduleType: ModuleType.EDUCATIVE_BILAN,
    // moduleId: '1',
    setSelectedModuleId: () => {},
    setDisableSoumettreButton: () => {},
    disableSoumettreButton: false,
};

const ModuleDetailPanel = (props: IModuleDetailPanelProps = moduleDetailPanelProps) => {
    const vm = new ModuleDetailViewModel();
    const styles = vm.getStyles();
    const { moduleType, setSelectedModuleId, disableSoumettreButton, setDisableSoumettreButton } = props;
    const { isCreatingNewModule } = useContext(ModuleDetailContext);

    const { codes } = useAuthorizationContext();
    const isAuthorizedToSeeFirstVideo = codes?.includes(AuthorizationCode.FRONT_DISPLAY_EDUCATIVE_BILAN_FIRST_VIDEO);
    const isAuthorizedToSeeModuleDetailPanel = codes?.includes(
        AuthorizationCode.FRONT_DISPLAY_EDUCATIVE_BILAN_MODULE_DETAIL,
    );

    const module = useSelector(selectCurrentModule);

    return (
        <Box
            sx={{
                ...styles.detailContainer,
                '@media screen and (max-width: 750px)': {
                    height: '100vh',
                    width: '100%',
                },
            }}
        >
            <Paper sx={styles.detailContent}>
                {!isCreatingNewModule && module?.id != '' ? (
                    // there is modules in backend but the user don't click on the create button
                    <>
                        {isAuthorizedToSeeModuleDetailPanel && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <ModuleDetailHeader
                                        isCreatingNewModule={isCreatingNewModule}
                                        disableSoumettreButton={disableSoumettreButton}
                                        setDisableSoumettreButton={setDisableSoumettreButton}
                                    />
                                </div>
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        height: '100%',
                                        borderRadius: '5px',
                                        padding: '25px',
                                        marginTop: '8px',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '72vh',
                                            overflowY: 'auto',
                                            paddingRight: '10px',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <ModuleDetailTab />
                                        <TabPanelContainer moduleType={moduleType} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {isAuthorizedToSeeFirstVideo && <ModuleDetailStudentPanel moduleType={moduleType} />}
                    </>
                ) : (
                    // No data means that there is no module in the backend
                    <>
                        {isAuthorizedToSeeModuleDetailPanel &&
                            (isCreatingNewModule ? (
                                //Create new module
                                <>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <ModuleDetailHeader
                                            isCreatingNewModule={isCreatingNewModule}
                                            disableSoumettreButton={disableSoumettreButton}
                                            setDisableSoumettreButton={setDisableSoumettreButton}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            height: '100%',
                                            borderRadius: '5px',
                                            padding: '25px',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <ModuleDetailTabCreate />
                                        <TabPanelContainer
                                            setSelectedModuleId={setSelectedModuleId}
                                            moduleType={moduleType}
                                        />
                                    </div>
                                </>
                            ) : (
                                // There is no module and the content here appears in the first screen
                                <div>
                                    <Typography fontFamily={baseFontFamily.OpenSansRegular} fontSize={10}>
                                        Aucun module pour le moment, vous pouvez en créer un avec le boutton créer un
                                        module
                                    </Typography>
                                </div>
                            ))}
                    </>
                )}
            </Paper>
        </Box>
    );
};

export default ModuleDetailPanel;
