const baseColors = {
    green: {
        primary: '#3EABAB',
        secondary: '#5BB8B8',
        dark: '#1B8E95',
        cancel: '#0D4447',
        pastel: '#64C7C7',
        light: '#E6F4F4',
        lightPastel: '#00B0AC',
    },
    orange: {
        primary: '#F39041',
        secondary: '#F5A15D',
        flash: '#FF821F',
        dark: '#EB6800',
        light: '#FFF9F5',
    },
    dark: {
        light: 'rgba(160, 160, 160, 0.09)'
    },
    white: '#FFFFFF',
    black: '#0D4447',
    grey: '#BFC8C9',
    red: '#E82F3E',
    success: '#ADCA86',
    warning: '#FAA515',
    danger: '#FF6268',
    completed: '#14AE5C',
    inProgress: '#FAA515',
    notStarted: '#FF6268',
};

const basePadding = {
    paddingHorizontal: 20,
    paddingVertical: 18,
};

const baseMarging = {
    marginVertical: 50,
    marginHorizontal: 50,
    marginBottom: 18,
};

const baseFontSize = {
    sm: 12,
    md: 18,
    lg: 28,
};

const baseFontFamily = {
    OpenSansRegular: 'OpenSans Regular',
    OpenSansSemiBold: 'OpenSans SemiBold',
    OpenSansBold: 'OpenSans Bold',
    BreeSerif: "'Bree Serif', serif",
};

const baseScreenHeight = {
    vh: '88vh',
    defaultHeight: {
        height: `calc(100vh - 80px)`,
        // width: `calc(100% - 20px)`
    },
};

const baseFontWeight = {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
};

const planningEventColors = {
    color: {
        orange: '#F0730F',
    },
    backgroundColor: {
        orange: '#FDCFAA',
    },
};

const screenSize = {
    minScreenSize: 1110,
};

const border = 7;

const baseBorderRadius = {
    borderRadius: border,
    rightOnly: {
        borderTopRightRadius: border,
        borderBottomRightRadius: border,
    },
    leftOnly: {
        borderTopLeftRadius: border,
        borderBottomLeftRadius: border,
    },
    allSide: {
        borderTopRightRadius: border,
        borderBottomRightRadius: border,
        borderTopLeftRadius: border,
        borderBottomLeftRadius: border,
    },
};

const baseScrollbar = {
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {},
    '&::-webkit-scrollbar-thumb': {
        width: '5px',
        backgroundColor: baseColors.green.dark,
        borderRadius: '2px',
    },
};

const baseRgbColors = {
    white: 'rgba(255, 255, 255)',
};

const eventColorByRoom = [
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.primary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.secondary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.dark,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.pastel,
    },
    {
        color: baseColors.green.primary,
        backgroundColor: baseColors.green.light,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.primary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.secondary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.flash,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.dark,
    },
    {
        color: baseColors.green.primary,
        backgroundColor: baseColors.green.light,
    },
];

export {
    baseColors,
    basePadding,
    baseMarging,
    baseFontSize,
    baseFontFamily,
    baseScreenHeight,
    baseFontWeight,
    planningEventColors,
    screenSize,
    baseRgbColors,
    baseBorderRadius,
    eventColorByRoom,
    baseScrollbar,
};
    function rgba(arg0: number, arg1: number, arg2: number, arg3: number) {
        throw new Error("Function not implemented.");
    }

