import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { InputField } from '../../../../components/InputField/InputField';
import { Box, Divider } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';

interface QuizzProps {
    quizz: any;
    trameField: any;
    triggerInput: Function;
}
const EducativeBilanQuizzTypeText: FC<QuizzProps> = (props: any) => {
    const { quizz, trameField, triggerInput } = props;
    const [answer, setAnswer] = useState(trameField.answer[0]);

    const onChangeHandler = (event: any) => {
        // console.log(event);
        setAnswer([event]);
    };

    useEffect(() => {
        triggerInput(answer, quizz.id);
    }, [answer]);

    return (
        <>
            <Box
                p="25px"
                bgcolor={baseColors.green.light}
                borderRadius="10px"
                sx={{
                    fontFamily: baseFontFamily.OpenSansBold,
                    fontSize: 14,
                }}
            >
                {quizz.text}
            </Box>
            <Spacer  height={25}/>
            <InputField
                placeholder="Tapez votre texte"
                value={answer}
                onChange={onChangeHandler}
                width={'100%'}
                height={'40px'}
                labelSize={'10.66px'}
                label={''}
            />
            <Spacer  height={15}/>
        </>
    );
};

export default EducativeBilanQuizzTypeText;
