import { Grid, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import logo from '../../assets/icons/Ivehte_Logo_Piste_1 2.png';
import { ROUTES } from 'src/routes/routes';
import { ACTIVATE_ACCOUNT_MUTATION } from 'src/graphql/mutations/mutations';
import { useMutation } from '@apollo/client';
import ActivateUserSuccess from './ActivateUserSuccess';
import ActivateUserError from './ActivateUserError';
import { CustomText } from 'src/components/Text/CustomText';
import { GppGood } from '@mui/icons-material';
import { InputFieldPassword } from 'src/components/InputField/InputFieldPassword';
import { CustomButton } from 'src/components/Button/CustomButton';

interface IProps {}

const resetPasswordValue = {
    password: '',
    retypePassword: '',
    token: '',
};

export const ActivateUser: FC<IProps> = (props) => {
    const search = useLocation().search;
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [token, setToken] = useState<string | null>(new URLSearchParams(search).get('token'));
    const [password, setPassword] = useState(resetPasswordValue);
    const [loading, setLoading] = useState(false);
    const [loadingActivateAccount, setLoadingActivateAccount] = useState(false);
    const [disabledBtn, setDisbledBtn] = useState(true);

    const [activateAccount, { data }] = useMutation(ACTIVATE_ACCOUNT_MUTATION, {
        variables: {
            token: token,
            password : password.password,
            retypePassword : password.retypePassword,
        },
        onCompleted: (data) => {
            setLoadingActivateAccount(false);
            setSuccess(true);
        },
        onError: (error) => {
            setError(true);
        },
    });

    useEffect(() => {
        if (token) {
            setToken(token);
            setDisbledBtn(false);
        }
    }, [token]);

    const activateAccountBtn = () => {
        setLoadingActivateAccount(true);
        if(token){
            activateAccount();
        }
        else{
            setDisbledBtn(true);
        }
    }

    const validPassword = (input : any) => {
        if(input.password === input.retypePassword && input?.password?.trim()?.length >= 8 && !disabledBtn){
            return false;
        }
        else {
            return true;
        }
    }


    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Link to={ROUTES.login}>
                <IconButton style={{ padding: 20 }}>
                    <img alt="icon" style={{ width: '65px', height: '60px' }} src={logo} />
                </IconButton>
            </Link>
            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                        marginTop: '3%',
                    }}
                >
                    {success ? (
                        <ActivateUserSuccess />
                    ) : error ? (
                        <>
                            <ActivateUserError />
                        </>
                    ) : (
                        <>
                            <Grid
                                style={{
                                    backgroundColor: 'white',
                                    width: '500px',
                                    padding: '25px',
                                    borderRadius: '15px',
                                }}
                            >
                                <Grid>
                                    <CustomText
                                        style={{ textAlign: 'center', marginBottom: 10 }}
                                        fontFamily={baseFontFamily.OpenSansBold}
                                        fontSize={16}
                                        color={baseColors.green.primary}
                                    >
                                        Pour activer votre compte, veuillez entrer votre nouveau mot de passe.
                                    </CustomText>
                                    <Grid>
                                        <Grid container direction={'row'}>
                                            <Grid
                                                item
                                                flexGrow={1}
                                                justifyContent={'center'}
                                                style={{ paddingLeft: '3%', paddingTop: '5%' }}
                                            >
                                                <InputFieldPassword
                                                    width={'97%'}
                                                    placeholder="Entrer votre nouveau mot de passe"
                                                    disabled={loading}
                                                    label="Mot de passe : "
                                                    value={password.password}
                                                    onChange={(value: any) => {
                                                        setPassword((old) => ({ ...old, password: value }));
                                                    }}
                                                />
                                                {password?.password?.trim()?.length < 8 && (
                                                    <CustomText
                                                        style={{ textAlign: 'center', marginBottom: 10 }}
                                                        color={baseColors.orange.primary}
                                                    >
                                                        Le mot de passe doit comporter au moins 8 caractères
                                                    </CustomText>
                                                )}

                                                <InputFieldPassword
                                                    width={'97%'}
                                                    disabled={loading}
                                                    placeholder="Confirmer votre mot de passe"
                                                    label="Confirmer votre mot de passe :"
                                                    value={password.retypePassword}
                                                    onChange={(value: any) => {
                                                        setPassword((old) => ({ ...old, retypePassword: value }));
                                                    }}
                                                />
                                                {password.retypePassword.length > 0 &&
                                                    password.password !== password.retypePassword && (
                                                        <CustomText
                                                            style={{ textAlign: 'center', marginBottom: 10 }}
                                                            color={baseColors.orange.primary}
                                                        >
                                                            Les mots de passe ne sont pas identiques
                                                        </CustomText>
                                                    )}
                                            </Grid>
                                            <Grid
                                                container
                                                justifyContent={'center'}
                                                style={{ marginTop: 10, marginBottom: 20 }}
                                            >
                                                <CustomButton
                                                    width={'100%'}
                                                    loading={loadingActivateAccount}
                                                    lower
                                                    onClick={() => activateAccountBtn()}
                                                    label="Activer votre compte"
                                                    disabled = {validPassword(password) && !disabledBtn}
                                                ></CustomButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
