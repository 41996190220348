import { baseColors } from 'src/utils/constants/baseStyles';

interface selectedEducativeBilanIconProps {
    width?: string;
    height?: string;
    color?: string;
}

export const SelectedEducativeBilanIcon: React.FC<selectedEducativeBilanIconProps> = ({ width, height, color }) => {
    return (
        <svg width={width || '50'} height={height || '50'} viewBox="-5 -5 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_801_20471)">
                    <rect width="40" height="40" rx="6" fill={ color || baseColors.green.light} stroke='white' stroke-width="1.75" stroke-linecap="round" />
                    <g clipPath="url(#clip1_801_20471)">
                        <path
                            d="M20.9856 30.4996H10.7003C9.70622 30.4996 8.90039 29.6937 8.90039 28.6997V26.8996H22.7145L20.9856 30.4996Z"
                            fill="#FFF9F5"
                        />
                        <path
                            d="M32.3021 16.1003V23.6624V28.7003H10.7003C9.70622 28.7003 8.90039 27.8944 8.90039 26.9003V16.1003L21.5013 11.6003L32.3021 16.1003Z"
                            fill="#FFF9F5"
                        />
                        <path
                            d="M12.0781 19.6996H11.1218C10.8888 19.6996 10.7 19.5107 10.7 19.2778V18.3215C10.7 18.0886 10.8888 17.8997 11.1218 17.8997H12.0781C12.311 17.8997 12.4999 18.0886 12.4999 18.3215V19.2778C12.4999 19.5107 12.311 19.6996 12.0781 19.6996Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M15.6779 19.6996H14.7217C14.4887 19.6996 14.2998 19.5107 14.2998 19.2778V18.3215C14.2998 18.0886 14.4887 17.8997 14.7217 17.8997H15.6779C15.9109 17.8997 16.0998 18.0886 16.0998 18.3215V19.2778C16.0998 19.5107 15.9109 19.6996 15.6779 19.6996Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M19.2778 19.6996H18.3215C18.0886 19.6996 17.8997 19.5107 17.8997 19.2778V18.3215C17.8997 18.0886 18.0886 17.8997 18.3215 17.8997H19.2778C19.5107 17.8997 19.6996 18.0886 19.6996 18.3215V19.2778C19.6996 19.5107 19.5108 19.6996 19.2778 19.6996Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M22.8784 19.6996H21.9221C21.6891 19.6996 21.5002 19.5107 21.5002 19.2778V18.3215C21.5002 18.0886 21.6891 17.8997 21.9221 17.8997H22.8784C23.1113 17.8997 23.3002 18.0886 23.3002 18.3215V19.2778C23.3002 19.5107 23.1113 19.6996 22.8784 19.6996Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M26.4772 19.6996H25.521C25.288 19.6996 25.0991 19.5107 25.0991 19.2778V18.3215C25.0991 18.0886 25.288 17.8997 25.521 17.8997H26.4772C26.7102 17.8997 26.8991 18.0886 26.8991 18.3215V19.2778C26.8991 19.5107 26.7103 19.6996 26.4772 19.6996Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M30.0776 19.6996H29.1213C28.8884 19.6996 28.6995 19.5107 28.6995 19.2778V18.3215C28.6995 18.0886 28.8884 17.8997 29.1213 17.8997H30.0776C30.3106 17.8997 30.4994 18.0886 30.4994 18.3215V19.2778C30.4994 19.5107 30.3106 19.6996 30.0776 19.6996Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M12.0781 23.3H11.1218C10.8888 23.3 10.7 23.1111 10.7 22.8781V21.9219C10.7 21.6889 10.8888 21.5 11.1218 21.5H12.0781C12.311 21.5 12.4999 21.6889 12.4999 21.9219V22.8781C12.4999 23.1111 12.311 23.3 12.0781 23.3Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M15.6779 23.3H14.7217C14.4887 23.3 14.2998 23.1111 14.2998 22.8781V21.9219C14.2998 21.6889 14.4887 21.5 14.7217 21.5H15.6779C15.9109 21.5 16.0998 21.6889 16.0998 21.9219V22.8781C16.0998 23.1111 15.9109 23.3 15.6779 23.3Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M19.2778 23.3H18.3215C18.0886 23.3 17.8997 23.1111 17.8997 22.8781V21.9219C17.8997 21.6889 18.0886 21.5 18.3215 21.5H19.2778C19.5107 21.5 19.6996 21.6889 19.6996 21.9219V22.8781C19.6996 23.1111 19.5108 23.3 19.2778 23.3Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M23.3002 23.3V21.95C23.3002 21.7014 23.0988 21.5 22.8502 21.5H21.9502C21.7017 21.5 21.5002 21.7014 21.5002 21.95V22.85C21.5002 23.0985 21.7017 23.3 21.9502 23.3H23.3002Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M12.0781 26.8994H11.1218C10.8888 26.8994 10.7 26.7105 10.7 26.4776V25.5213C10.7 25.2884 10.8888 25.0995 11.1218 25.0995H12.0781C12.311 25.0995 12.4999 25.2884 12.4999 25.5213V26.4776C12.4999 26.7105 12.311 26.8994 12.0781 26.8994Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M15.6779 26.8994H14.7217C14.4887 26.8994 14.2998 26.7105 14.2998 26.4776V25.5213C14.2998 25.2884 14.4887 25.0995 14.7217 25.0995H15.6779C15.9109 25.0995 16.0998 25.2884 16.0998 25.5213V26.4776C16.0998 26.7105 15.9109 26.8994 15.6779 26.8994Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M19.2778 26.8994H18.3215C18.0886 26.8994 17.8997 26.7105 17.8997 26.4776V25.5213C17.8997 25.2884 18.0886 25.0995 18.3215 25.0995H19.2778C19.5107 25.0995 19.6996 25.2884 19.6996 25.5213V26.4776C19.6996 26.7105 19.5108 26.8994 19.2778 26.8994Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M30.0647 17.8996H29.134C29.0777 17.8996 29.024 17.9107 28.9746 17.9301C29.1357 17.9937 29.2498 18.1505 29.2498 18.3342V19.2649C29.2498 19.4487 29.1357 19.6054 28.9746 19.669C29.024 19.6885 29.0777 19.6996 29.134 19.6996H30.0647C30.3048 19.6996 30.4994 19.505 30.4994 19.265V18.3342C30.4993 18.0942 30.3047 17.8996 30.0647 17.8996V17.8996Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M26.4649 17.8996H25.5341C25.4778 17.8996 25.4242 17.9107 25.3748 17.9301C25.5359 17.9937 25.65 18.1505 25.65 18.3342V19.2649C25.65 19.4487 25.5358 19.6054 25.3748 19.669C25.4242 19.6885 25.4778 19.6996 25.5341 19.6996H26.4649C26.7049 19.6996 26.8995 19.505 26.8995 19.265V18.3342C26.8995 18.0942 26.7049 17.8996 26.4649 17.8996V17.8996Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M22.8653 17.8996H21.9345C21.8782 17.8996 21.8246 17.9107 21.7751 17.9301C21.9363 17.9937 22.0504 18.1505 22.0504 18.3342V19.2649C22.0504 19.4487 21.9363 19.6054 21.7751 19.669C21.8246 19.6885 21.8782 19.6996 21.9345 19.6996H22.8653C23.1053 19.6996 23.2999 19.505 23.2999 19.265V18.3342C23.2999 18.0942 23.1053 17.8996 22.8653 17.8996V17.8996Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M19.2654 17.8996H18.3347C18.2783 17.8996 18.2247 17.9107 18.1753 17.9301C18.3364 17.9937 18.4506 18.1505 18.4506 18.3342V19.2649C18.4506 19.4487 18.3364 19.6054 18.1753 19.669C18.2247 19.6885 18.2783 19.6996 18.3347 19.6996H19.2654C19.5055 19.6996 19.7001 19.505 19.7001 19.265V18.3342C19.7 18.0942 19.5055 17.8996 19.2654 17.8996Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M15.6656 17.8996H14.7348C14.6785 17.8996 14.6249 17.9107 14.5754 17.9301C14.7365 17.9937 14.8507 18.1505 14.8507 18.3342V19.2649C14.8507 19.4487 14.7365 19.6054 14.5754 19.669C14.6249 19.6885 14.6785 19.6996 14.7348 19.6996H15.6656C15.9056 19.6996 16.1002 19.505 16.1002 19.265V18.3342C16.1002 18.0942 15.9056 17.8996 15.6656 17.8996V17.8996Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M12.0655 17.8996H11.1347C11.0784 17.8996 11.0248 17.9107 10.9753 17.9301C11.1364 17.9937 11.2506 18.1505 11.2506 18.3342V19.2649C11.2506 19.4487 11.1364 19.6054 10.9753 19.669C11.0248 19.6885 11.0784 19.6996 11.1347 19.6996H12.0655C12.3055 19.6996 12.5001 19.505 12.5001 19.265V18.3342C12.5 18.0942 12.3055 17.8996 12.0655 17.8996Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M12.0655 21.5H11.1347C11.0784 21.5 11.0248 21.5111 10.9753 21.5305C11.1364 21.5941 11.2506 21.7509 11.2506 21.9346V22.8654C11.2506 23.0491 11.1364 23.2059 10.9753 23.2694C11.0248 23.289 11.0784 23.3 11.1347 23.3H12.0655C12.3055 23.3 12.5001 23.1054 12.5001 22.8655V21.9346C12.5 21.6946 12.3055 21.5 12.0655 21.5V21.5Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M12.0655 25.1004H11.1347C11.0784 25.1004 11.0248 25.1115 10.9753 25.1309C11.1364 25.1945 11.2506 25.3513 11.2506 25.535V26.4658C11.2506 26.6495 11.1364 26.8063 10.9753 26.8699C11.0248 26.8894 11.0784 26.9004 11.1347 26.9004H12.0655C12.3055 26.9004 12.5001 26.7058 12.5001 26.4659V25.535C12.5 25.2949 12.3055 25.1004 12.0655 25.1004V25.1004Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M15.6656 21.5H14.7348C14.6785 21.5 14.6249 21.5111 14.5754 21.5305C14.7365 21.5941 14.8507 21.7509 14.8507 21.9346V22.8654C14.8507 23.0491 14.7365 23.2059 14.5754 23.2694C14.6249 23.289 14.6785 23.3 14.7348 23.3H15.6656C15.9056 23.3 16.1002 23.1054 16.1002 22.8655V21.9346C16.1002 21.6946 15.9056 21.5 15.6656 21.5V21.5Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M15.6656 25.1004H14.7348C14.6785 25.1004 14.6249 25.1115 14.5754 25.1309C14.7365 25.1945 14.8507 25.3513 14.8507 25.535V26.4658C14.8507 26.6495 14.7365 26.8063 14.5754 26.8699C14.6249 26.8894 14.6785 26.9004 14.7348 26.9004H15.6656C15.9056 26.9004 16.1002 26.7058 16.1002 26.4659V25.535C16.1002 25.2949 15.9056 25.1004 15.6656 25.1004V25.1004Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M19.2654 21.5H18.3347C18.2783 21.5 18.2247 21.5111 18.1753 21.5305C18.3364 21.5941 18.4506 21.7509 18.4506 21.9346V22.8654C18.4506 23.0491 18.3364 23.2059 18.1753 23.2694C18.2247 23.289 18.2783 23.3 18.3347 23.3H19.2654C19.5055 23.3 19.7001 23.1054 19.7001 22.8655V21.9346C19.7 21.6946 19.5055 21.5 19.2654 21.5V21.5Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M23.2999 23.3V21.9346C23.2999 21.6945 23.1053 21.5 22.8653 21.5H21.9345C21.8782 21.5 21.8246 21.5111 21.7751 21.5305C21.9363 21.5941 22.0504 21.7509 22.0504 21.9346V22.8654C22.0504 23.0491 21.9363 23.2059 21.7751 23.2694C21.8246 23.289 21.8782 23.3 21.9345 23.3H23.2999V23.3Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M19.2654 25.1004H18.3347C18.2783 25.1004 18.2247 25.1115 18.1753 25.1309C18.3364 25.1945 18.4506 25.3513 18.4506 25.535V26.4658C18.4506 26.6495 18.3364 26.8063 18.1753 26.8699C18.2247 26.8894 18.2783 26.9004 18.3347 26.9004H19.2654C19.5055 26.9004 19.7001 26.7058 19.7001 26.4659V25.535C19.7 25.2949 19.5055 25.1004 19.2654 25.1004V25.1004Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M31.0508 15.5793V23.6623V28.7002H32.3012V23.6623V16.1003L31.0508 15.5793Z"
                            fill="#FFF9F5"
                        />
                        <path
                            d="M32.3021 16.1002V11.6002C32.3021 10.6061 31.4962 9.80023 30.5022 9.80023H10.7003C9.70622 9.80023 8.90039 10.6061 8.90039 11.6002V16.1002H32.3021Z"
                            fill="#F39041"
                        />
                        <path
                            d="M14.5712 12.0074H13.3996C13.1745 12.0074 12.9922 11.825 12.9922 11.6C12.9922 11.375 13.1746 11.1926 13.3996 11.1926H14.5712C14.7963 11.1926 14.9786 11.375 14.9786 11.6C14.9786 11.825 14.7962 12.0074 14.5712 12.0074V12.0074Z"
                            fill="#EB6800"
                        />
                        <path
                            d="M17.4496 12.0074H16.278C16.053 12.0074 15.8706 11.825 15.8706 11.6C15.8706 11.375 16.053 11.1926 16.278 11.1926H17.4496C17.6747 11.1926 17.8571 11.375 17.8571 11.6C17.8571 11.825 17.6747 12.0074 17.4496 12.0074V12.0074Z"
                            fill="#EB6800"
                        />
                        <path
                            d="M24.9219 12.0074H23.7502C23.5251 12.0074 23.3428 11.825 23.3428 11.6C23.3428 11.375 23.5252 11.1926 23.7502 11.1926H24.9219C25.1469 11.1926 25.3293 11.375 25.3293 11.6C25.3293 11.825 25.1469 12.0074 24.9219 12.0074Z"
                            fill="#EB6800"
                        />
                        <path
                            d="M27.8003 12.0074H26.6286C26.4035 12.0074 26.2212 11.825 26.2212 11.6C26.2212 11.375 26.4036 11.1926 26.6286 11.1926H27.8003C28.0253 11.1926 28.2077 11.375 28.2077 11.6C28.2077 11.825 28.0253 12.0074 27.8003 12.0074Z"
                            fill="#EB6800"
                        />
                        <path
                            d="M16.5495 11.375C16.5495 11.9963 16.0459 12.5 15.4245 12.5C14.8032 12.5 14.2996 11.9963 14.2996 11.375V9.12498C14.2995 8.50367 14.8032 8 15.4245 8C16.0458 8 16.5495 8.50367 16.5495 9.12498V11.375H16.5495Z"
                            fill="#EBF5FC"
                        />
                        <path
                            d="M26.8997 11.375C26.8997 11.9963 26.396 12.5 25.7747 12.5C25.1534 12.5 24.6497 11.9963 24.6497 11.375V9.12498C24.6497 8.50367 25.1533 8 25.7746 8C26.396 8 26.8996 8.50367 26.8996 9.12498V11.375H26.8997Z"
                            fill="#EBF5FC"
                        />
                        <path
                            d="M25.7753 8C25.5443 8 25.3298 8.06982 25.1511 8.18921C25.453 8.39097 25.652 8.73464 25.652 9.12504V11.375C25.652 11.7653 25.453 12.1091 25.1511 12.3108C25.3297 12.4302 25.5443 12.5 25.7753 12.5C26.3966 12.5 26.9003 11.9964 26.9003 11.3751V9.12498C26.9003 8.50367 26.3967 8 25.7753 8V8Z"
                            fill="#D3DCFB"
                        />
                        <path
                            d="M15.4245 8C15.1935 8 14.979 8.06982 14.8003 8.18921C15.1022 8.39097 15.3012 8.73464 15.3012 9.12504V11.375C15.3012 11.7653 15.1022 12.1091 14.8003 12.3108C14.9789 12.4302 15.1935 12.5 15.4245 12.5C16.0458 12.5 16.5494 11.9964 16.5494 11.3751V9.12498C16.5495 8.50367 16.0458 8 15.4245 8V8Z"
                            fill="#D3DCFB"
                        />
                        <path
                            d="M30.5012 9.80017H29.2512C30.2453 9.80017 31.0512 10.606 31.0512 11.6001V16.1002H32.3011V11.6002C32.3011 10.6061 31.4952 9.80017 30.5012 9.80017V9.80017Z"
                            fill="#EB6800"
                        />
                        <path d="M8.89941 13.8923H32.3012V14.7072H8.89941V13.8923Z" fill="#EB6800" />
                        <path
                            d="M27.3506 35C31.0785 35 34.1006 31.9779 34.1006 28.25C34.1006 24.5221 31.0785 21.5 27.3506 21.5C23.6227 21.5 20.6006 24.5221 20.6006 28.25C20.6006 31.9779 23.6227 35 27.3506 35Z"
                            fill="#3EABAB"
                        />
                        <path
                            d="M27.3502 33.2002C30.084 33.2002 32.3002 30.984 32.3002 28.2502C32.3002 25.5164 30.084 23.3002 27.3502 23.3002C24.6163 23.3002 22.4001 25.5164 22.4001 28.2502C22.4001 30.984 24.6163 33.2002 27.3502 33.2002Z"
                            fill="#EBF5FC"
                        />
                        <path
                            d="M27.3501 27.7574C27.125 27.7574 26.9426 27.575 26.9426 27.3499V25.0999C26.9426 24.8749 27.125 24.6925 27.3501 24.6925C27.5751 24.6925 27.7575 24.8749 27.7575 25.0999V27.3499C27.7575 27.575 27.5751 27.7574 27.3501 27.7574Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M29.6003 28.657H28.2502C28.0251 28.657 27.8428 28.4746 27.8428 28.2496C27.8428 28.0246 28.0252 27.8422 28.2502 27.8422H29.6003C29.8253 27.8422 30.0077 28.0246 30.0077 28.2496C30.0077 28.4746 29.8253 28.657 29.6003 28.657Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M27.3501 21.5C27.1395 21.5 26.9312 21.5102 26.7254 21.5291C30.1604 21.8444 32.8506 24.7328 32.8506 28.25C32.8506 31.7672 30.1603 34.6556 26.7253 34.9709C26.9311 34.9898 27.1394 35 27.3501 35C31.078 35 34.1001 31.9779 34.1001 28.25C34.1001 24.5221 31.078 21.5 27.3501 21.5Z"
                            fill="#1B8E95"
                        />
                        <path
                            d="M27.3504 23.3002C27.1389 23.3002 26.9306 23.3135 26.7261 23.3392C29.1651 23.6462 31.0518 25.7279 31.0518 28.2502C31.0518 30.7725 29.1651 32.8542 26.7261 33.1611C26.9306 33.1869 27.1389 33.2002 27.3504 33.2002C30.0842 33.2002 32.3004 30.984 32.3004 28.2502C32.3004 25.5164 30.0842 23.3002 27.3504 23.3002V23.3002Z"
                            fill="#E6F4F4"
                        />
                        <path
                            d="M27.3505 29.1504C27.8475 29.1504 28.2505 28.7475 28.2505 28.2504C28.2505 27.7533 27.8475 27.3504 27.3505 27.3504C26.8534 27.3504 26.4504 27.7533 26.4504 28.2504C26.4504 28.7475 26.8534 29.1504 27.3505 29.1504Z"
                            fill="#FF821F"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_801_20471">
                        <rect width="40" height="40" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_801_20471">
                        <rect width="27" height="27" fill="white" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>
    );
};

