import { Grid, MenuItem, Select, Typography } from '@mui/material';

interface InputSelectRoleProps {
    currentValue: string;
    onChange: Function;
    availableRole: any[];
}
const InputSelectRole = (props: InputSelectRoleProps) => {
    const { currentValue, onChange, availableRole } = props;
    return (
        <Grid container alignItems="center">
            <Grid item md={4} sm={12}>
                <Typography fontSize={12}>Rôle : </Typography>
            </Grid>
            <Grid item md={8} sm={12}>
                <Select
                    value={currentValue}
                    label=""
                    onChange={(value) => {
                        onChange(value);
                    }}
                    fullWidth
                >
                    {availableRole.map((role) => {
                        return (
                            <MenuItem key={role.value} value={role.value}>
                                {role.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
        </Grid>
    );
};

export default InputSelectRole;
