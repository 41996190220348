interface SelectedSettingIconProps {
    width: string;
    height: string;
    color: string;
}

const selectedSettingIconProps = {
    width: '50',
    height: '50',
    color : ''
};

export const SelectedSettingIcon = (props: SelectedSettingIconProps = selectedSettingIconProps) => {
    const { width, height, color } = props;

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="-8.5 -8.5  47.5 47.5"
            >
                <rect x="-5.5" y="-5" width="35" height="35" rx="6" fill={color} fill-opacity="80%" stroke='white' stroke-width="1.75" stroke-linecap="round" />
                <circle cx="11.99" cy="11.99" r="6.83" fill="#f2f2f2" />
                <path
                    d="M4.23,18.64,3,21.17l1.26,1.27L5.49,23.7,8,22.43a9.65,9.65,0,0,0,2,.83L10.91,26h3.58l.88-2.69a9.43,9.43,0,0,0,2-.83l2.53,1.27,1.26-1.26,1.27-1.27-1.28-2.53a9.92,9.92,0,0,0,.84-2l2.68-.88V12.17L22,11.28a10.16,10.16,0,0,0-.84-2l1.28-2.53L21.18,5.48,19.92,4.21,17.39,5.49a10,10,0,0,0-2-.84L14.49,2H10.91L10,4.65a10.25,10.25,0,0,0-2,.84L5.49,4.21,4.22,5.48,3,6.74,4.23,9.27a9.56,9.56,0,0,0-.83,2l-2.69.89v3.58l2.69.88A9.35,9.35,0,0,0,4.23,18.64ZM12.7,7.12A6.84,6.84,0,1,1,5.87,14,6.85,6.85,0,0,1,12.7,7.12Z"
                    transform="translate(-0.71 -1.96)"
                    fill="#ea6808"
                />
                <circle cx="11.99" cy="11.99" r="3.68" fill="#3caaaa" />
            </svg>
        </>
    );
};
