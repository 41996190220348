import { baseColors } from 'src/utils/constants/baseStyles';

class VideoPreviewViewModel {
    private styles = {
        listContainer: {
            width: '20%',
            height: '100%',
        },
        listContent: {
            height: '100%',
        },
        listTitle: {
            color: baseColors.black,
            fontWeight: 'bold',
            paddingLeft: '15px',
            paddingTop: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        moduleListContent: {},
        scrollableListContainer: {
            width: '100%',
            height: '95%',
            overflow: 'auto',
            scrollbarColor: baseColors.green.primary,
        },
        moduleItemSelected: {
            backgroundColor: baseColors.green.primary,
        },
        moduleIcon: {
            width: '100%',
            height: 'auto',
        },
        moduleText: {
            // backgroundColor : 'yellow',
        },

        moduleItemTitleSelected: {
            color: baseColors.white,
        },

        moduleItemDescriptionSelected: {
            color: baseColors.white,
        },

        moduleItemTitle: {
            color: baseColors.black,
            fontWeight: 'bold',
            marginBottom: '5px',
            fontSize: '10pt',
        },

        moduleItemDescription: {
            color: baseColors.green.primary,
            fontSize: '8pt',
        },
        buttonPanel: {
            width: '100%',
            height: '100px',
            display: 'flex',
            flexDirection: 'row',
            fontSize: '50pt',
        },
        buttonContainer: {
            width: '100%',
            height: '125px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'center',
            justifyContent: 'center',
        },
        button: {
            backgroundColor: baseColors.green.primary,
            fontSize: '9pt',
            fontWeight: 'bold',
            marginTop: '15px',
        },
        video: {
            backgroundColor: baseColors.grey,
            marginLeft: '2px',
            marginBottom: '5px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        },
    };
    public getStyles() {
        return this.styles;
    }
}

export default VideoPreviewViewModel;
