import { Box } from '@mui/material';
import { ProfileImage } from '../../Header/ProfileImage';
import { truncate } from './ModuleItem';
import ModuleListViewModel from './ModuleListViewModel';

export interface ImageUiData {
    module: any;
    selected: boolean;
}

export interface ImageItemProps {
    data: ImageUiData;
}
export const imageItemProps: ImageItemProps = {
    data: {
        module: {},
        selected: false,
    },
};

export const ImageItem = (props: ImageItemProps = imageItemProps) => {
    const vm = new ModuleListViewModel();
    const styles = vm.getStyles();
    const { data } = props;
    const { module, selected } = data;

    return (
        <>
            <Box
                sx={{
                    ...styles.moduleIcon,
                    '@media screen and (max-width: 1000px)': {
                        display: 'none',
                        width: 0,
                    },
                }}
            >
                <ProfileImage width="40px" height="40px" alt="V" />
            </Box>
            <Box sx={styles.moduleText}>
                <Box
                    sx={
                        selected
                            ? {
                                  ...styles.moduleItemTitle,
                                  color: styles.moduleItemTitleSelected.color,
                              }
                            : styles.moduleItemTitle
                    }
                >
                    {module.video?.title}
                </Box>
                <Box
                    sx={
                        selected
                            ? {
                                  ...styles.moduleItemDescription,
                                  color: styles.moduleItemDescriptionSelected.color,
                              }
                            : styles.moduleItemDescription
                    }
                >
                    {truncate(module.video?.description)}
                </Box>
            </Box>
        </>
    );
};
