import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';

type AppProps = {
    label: string;
    value: number;
    handleChange: Function;
    color?: string;
    labelStyle?: React.CSSProperties;
};

export const CustomRadioGroup = ({ label, value, handleChange, color, labelStyle }: AppProps) => {
    return (
        <FormControl>
            <FormLabel
                id="demo-controlled-radio-buttons-group"
                sx={{
                    '&.MuiFormLabel-root': {
                        fontFamily: baseFontFamily.OpenSansRegular,
                        color: { color },
                        fontSize: 10.66
                    },
                }}
                style={labelStyle}
            >
                {label}
            </FormLabel>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={value}
                onChange={(event) => handleChange(event)}
            >
                <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={<CustomText fontSize={10.66}> Oui </CustomText>}
                    labelPlacement="start"
                    style={{
                        color: baseColors.black,
                        marginLeft: '0',
                        fontSize: '0.66rem',
                        fontFamily: baseFontFamily.OpenSansRegular + '!important',
                    }}
                    sx={{
                        '& .Mui-checked': {
                            color: color + '!important',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: 18, // Adjust the fontSize value to change the size of the Checkbox
                        },
                    }}
                />
                <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={<CustomText fontSize={10.66}> Non </CustomText>}
                    labelPlacement="start"
                    style={{
                        color: baseColors.black,
                        fontSize: '0.66rem',
                        fontFamily: baseFontFamily.OpenSansRegular + '!important',
                    }}
                    sx={{
                        '& .Mui-checked': {
                            color: color + '!important',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: 18, // Adjust the fontSize value to change the size of the Checkbox
                        },
                    }}
                />
            </RadioGroup>
        </FormControl>
    );
};
