import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface QuizzProps {
    quizz: any;
    trameField: Array<any>;
    triggerInput: Function;
}
const EducativeBilanQuizzTypeRadio: FC<QuizzProps> = (props: any) => {
    const { quizz, trameField, triggerInput } = props;
    let initialState: number = 0;

    for (let i = 0; i < quizz.choices.length; i++) {
        if (quizz.choices[i].text === trameField.answer[0]) {
            initialState = i;
            break;
        }
    }

    const [radioValue, setRadioValue] = useState(initialState);

    const handleCheckboxChange = (event: any, i: any) => {
        setRadioValue(i);
    };

    useEffect(() => {
        let objs = quizz.choices[radioValue];
        let answer = objs.text;
        triggerInput([answer], quizz.id);
    }, [radioValue]);

    return (
        <>
            <Box
                p="25px"
                bgcolor={baseColors.green.light}
                borderRadius="10px"
                sx={{
                    fontFamily: baseFontFamily.OpenSansBold,
                    fontSize: 14,
                }}
            >
                {quizz.text}
            </Box>
            <Spacer  height={10}/>
            {quizz.choices.map((choice: any, i: number) => (
                <CustomRadio
                    key={i}
                    label={choice.text}
                    checked={radioValue == i}
                    onClick={(event: any) => handleCheckboxChange(event, i)}
                    labelSize={10.66}
                ></CustomRadio>
            ))}
            <Spacer  height={15}/>
        </>
    );
};

export default EducativeBilanQuizzTypeRadio;
