import * as React from 'react';

import { Navigate, useNavigate } from 'react-router';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

import { AccountMenuProps } from './interface/AccountMenuProps';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ConfirmDialog } from '../Dialog/ConfirmDialog';
import { FC } from 'react';
import Grow from '@mui/material/Grow';
import { LOGOUT } from 'src/graphql/mutations/mutations';
import LogoutHandler from './handlers/LogoutHandler';
import { AuthorizationCode, MenuIconName } from 'src/utils/constants/constants';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { useMutation } from '@apollo/client';
import { useSidebarContext } from 'src/providers/SidebarProvider';
import { useState } from 'react';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuthContext } from 'src/providers/AuthProvider';
import { Typography } from '@mui/material';
export const AccountMenu: FC<AccountMenuProps> = (props) => {
    // props
    const fullName = props.fullName;

    //useContext
    const { selectedPanel, setSelectedPanel } = useSidebarContext();
    const { codes } = useAuthorizationContext();
    const { logout, isLoggedIn, setIsLoadingLogout } = useAuthContext();

    // useState
    const [disconnecting, setDisconnecting] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    // useMutation
    const [logoutMutation, { data, loading, error }] = useMutation(LOGOUT);

    // handler
    const handler = new LogoutHandler();

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const navigate = useNavigate();
    const performLogout = () => {
        setDisconnecting(true);
        setIsLoadingLogout(true);
        const cb = () => {
            setShowConfirm(false);
            navigate('/login');
        };
        handler.logout({ logoutFront: logout, logout: logoutMutation, data, error, cb }, localStorage.getItem('token'));
    };

    const ShowUserConsent = (event: Event | React.SyntheticEvent) => {
        navigate('/user-consent');
        handleClose(event);
        // setSelectedPanel?.(MenuIconName.USER_CONSENT);
        // handleClose(event);
    };

    const showProfile = (event: Event | React.SyntheticEvent) => {
        navigate('/profile');
        handleClose(event);
    };

    const isAuthorizedToSeeUserConsent = codes?.includes(AuthorizationCode.FRONT_DISPLAY_USER_CONSENT_SIDEBAR_MENU);

    return (
        <Stack direction="row" spacing={2} >
            <div style={{ width: '100%' }}>
                <ConfirmDialog
                    title={disconnecting ? '' : 'Déconnexion'}
                    message={disconnecting ? 'Déconnexion...' : 'Êtes-vous sûr de vouloir vous déconnecter ?'}
                    onCancel={() => setShowConfirm(false)}
                    visible={showConfirm}
                    onConfirm={() => performLogout()}
                />
                <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <div
                        style={{
                            color: baseColors.black,
                            fontFamily: baseFontFamily.OpenSansSemiBold,
                            width: 'max-content',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span>{fullName}</span>
                        <div style={{ marginTop: '3px' }}>
                            <span>
                                <KeyboardArrowDownIcon />
                            </span>
                        </div>
                    </div>
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    // disablePortal
                    sx={{position : 'absolute'}}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper style={{ width: '100%' }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem onClick={showProfile}>
                                            <Typography
                                                fontFamily={baseFontFamily.BreeSerif}
                                                fontWeight={400}
                                                color={baseColors.green.cancel}
                                                fontSize={16}
                                            >
                                                Espace personnel
                                            </Typography>
                                        </MenuItem>
                                        {isAuthorizedToSeeUserConsent && (
                                            <MenuItem onClick={ShowUserConsent}>
                                                <Typography
                                                    fontFamily={baseFontFamily.BreeSerif}
                                                    fontWeight={400}
                                                    color={baseColors.green.cancel}
                                                    fontSize={16}
                                                >
                                                    Recueil de consentement
                                                </Typography>
                                            </MenuItem>
                                        )}
                                        <MenuItem onClick={() => setShowConfirm(true)}>
                                            <Typography
                                                fontFamily={baseFontFamily.BreeSerif}
                                                fontWeight={400}
                                                color={baseColors.green.cancel}
                                                fontSize={16}
                                            >
                                                Se déconnecter
                                            </Typography>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Stack>
    );
};
