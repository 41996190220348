import { Box } from '@mui/material';
import { useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { BilanTabMenuIconName } from 'src/utils/constants/constants';
import CreateNewModuleInformation from './Information/CreateNewModuleInformation';
import ModuleInformation from './Information/ModuleInformation';
import { TabPanelViewModel } from './TabPanelViewModel';

export const InformationPanel = (props: any) => {
    const vm = new TabPanelViewModel();
    const styles = vm.getStyles();
    const { setSelectedPanel, isCreatingNewModule, setIsCreatingNewModule } = useModuleDetailContext();
    const { module, moduleType, setSelectedModuleId } = props;
    let readOnly = module?.moduleIsValidated;
    return (
        <Box sx={styles.panel}>
            {isCreatingNewModule ? (
                <CreateNewModuleInformation
                    module={module}
                    onCreatedModule={(module: any) => {
                        setSelectedModuleId(module.id);
                        setSelectedPanel(BilanTabMenuIconName.VIDEO);
                        setIsCreatingNewModule(false);
                    }}
                    moduleType={moduleType}
                ></CreateNewModuleInformation>
            ) : (
                module && <ModuleInformation moduleId={module.id} readOnly={readOnly}></ModuleInformation>
            )}
        </Box>
    );
};
