import { gql } from '@apollo/client';

const VALIDATE_MODULE = gql`
    mutation Validate($userId: String!, $moduleId: String!) {
        validate(moduleId: $moduleId, userId: $userId) {
            userId
            moduleId
            message
            code
            status
        }
    }
`;

const VALIDATE_MODULE_EDUCATIVE_WORKSHOP = gql`
    mutation ValidateEducativeWorkshop($userId: String!, $moduleId: String!) {
        validateEducativeWorkshop(moduleId: $moduleId, userId: $userId) {
            userId
            moduleId
            message
            code
            status
        }
    }
`;

const UPDATE_PROGRESSION_VIDEO_OF_USER = gql`
    mutation UpdateProgressionVideoOfUser($input: UpdateProgressionVideoInput!) {
        updateProgressionVideoOfUser(updateProgressionVideoInput: $input) {
            message
            code
            hasSeenVideo
            hasSeenVideoAnnote
        }
    }
`;

const VALIDATE_MODULE_BY_PROFESSOR = gql`
    mutation ValidateModuleByProfessor($moduleId: String!) {
        validateModuleByProfessor(moduleId: $moduleId) {
            message
            code
            fieldsNotFound
        }
    }
`;
export {
    VALIDATE_MODULE,
    VALIDATE_MODULE_EDUCATIVE_WORKSHOP,
    UPDATE_PROGRESSION_VIDEO_OF_USER,
    VALIDATE_MODULE_BY_PROFESSOR,
};
