import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { FC } from "react";
import { baseColors } from "src/utils/constants/baseStyles";


interface ICustomLinearProgress {
    status: "notStarted" | "done" | "inProgress";
    min: number;
    max: number;
    value: number;
    withLabel?: boolean;
    label?: string;
}




const CustomLinearProgress: FC<LinearProgressProps & ICustomLinearProgress> = ({ status, min, max, value, withLabel, label, ...linearProgressProps }) => {
    const normalizedValue = ((value - min) * 100) / (max - min);
    const color = status == "notStarted" ? baseColors.danger : status == "inProgress" ? baseColors.warning : baseColors.success;

    const renderLinearProgressionWithLabel = (label: string, value: number, props: LinearProgressProps) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress sx={{
                        border: `${baseColors.grey} solid 2px`,
                        height: 10, borderRadius: '5px', backgroundColor: status == "notStarted" ? baseColors.red : baseColors.grey, '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                            borderRadius: '3px'
                        }
                    }} variant="determinate" value={value} {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{label}</Typography>
                </Box>
            </Box>
        )
    }
    return (
        <>
            {
                withLabel && label ?
                    renderLinearProgressionWithLabel(label, normalizedValue, linearProgressProps as LinearProgressProps) :
                    <LinearProgress sx={{
                        height: 20, borderRadius: '5px', backgroundColor: baseColors.grey, '& .MuiLinearProgress-bar': {
                            backgroundColor: color
                        }
                    }} variant="determinate" value={normalizedValue} {...linearProgressProps} />
            }
        </>
    );
}

export { };
export default CustomLinearProgress;