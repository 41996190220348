import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import {
    GET_SUMMARY_PROGRESSION_BY_PROMOTIONS,
    GET_SUMMARY_PROGRESSION_USER,
} from 'src/graphql/queries/summary.queries';
import { adaptSummaryProgression, SummaryProgression } from 'src/components/shared/SummaryProgression';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { GlobalAvancementBarChart } from 'src/components/shared/GlobalAvancementBarChart/GlobalAvancementBarChart';
import { AvancementContext } from 'src/providers/AvancementProvider';

const GlobalAvancement: FC<{ promotionId: string }> = ({ promotionId }: any) => {
    const [summaryProgression, setSummaryProgression] = useState<SummaryProgression[]>([]);
    const { reload, setReload, selectedUserId, isUserSelected } = useContext(AvancementContext);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const [
        getProgress,
        { loading: loadingPromotion, data: dataPromotion, error: errorPromotion, refetch: refechProm },
    ] = useLazyQuery(GET_SUMMARY_PROGRESSION_BY_PROMOTIONS, {
        variables: { promotionId },
        onCompleted: (dataPromotion: any) => {
            setSummaryProgression(() => {
                return dataPromotion.getSummaryOfAllActionsByStudentQuery.map(adaptSummaryProgression);
            });
        },
    });

    const {
        loading: loadingUser,
        data: dataUser,
        error: errorUser,
        refetch,
    } = useQuery(GET_SUMMARY_PROGRESSION_USER, {
        variables: { userId: selectedUserId },
        onCompleted: (dataUser: any) => {
            const summary: SummaryProgression[] = [adaptSummaryProgression(dataUser.getSummaryProgressionOfUser)];
            setSummaryProgression(summary);
        },
        skip: selectedUserId == null,
    });

    useEffect(() => {
        getProgress();
    }, []);

    useEffect(() => {
        if (reload) {
            if (!isUserSelected) {
                setLoadingData(true);
                refechProm().then(() => {
                    setLoadingData(false);
                });
                getProgress();
            } else {
                setLoadingData(true);
                refetch({ userId: selectedUserId }).then(() => {
                    setLoadingData(false);
                });
            }
        }
        setReload(false);
    }, [reload, isUserSelected]);

    if (!promotionId) {
        return <div>Aucune promotion sélectionnée</div>;
    }
    if (errorPromotion || errorUser) {
        return <div>Oupps, une erreur</div>;
    }
    return (
        <>
            {loadingPromotion || loadingUser || loadingData ? (
                <Box display='flex' justifyContent='center' alignItems='center' mt='25%'>
                    <CustomCircleLoader width={60} />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '75vh',
                            padding: '25px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Typography
                            fontFamily={baseFontFamily.BreeSerif}
                            fontSize={24}
                            color={baseColors.green.cancel}
                            fontWeight={400}
                        >
                            Avancement global de la promotion
                        </Typography>
                        <GlobalAvancementBarChart data={summaryProgression} />
                    </Box>
                </>
            )}
        </>
    );
};
export { GlobalAvancement };
