class LoginHandler {
    public authenticate(action: any, username: string = '', password: string = '') {
        const mutate = action.loginCheck({ variables: { email: username, password: password } });
        mutate
            .then((result: any) => {
                const vm = action.viewModel;
                const data = result.data?.authentication;
                const token = data?.token;

                vm.saveToken(token);
                action.login(token);
            })
            .catch((error: any) => {});
    }
}

export default LoginHandler;
