import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Module } from 'src/interfaces/interfaces';
import ModuleListViewModel from './ModuleListViewModel';
import { ProfileImage } from '../../Header/ProfileImage';
import { ImageItem } from './ImageItem';
import { VideoItem } from './VideoItem';
import { ThumbnailItem } from './ThumbnailItem';

export enum MediaType {
    IMAGE = 'image',
    VIDEO = 'Video',
    THUMBNAIL = 'thumbnail',
}
interface ModuleItemProps {
    module: Module;
    selected: boolean;
    onClick: any;
    mediaType?: string;
}

const moduleItemProps = {
    module: {
        id: '0',
        title: '',
        description: '',
    } as Module,
    selected: false,
    onClick: null,
    mediaType: MediaType.VIDEO,
};

export const truncate = (str: string) => {
    return str?.length > 30 ? str?.substring(0, 27) + '...' : str;
};

export const ModuleItem = (props: ModuleItemProps = moduleItemProps) => {
    const vm = new ModuleListViewModel();
    const styles = vm.getStyles();
    const { module, selected, onClick, mediaType } = props;

    const loadItems = () => {
        switch (mediaType) {
            case MediaType.IMAGE:
                return (
                    <ImageItem
                        data={{
                            module: module,
                            selected: selected,
                        }}
                    />
                );

            case MediaType.VIDEO:
                return (
                    <VideoItem
                        data={{
                            module: module,
                            selected: selected,
                            link: module.video?.fileUrl,
                        }}
                    />
                );

            case MediaType.THUMBNAIL:
                return (
                    <ThumbnailItem
                        data={{
                            module: module,
                            selected: selected,
                            link: module.video?.fileUrl,
                        }}
                    />
                );

            default:
                return (
                    <ImageItem
                        data={{
                            module: module,
                            selected: selected,
                        }}
                    />
                );
        }
    };

    const loadStyles = () => {
        switch (mediaType) {
            case MediaType.IMAGE:
                return selected
                    ? { ...styles.moduleItem, backgroundColor: styles.moduleItemSelected.backgroundColor }
                    : styles.moduleItem;
            case MediaType.VIDEO:
                return selected
                    ? { ...styles.moduleVideoItem, backgroundColor: styles.moduleItemSelected.backgroundColor }
                    : styles.moduleVideoItem;
            case MediaType.THUMBNAIL:
                return selected
                    ? { ...styles.moduleVideoItem, backgroundColor: styles.moduleItemSelected.backgroundColor }
                    : styles.moduleVideoItem;
            default:
                return selected
                    ? { ...styles.moduleItem, backgroundColor: styles.moduleItemSelected.backgroundColor }
                    : styles.moduleItem;
        }
    };
    return (
        <Box sx={loadStyles()} onClick={() => onClick()}>
            {loadItems()}
        </Box>
    );
};
