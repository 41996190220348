import { useMutation } from '@apollo/client';
import { CustomButton } from 'src/components/Button/CustomButton';
import {
    FINALIZE_EDUCATIVE_BILAN_MUTATION,
    FINALIZE_EDUCATIVE_WORKSHOP_MUTATION,
} from 'src/graphql/mutations/mutations';
import { ModuleType } from 'src/utils/constants/constants';
import ShowVideo from './ShowVideo';

interface AnnotedVideoPanelProps {
    annotedVideoFile?: any;
    annotedVideoUrl?: any;
    moduleTitle?: string | undefined;
    module?: any;
    moduleType?: string;
    videoType?: string;
    moduleId: string | undefined;
    onCompletedFinalizeModule: any;
    onErrorMutation: any;
    loadingVideo?: boolean;
    isFinalizedStatus?: boolean;
    hasSeenVideoAnnote: boolean;
    setHasSeenVideoAnnote: any;
    progressionVideo?: any;
}

const AnnotedVideoPanel = (props: AnnotedVideoPanelProps) => {
    const {
        annotedVideoFile,
        annotedVideoUrl,
        moduleTitle,
        moduleType,
        videoType,
        moduleId,
        onCompletedFinalizeModule,
        onErrorMutation,
        loadingVideo,
        isFinalizedStatus,
        hasSeenVideoAnnote,
        setHasSeenVideoAnnote,
        progressionVideo,
    } = props;

    const [finalizeEducativeBilan, finalizeMutation] = useMutation(FINALIZE_EDUCATIVE_BILAN_MUTATION, {
        variables: { moduleId },
        onCompleted: onCompletedFinalizeModule,
        onError: onErrorMutation,
    });

    const [finalizeEducativeWorkshop, finalizeWorkshopMutation] = useMutation(FINALIZE_EDUCATIVE_WORKSHOP_MUTATION, {
        variables: { moduleId },
        onCompleted: onCompletedFinalizeModule,
        onError: onErrorMutation,
    });
    return (
        <>
            <ShowVideo
                videoFile={annotedVideoFile}
                videoUrl={annotedVideoUrl}
                moduleTitle={moduleTitle}
                moduleId={moduleId}
                videoType={videoType}
                moduleType={moduleType}
                loadingVideo={loadingVideo}
                hasSeenVideo={hasSeenVideoAnnote}
                setHasSeenVideoAnnote={setHasSeenVideoAnnote}
                progressionVideo={progressionVideo}
            ></ShowVideo>
            {!isFinalizedStatus && moduleType === ModuleType.EDUCATIVE_BILAN ? (
                <CustomButton
                    width="auto"
                    lower={true}
                    fontSize={16}
                    label={'Finaliser module bilan éducatif'}
                    disabled={!hasSeenVideoAnnote}
                    onClick={() => {
                        if (hasSeenVideoAnnote) {
                            finalizeEducativeBilan();
                        }
                    }}
                ></CustomButton>
            ) : !isFinalizedStatus && moduleType === ModuleType.EDUCATIVE_WORKSHOP ? (
                <CustomButton
                    width="auto"
                    lower={true}
                    fontSize={16}
                    label={'Finaliser module atelier éducatif'}
                    disabled={!hasSeenVideoAnnote}
                    onClick={() => {
                        if (hasSeenVideoAnnote) {
                            finalizeEducativeWorkshop();
                        }
                    }}
                ></CustomButton>
            ) : (
                <></>
            )}
        </>
    );
};
export default AnnotedVideoPanel;
