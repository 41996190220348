import { Module } from 'src/interfaces/interfaces';

const disableSoumettreButtonHandler = (module: Module | undefined, setDisableSoumettreButton: Function | undefined) => {
    if (module && module?.video !== null && module?.videoAnnote !== null && module?.quizz?.length !== 0) {
        setDisableSoumettreButton?.(false);
        return false;
    } else {
        if (setDisableSoumettreButton !== undefined) {
            setDisableSoumettreButton?.(true);
        }
        return true;
    }
};


export default disableSoumettreButtonHandler;
