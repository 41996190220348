interface BilanIconProps {
    width: string;
    height: string;
}

const bilanIconProps = {
    width: '50',
    height: '50',
};

export const BilanIcon = (props: BilanIconProps = bilanIconProps) => {
    const { width, height } = props;

    return (
        <>
            <svg width={width} height={height} viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2846_3189)" filter="url(#filter0_d_2846_3189)">
                    <rect x="9" y="9" width="40" height="40" rx="6" fill="#E6F4F4" />
                    <path
                        d="M34.1426 17H38.0625C39.6843 17 41 18.3271 41 19.9642V23.9169C41 25.5528 39.6843 26.8812 38.0625 26.8812H34.1426C32.5196 26.8812 31.2039 25.5528 31.2039 23.9169V19.9642C31.2039 18.3271 32.5196 17 34.1426 17ZM19.9387 17H23.8574C25.4804 17 26.7961 18.3271 26.7961 19.9642V23.9169C26.7961 25.5528 25.4804 26.8812 23.8574 26.8812H19.9387C18.3157 26.8812 17 25.5528 17 23.9169V19.9642C17 18.3271 18.3157 17 19.9387 17ZM19.9387 31.1188H23.8574C25.4804 31.1188 26.7961 32.4459 26.7961 34.0843V38.0358C26.7961 39.6729 25.4804 41 23.8574 41H19.9387C18.3157 41 17 39.6729 17 38.0358V34.0843C17 32.4459 18.3157 31.1188 19.9387 31.1188ZM34.1426 31.1188H38.0625C39.6843 31.1188 41 32.4459 41 34.0843V38.0358C41 39.6729 39.6843 41 38.0625 41H34.1426C32.5196 41 31.2039 39.6729 31.2039 38.0358V34.0843C31.2039 32.4459 32.5196 31.1188 34.1426 31.1188Z"
                        stroke="#3EABAB"
                        strokeWidth="1.65441"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_d_2846_3189"
                        x="0.17647"
                        y="0.17647"
                        width="66.4706"
                        height="66.4706"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="4.41176" dy="4.41176" />
                        <feGaussianBlur stdDeviation="6.61765" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.901961 0 0 0 0 0.964706 0 0 0 0 0.964706 0 0 0 0.25 0"
                        />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2846_3189" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2846_3189" result="shape" />
                    </filter>
                    <clipPath id="clip0_2846_3189">
                        <rect width="40" height="40" fill="white" transform="translate(9 9)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
