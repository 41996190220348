import { BilanTabMenuIconName, QuestionType } from 'src/utils/constants/constants';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { Module, Question } from 'src/interfaces/interfaces';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { ADD_QUIZZ_TO_MODULE } from 'src/graphql/mutations/ModuleMutation';
import AddIcon from '@mui/icons-material/Add';
import { CustomButton } from 'src/components/Button/CustomButton';
import CustomSnackbar from 'src/components/CustomSnackbar';
import { GET_MODULE_BY_ID } from 'src/graphql/queries/queries';
import { QuestionItem } from './QuestionItem';
import { QuizzCreationProvider } from 'src/providers/QuizzProvider';
import { QuizzPanelViewModel } from './QuizzPanelViewModel';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { Spacer } from 'src/components/Spacer/Spacer';
import { TabPanelViewModel } from '../TabPanelViewModel';
import { ModuleDetailContext, useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { useQuizzCreationContext } from 'src/providers/QuizzProvider';
import { v4 as uuidv4 } from 'uuid';
import { isFormElement } from 'react-router-dom/dist/dom';
import disableSoumettreButtonHandler from '../../Helpers/SoumettreModuleHelper';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import { useDispatch } from 'react-redux';
import { setCurrentModule, updateModule } from 'src/redux/features/listModules/listModulesSlice';
// import { setCurrentModule } from 'src/redux/features/currentModule/currentModuleSlice';
import { Co2Sharp } from '@mui/icons-material';
import PlusIconButton from 'src/components/Icon/PlusIconButton';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

type IQuizzPanelProps = {
    module: Module;
};

export const QuizzPanel = (props: IQuizzPanelProps) => {
    const dispatch = useDispatch();
    const { modules, setModules } = useCurrentModuleContext();
    const { module } = props;
    const currentModule = module;
    let readOnly = module?.moduleIsValidated;
    const [isModifiable, setIsModifiable] = useState(!readOnly);
    const { setSelectedPanel } = useModuleDetailContext();
    if (!module?.id) {
        setSelectedPanel(BilanTabMenuIconName.INFORMATION);
    }

    const tabPanelViewModel = new TabPanelViewModel();
    const tabStyles = tabPanelViewModel.getStyles();

    const quizzPanelViewModel = new QuizzPanelViewModel();
    const styles = quizzPanelViewModel.getStyles();

    const quizzCreationContext = useQuizzCreationContext();
    const { questions, addQuestion, initializeQuestions } = quizzCreationContext;

    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);

    useEffect(() => {
        if (currentModule) {
            const quizz = currentModule.quizz;
            initializeQuestions(quizz);
        }
    }, [currentModule]);

    const addEmptyQuestion = () => {
        const question = {
            id: uuidv4(),
            text: '',
            type: QuestionType.TEXT,
            choices: [],
        } as Question;

        addQuestion(question);
    };

    const [addQuizzToModuleMutation, { loading, error, data }] = useMutation(ADD_QUIZZ_TO_MODULE);

    const submitQuizz = () => {
        let questionInputs = questions.map((question) => {
            return {
                text: question.text,
                type: question.type,
                choices: question.choices.map((choice) => {
                    return {
                        text: choice.text,
                    };
                }),
            };
        });

        const addQuizzToModuleMutationInput = {
            questions: questionInputs,
            moduleId: module.id,
        };

        addQuizzToModuleMutation({
            variables: {
                input: addQuizzToModuleMutationInput,
            },
            onCompleted: (data: any) => {
                setIsModifiable(false);
                if (readOnly) {
                    showSuccessSnackBar('Quizz enregistré avec succès');
                } else {
                    showSuccessSnackBar('Quizz ajouté avec succès');
                }
                console.log({ data });
                const moduleUpdated = {
                    ...module,
                    quizz: data.addQuizzToModule.quizz,
                };
                console.log(moduleUpdated);
                dispatch(updateModule({ moduleId: moduleUpdated.id, newValue: moduleUpdated }));
                // dispatch(setCurrentModule(moduleUpdated));
            },
            onError: (error) => {
                console.log({ error });
                showErrorSnackBar();
            },
        });
    };

    return (
        <Box sx={tabStyles.panel}>
            {questions.length == 0 ? (
                <Box
                    width={'100%'}
                    height={'100%'}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box onClick={addEmptyQuestion}>
                        <PlusIconButton />
                    </Box>
                    <Spacer height="20px" />
                    <Typography fontSize={16} fontWeight={700} fontFamily={baseFontFamily.OpenSansRegular}>
                        Ajouter un champ
                    </Typography>
                </Box>
            ) : (
                <>
                    {questions.map((question) => {
                        return (
                            <QuestionItem key={question.id} question={question} readOnly={!isModifiable}></QuestionItem>
                        );
                    })}

                    <Box sx={styles.addFieldBox}>
                        {isModifiable && (
                            <Box
                                onClick={addEmptyQuestion}
                                sx={{
                                    display: 'flex',
                                    border: '1px dashed',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '25px 0px 25px 0px',
                                    boxSizing: 'border-box',
                                    borderRadius: '10px',
                                    borderColor: baseColors.grey,
                                    cursor: 'pointer',
                                    // transition: 'background-color 0.3s ease-in-out', // Add transition for smooth color change
                                    '&:hover': {
                                        backgroundColor: '#EFF2F2',
                                    },
                                }}
                            >
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={14}>
                                    Ajouter un champ
                                </CustomText>
                            </Box>
                        )}
                    </Box>
                    {isModifiable ? (
                        <Box sx={{ float: 'right', paddingTop: '25px' }}>
                            <CustomButton
                                disabled={questions.length == 0}
                                label="Enregistrer"
                                width="200px"
                                onClick={submitQuizz}
                                loading={loading}
                                lower={true}
                            />
                        </Box>
                    ) : (
                        <Box sx={{ float: 'right', paddingTop: '25px' }}>
                            <CustomButton
                                width={'100px'}
                                height={'40px'}
                                label={'Modifier'}
                                fontSize={12}
                                lower={true}
                                onClick={() => {
                                    setIsModifiable(true);
                                }}
                            ></CustomButton>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};
