import { createContext, useContext } from "react";
import { SettingMenuIcon } from "src/utils/constants/constants";

export type SettingPanelContent = {
    selectedPanel: string;
    setSelectedPanel: any;
}

export const SettingPanelContext = createContext<SettingPanelContent>({
    selectedPanel: SettingMenuIcon.USER,
    setSelectedPanel: () => { },
})

export const useSettingPanelContext = () => useContext(SettingPanelContext);