import { baseColors } from 'src/utils/constants/baseStyles';

class ContentPanelViewModel {
    private style = {
        content: {
            backgroundColor: baseColors.green.light,
            width: '85%',
            paddingLeft: 1,
            boxSizing: 'border-box',
        },
        paperContent: {
            width: '100%',
            height: '100%',
            boxShadow: 'none',
        },
        rootPanel: {
            display: 'flex',
            height: '100%',
            width: '100%',
            backgroundColor: baseColors.green.light,
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        paperPanel: {
            width: '100%',
            height: '100%',
            backgroundColor: baseColors.green.light,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            boxShadow: 'none',
        },
        toolBoxPanel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    };

    public getStyles() {
        return this.style;
    }
}

export default ContentPanelViewModel;
