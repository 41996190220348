import { Box } from '@mui/material';
import VideoContentViewModel from './VideoContentViewModel';

interface ThumbnailContentProps {
    width: string;
    height: string;
    src: string;
}

const videoContentProps = {
    width: '50',
    height: '50',
    src: '',
};

export const ThumbnailContent = (props: ThumbnailContentProps = videoContentProps) => {
    const viewModel = new VideoContentViewModel();
    const styles = viewModel.getStyles();
    const { width, height, src } = props;

    return (
        <Box sx={styles.wrapper}>
            <video
                width={width}
                height={height}
                src={src}
                style={{ backgroundColor: 'white', borderRadius: 6 }}
            ></video>
        </Box>
    );
};
