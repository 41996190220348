import { gql } from '@apollo/client';

const SEND_PDF_TO_PROFESSOR = gql`
    mutation SendPdfToProfessor($input: SendPdfToProfessorInput!) {
        sendPdfToProfessor(sendPdfToProfessorInput: $input) {
            message
            code
            status
        }
    }
`;
export { SEND_PDF_TO_PROFESSOR };
