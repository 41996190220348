import { Box } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorText } from 'src/components/Error/ErrorText';
import { useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { selectCurrentModule, selectCurrentModuleWithId } from 'src/redux/features/listModules/listModulesSlice';
import { BilanTabMenuIconName } from 'src/utils/constants/constants';
import { TabPanelViewModel } from '../TabPanelViewModel';
import { AddVideo } from './AddVideo';
import { ListVideo } from './ListVideo';

export const VideoPanel = (props: any) => {
    // const { module } = props;
    const module = useSelector(selectCurrentModule);
    // const module = useSelector(selectCurrentModuleWithId);
    console.log({ module });
    const moduleId = module?.id;
    const vm = new TabPanelViewModel();
    const styles = vm.getStyles();
    const { setSelectedPanel } = useModuleDetailContext();
    const [reloadVideo, setReloadVideo] = useState(false);
    if (!moduleId) {
        setSelectedPanel(BilanTabMenuIconName.INFORMATION);
    }
    const isModuleValidated = module?.moduleIsValidated;
    return (
        <Box sx={styles.panel}>
            {moduleId ? (
                <>
                    {!isModuleValidated && (
                        <div style={{ float : 'right'}}>
                            <AddVideo setReloadVideo={setReloadVideo} module={module}></AddVideo>
                        </div>
                    )}
                    <ListVideo
                        reload={reloadVideo}
                        setReloadVideo={setReloadVideo}
                        module={module}
                        readOnly={false}
                    ></ListVideo>
                </>
            ) : (
                <ErrorText>Veuillez retourner vers l'onglet information</ErrorText>
            )}
        </Box>
    );
};
