import { Box, Paper } from '@mui/material';
import { FC, useState } from 'react';

import { Content } from 'src/pages/Home/Content/Content';
import ContentPanelViewModel from 'src/pages/Home/Content/ContentPanelViewModel';
import CustomSnackbar from '../CustomSnackbar';
import { Header } from 'src/pages/Home/Header/Header';
import HomeViewModel from 'src/pages/Home/HomeViewModel';
import { MenuIconName } from 'src/utils/constants/constants';
import { SidebarContext } from 'src/providers/SidebarProvider';
import { SidebarWrapper } from 'src/pages/Home/Sidebar/SidebarWrapper';
import WrapperViewModel from './WrapperViewModel';
import { useNavigate } from 'react-router';
import useStyles from 'src/pages/Home/styles';

type IWrapperComponentProps = {
    children: any;
};

const WrapperComponent = (props: IWrapperComponentProps) => {
    const { children } = props;
    const classes = useStyles();
    const [selectedPanel, setSelectedPanel] = useState(MenuIconName.BILAN);
    const viewModel = new HomeViewModel();
    const styles = viewModel.getStyles();

    const contentPanelViewModel = new ContentPanelViewModel();
    const contentPanelStyles = contentPanelViewModel.getStyles();

    const wrapperViewModel = new WrapperViewModel();
    const wrapperStyles = wrapperViewModel.getStyles();

    return (
        <Box sx={styles.container}>
            <SidebarContext.Provider value={{ selectedPanel, setSelectedPanel }}>
                <Header />
                <Box sx={styles.sidebarContent}>
                    <SidebarWrapper />
                    <Box sx={contentPanelStyles.content}>
                        <Paper sx={contentPanelStyles.paperContent}>
                            <Box sx={contentPanelStyles.rootPanel}>
                                <Paper sx={contentPanelStyles.paperPanel}>
                                    <Box sx={wrapperStyles.whiteBox}>{children}</Box>
                                </Paper>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </SidebarContext.Provider>
            <CustomSnackbar open={false} message="" severity="success" />
        </Box>
    );
};

export default WrapperComponent;
