import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { BilanTabMenuIconName } from 'src/utils/constants/constants';

export type ModuleDetailContent = {
    selectedPanel: string;
    setSelectedPanel?: any;
    moduleId?: string;
    isCreatingNewModule: boolean;
    setIsCreatingNewModule: Dispatch<SetStateAction<boolean>>;
    isRefreshListModule?: boolean;
    setIsRefreshListModule?: Dispatch<SetStateAction<boolean>>;
    disableSoumettreButton?: boolean;
    setDisableSoumettreButton?: Dispatch<SetStateAction<boolean>>;
};

export const ModuleDetailContext = createContext<ModuleDetailContent>({
    selectedPanel: BilanTabMenuIconName.INFORMATION,
    setSelectedPanel: '',
    moduleId: '',
    isCreatingNewModule: false,
    isRefreshListModule: false,
    setIsRefreshListModule: () => {},
    setIsCreatingNewModule: () => {},
    setDisableSoumettreButton: () => {},
    disableSoumettreButton: false,
});

export const useModuleDetailContext = () => useContext(ModuleDetailContext);
