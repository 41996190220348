import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2px',
        borderRadius: '2%',
        width: '100%',
        height: '100%',
        padding: '25px',
        boxSizing: 'border-box',
        backgroundColor : baseColors.white,
        ...baseBorderRadius.allSide,
    },
    videoContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '2%',
        width: '100%',
        height: '100%',
        // padding: '25px',
        paddingTop : '15px',
        boxSizing: 'border-box',
        backgroundColor : baseColors.white,
        ...baseBorderRadius.allSide,
    },
    leftContent: {
        height: '92vh',
        width: '96%',
    },
    rightContent: {
        height: '92vh',
        width: '100%',
        minWidth: 825,
    },
    patientListContainer: {
        height: '90%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {},
        '&::-webkit-scrollbar-thumb': {
            width: '5px',
            height: '25%',
            backgroundColor: baseColors.green.dark,
            borderRadius: '2px',
        },
    },
    buttonContainer: {
        marginTop: 25,
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
});

export const sxStyles: any = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
        height: '100%',
        zIndex: '100',
        marginBottom: '2rem',
    },
};

export default useStyles;
