import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { baseColors, baseFontFamily } from '../../utils/constants/baseStyles';
import { makeStyles } from '@mui/styles';
import { specialCharToUnderscore } from 'src/utils/utils';
type AppProps = {
    defaultValue?: string;
    label: string;
    placeholder: string;
    onChange: Function;
    width: any;
    height?: any;
    value?: string;
    inversed?: boolean;
    validator?: Function;
    errorMessage?: string;
    disabled?: boolean;
    multiline?: boolean;
    single?: boolean;
    idSuffix?: string;
    passwordInput?: boolean;
    defaultInputId?: string;
    onKeyPress?: Function;
    labelSize?: string;
    fontSize?: string;
};
const CustomTextField = styled(TextField)({});

const useStyles = makeStyles({
    input: {
        marginTop: 10,
        width: '100%',

        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.grey,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.green.primary,
            },
        },
    },
    inputInversed: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.green.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.grey,
            },
        },
    },
    inputInvalid: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.orange.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.orange.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.orange.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.orange.primary,
            },
        },
    },
});

export const InputField = ({
    defaultValue,
    value,
    validator,
    height,
    multiline,
    inversed,
    errorMessage,
    label,
    placeholder,
    onChange,
    width,
    single,
    disabled,
    idSuffix,
    passwordInput,
    defaultInputId,
    onKeyPress,
    labelSize,
    fontSize,
}: AppProps) => {
    const classes = useStyles();
    const [isValid, setIsValid] = useState(false);
    //const [defaultValue, setDefaultValue] = useState(value);

    useEffect(() => {
        if (!validator) {
            setIsValid(true);
        } else {
            if (value && value?.length > 0 && !validator(value)) {
                setIsValid(false);
            } else {
                setIsValid(true);
            }
        }
    }, [validator, value]);

    return (
        <div style={{ width: width }}>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                {label.length > 0 && (
                    <Grid direction={'row'} container alignItems={'center'}>
                        <Grid item>
                            <Typography fontFamily={baseFontFamily.OpenSansSemiBold} color={baseColors.black} fontSize={labelSize ? labelSize : '9.33px'}>
                                {label}
                            </Typography>
                        </Grid>
                        {!isValid && (
                            <Grid item>
                                <Typography
                                    color={baseColors.orange.primary}
                                    style={{ marginLeft: 5, fontStyle: 'italic' }}
                                    fontSize={'9.33px'}
                                >
                                    ({errorMessage})
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}

                <CustomTextField
                    defaultValue={defaultValue}
                    value={value}
                    size="small"
                    
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(event.target.value);
                        // if (validator) {
                        //     setIsValid(validator(event.target.value));
                        // }
                        //setDefaultValue(event.target.value);
                    }}
                    type={'text'}
                    placeholder={placeholder}
                    multiline={multiline && multiline}
                    className={isValid ? (inversed ? classes.inputInversed : classes.input) : classes.inputInvalid}
                    InputProps={{
                        style: { minHeight: height, fontSize: fontSize ? fontSize : 10 },
                        type: passwordInput ? 'password' : undefined,
                    }}
                    style={{
                        marginTop: !single ? 10 : 0,
                        marginBottom: !single ? 20 : 0,
                    }}
                    disabled={disabled && disabled}
                    id={specialCharToUnderscore(`${defaultInputId ?? label}-${idSuffix}`)}
                />
            </div>
        </div>
    );
};
