import { Box, CardMedia } from '@mui/material';
import { truncate } from './ModuleItem';
import { VideoPreview } from './VideoPreview';
import { VideoContent } from './VideoContent';
import VideoPreviewViewModel from './VideoPreviewViewModel';
import { ThumbnailContent } from './ThumbnailContent';

export interface VideoUiData {
    module: any;
    selected: boolean;
    link?: string;
}

export interface ThumbnailItemProps {
    data: VideoUiData;
}
export const thumbnailItemProps: ThumbnailItemProps = {
    data: {
        module: {},
        selected: false,
        link: '',
    },
};

export const ThumbnailItem = (props: ThumbnailItemProps = thumbnailItemProps) => {
    const vm = new VideoPreviewViewModel();
    const styles = vm.getStyles();
    const { data } = props;
    const { module, selected, link } = data;

    return (
        <>
            <Box
                sx={{
                    ...styles.moduleIcon,
                    '@media screen and (max-width: 1000px)': {
                        display: 'none',
                        width: 0,
                    },
                }}
            >
                {link && link?.length !== 0 ? (
                    <ThumbnailContent width="100%" height="75px" src={link} />
                ) : (
                    <VideoPreview width="100%" height="75px" alt="Unavailable" />
                )}
            </Box>
            <Box sx={styles.moduleText}>
                <Box
                    sx={
                        selected
                            ? {
                                  ...styles.moduleItemTitle,
                                  color: styles.moduleItemTitleSelected.color,
                              }
                            : styles.moduleItemTitle
                    }
                >
                    {module.title}
                </Box>
                <Box
                    sx={
                        selected
                            ? {
                                  ...styles.moduleItemDescription,
                                  color: styles.moduleItemDescriptionSelected.color,
                              }
                            : styles.moduleItemDescription
                    }
                >
                    {truncate(module.description)}
                </Box>
            </Box>
        </>
    );
};
