interface PlayButtonIconProps {
    width: string;
    height: string;
}

const playButtonIcon = {
    width: '50',
    height: '50',
};

export const PlayButtonIcon = (props: PlayButtonIconProps = playButtonIcon) => {
    const { width, height } = props;
    return (
        <>
            <svg
                fill="#000"
                width={width}
                height={height}
                viewBox="0 0 24 24"
                id="play"
                data-name="Flat Color"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle id="primary" cx="12" cy="12" r="10" fill="#3EABAB"></circle>
                <path
                    id="secondary"
                    d="M14.75,12.83,11.55,15A1,1,0,0,1,10,14.13V9.87A1,1,0,0,1,11.55,9l3.2,2.13A1,1,0,0,1,14.75,12.83Z"
                    fill="#fff"
                ></path>
            </svg>
        </>
    );
};
