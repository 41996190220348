class HeaderHandler {
    public parseCurrentUser(action: any, data: any) {
        const queryData = action.query.data;
        data.setCurrentUserViewModel({
            ...data.currentUserViewModel,
            firstName: queryData?.currentUser.firstName,
            lastName: queryData?.currentUser.lastName,
            profileUrl: queryData?.currentUser.profilePicture?.fileUrl
        });
    }
}

export default HeaderHandler;
