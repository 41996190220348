import { Box } from '@mui/material';
import SidebarMenuHelper from './SidebarMenuHelper';
import SidebarMenuViewModel from './SidebarMenuViewModel';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
interface SidebarMenuProps {
    id?: number;
    icon: any;
    title: string;
    selected: boolean;
    onClick?: Function;
}

const sidebarMenuProps: SidebarMenuProps = {
    icon: '',
    title: '',
    selected: false,
    onClick: () => {},
};

export const SidebarMenu = (props: SidebarMenuProps = sidebarMenuProps) => {
    const { icon, title, onClick, selected } = props;

    const viewModel = new SidebarMenuViewModel();
    const styles = viewModel.getStyles();
    const helper = new SidebarMenuHelper();
    let render;
    if (!selected) {
        render = (
            <Box sx={styles.sidebarMenuItem} onClick={() => helper.handleMenuOnClick({}, onClick)}>
                <Box sx={styles.titleBlock}>
                    <Box sx={styles.icon}>{viewModel.parseIcon(icon)}</Box>
                    <Box sx={styles.title}>
                        <CustomText
                            fontSize={14}
                            color={baseColors.green.cancel}
                            fontFamily={baseFontFamily.OpenSansRegular}
                        >
                            {title}
                        </CustomText>
                    </Box>
                </Box>
            </Box>
        );
    } else {
        render = (
            <Box sx={styles.sidebarMenuItemSelected} alignItems='center' onClick={() => helper.handleMenuOnClick({}, onClick)}>
                <Box sx={styles.titleBlock}>
                    <Box sx={styles.icon}>{viewModel.parseIcon('SELECTED_' + icon)}</Box>
                    <Box sx={styles.selectedTitle}>{title}</Box>
                </Box>
                <Box sx={styles.indicator}></Box>
            </Box>
        );
    }

    return render;
};
