import { useContext, createContext } from 'react';
import { Module } from 'src/interfaces/interfaces';

export type ModuleContent = {
    currentModule: Module | undefined;
    setCurrentModule: any;
    modules: Module[];
    setModules: any;
};

export const CurrentModuleContext = createContext<ModuleContent>({
    currentModule: {} as Module,
    setCurrentModule: '',
    modules: [],
    setModules: '',
});

export const useCurrentModuleContext = () => useContext(CurrentModuleContext);
