import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { LoginIcon } from 'src/components/Icon/LoginIcon';
import { CustomButton } from 'src/components/Button/CustomButton';
import { InputField } from 'src/components/InputField/InputField';
import { InputFieldPassword } from 'src/components/InputField/InputFieldPassword';
import { Link, Typography } from '@mui/material';
import { baseColors } from 'src/utils/constants/baseStyles';
import LoginViewModel from './LoginViewModel';
import LoginInput from './LoginInput';
import { useMutation, useQuery } from '@apollo/client';
import { LOGIN } from 'src/graphql/mutations/mutations';
import LoginHandler from './LoginHandler';
import { useNavigate } from 'react-router';
import { AuthContext, useAuthContext } from 'src/providers/AuthProvider';
import { LIST_AUTHORIZED_FEATURE_CODES } from 'src/graphql/queries/authorizations';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { increment, decrement } from '../../redux/features/counter/counterSlice';
import { isValidMail } from 'src/utils/helpers/InputValidator';

interface IProps {}

const Login: FC<IProps> = (props) => {
    const viewModel = new LoginViewModel();
    const styles = viewModel.getStyles();
    const [input, setInput] = useState<Partial<LoginInput>>(new LoginInput());
    const [loginCheck, { data, loading, error }] = useMutation(LOGIN);
    const validInput = (input : any) => {
        if(isValidMail(input.username) && input.password.trim()?.length >= 8){
            return false;
        }
        else {
            return true;
        }
    }

    const handler = new LoginHandler();
    const navigate = useNavigate();
    const { login, isLoggedIn } = useAuthContext();

    const getAuthorizedFeatureCodesQuery = useQuery(LIST_AUTHORIZED_FEATURE_CODES);
    const { setCodes } = useAuthorizationContext();

    const parseData = (results: any) => {
        let codesData = results ? results : {};
        codesData =
            Object.keys(codesData).indexOf('listAuthorizedFeatureCodes') !== -1
                ? codesData.listAuthorizedFeatureCodes
                : {};
        const items = codesData?.items;
        return items ? items : [];
    };

    useEffect(() => {
        if (isLoggedIn) {
            getAuthorizedFeatureCodesQuery.refetch().then((results) => {
                setCodes(parseData(results?.data));
            });
            navigate('/');
        }
        const keyDownHandler = (event: { key: string; preventDefault: () => void }) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handler.authenticate({ loginCheck, data, error, viewModel, login }, input.username, input.password);
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isLoggedIn, input]);

    return (
        <Box sx={styles.loginContainer}>
            <Box sx={styles.loginContent}>
                <Box sx={styles.loginIconContainer}>
                    <LoginIcon />
                </Box>
                <Box sx={styles.loginTitleContainer}>
                    <Typography color={baseColors.green.primary} variant="h5" sx={styles.loginTitle}>
                        Application d'enseignement en éducation thérapeutique
                    </Typography>
                </Box>
                <Box sx={styles.usernameContainer}>
                    <InputField
                        label="Nom d'utilisateur"
                        placeholder="Entrez votre nom d'utilisateur"
                        width={'335px'}
                        height={'39px'}
                        value={input.username}
                        onChange={(value: any) => viewModel.changeUsername(value, { input, setInput })}
                        fontSize='16px'
                        labelSize='16px'
                    />
                </Box>
                <Box sx={styles.passwordContainer}>
                    <InputFieldPassword
                        label="Mot de passe"
                        placeholder="Minimum 8 caractères"
                        width={'335px'}
                        height={'49px'}
                        value={input.password}
                        onChange={(value: any) => viewModel.changePassword(value, { input, setInput })}
                        labelSize='16px'
                        fontSize='16px'
                    />
                </Box>
                {error ? (
                    <Box sx={styles.loginErrorContainer}>
                        <Link color={baseColors.orange.secondary} sx={styles.loginError}>
                            Nom d'utilisateur ou mot de passe incorrect
                        </Link>
                    </Box>
                ) : (
                    ''
                )}
                <Box sx={styles.submitLoginContainer}>
                    <CustomButton
                        label={'Se connecter'}
                        width={'335px'}
                        onClick={() => {
                            handler.authenticate(
                                { loginCheck, data, error, viewModel, login },
                                input.username,
                                input.password,
                            );
                        }}
                        loading={loading}
                        disabled={validInput(input)}
                    />
                </Box>
                <Box sx={styles.forgetPasswordContainer}>
                    <Link color={'#3EABAB'} href="/passwordForgot" sx={styles.forgetPasswordLink}>
                        Mot de passe oublié
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
