export class ExplicativeVideoHandler {
    public handleEndVideo(payload: any) {
        const { startTime, endTime, setHideNextBtn } = payload;

        const diffTime = Math.round(endTime - startTime);
        console.log(
            ` startTime: ${startTime} -  endTime :${endTime} = diffTime ${diffTime}  Note: endingTime is : ${endTime}`,
        );

        const explicativeVideo = document.getElementById('explicativeVideo');

        if (explicativeVideo instanceof HTMLMediaElement) {
            const duration = Math.round(explicativeVideo.duration);
            console.log('duration', duration);

            const timeRate = Math.round((diffTime * 100) / duration);

            const minTimeRate = Number.parseInt(process.env.REACT_APP_VIEWING_TIME_RATE ?? '0');

            console.log('timeRate', timeRate + '%');
            console.log('minTimeRate', minTimeRate + '%');
            if (timeRate >= minTimeRate) {
                setHideNextBtn(false);
            }
        }
    }

    public dateNow() {
        const now = new Date(Date.now());
        return Number.parseInt(
            `${now.getFullYear()}${now.getMonth()}${now.getDay()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`,
        );
    }
}
