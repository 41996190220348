import {
    Box,
    Card,
    Divider,
    Grid,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { Choice, Question } from 'src/interfaces/interfaces';

import AddIcon from '@mui/icons-material/Add';
import { ChoiceList } from './ChoiceList';
import { CustomInputField } from 'src/components/InputField/CustomInputField';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputField } from 'src/components/InputField/InputField';
import { QuestionItemViewModel } from './QuestionItemViewModel';
import { QuestionType } from 'src/utils/constants/constants';
import styled from '@emotion/styled';
import { useQuizzCreationContext } from 'src/providers/QuizzProvider';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';

interface IQuestionItemProps {
    question: Question;
    readOnly: boolean;
}

export const QuestionItem = (props: IQuestionItemProps) => {
    const vm = new QuestionItemViewModel();
    const styles = vm.getStyles();

    const { removeQuestion, updateQuestion } = useQuizzCreationContext();

    const [question, setQuestion] = useState<Question>(props['question']);
    const [questionText, setQuestionText] = useState<string>(question.text);
    const readOnly = props.readOnly;

    const handleQuestionTypeChange = (event: SelectChangeEvent) => {
        setQuestion({
            ...question,
            type: event.target.value,
        });
        updateQuestion(question.id, {
            type: event.target.value,
        });
    };

    const handleQuestionTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        const textValue = event.target.value;

        setQuestionText(textValue);
        updateQuestion(question.id, {
            text: textValue,
        });
    };

    const handleRemoveQuestion = () => {
        removeQuestion(question);
    };

    return (
        <Card sx={styles.questionCard} variant="outlined">
            <Box
                sx={{
                    padding: '25px',
                    boxSizing: 'border-box',
                }}
            >
                {!readOnly ? (
                    <>
                        <Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" pb="10px">
                                <Select
                                    value={question.type}
                                    onChange={handleQuestionTypeChange}
                                    sx={styles.smallSelect}
                                >
                                    <MenuItem value={QuestionType.TEXT}>Texte libre</MenuItem>
                                    <MenuItem value={QuestionType.CHECKBOX}>Choix multiple</MenuItem>
                                    <MenuItem value={QuestionType.RADIO}>Choix unique</MenuItem>
                                </Select>
                                <IconButton aria-label="delete" size="small" onClick={handleRemoveQuestion}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>

                            <TextField
                                multiline
                                sx={styles.textarea}
                                value={questionText}
                                onChange={handleQuestionTextChange}
                                InputProps={{ readOnly: readOnly }}
                            ></TextField>
                        </Box>
                        <Box fontSize={13}>
                            {QuestionType.TEXT !== question.type && (
                                <ChoiceList readOnly={readOnly} question={question}></ChoiceList>
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography fontFamily={baseFontFamily.OpenSansRegular} fontSize={14} fontWeight={700}>
                            {question.type == QuestionType.TEXT
                                ? 'Question - Texte libre :'
                                : question.type == QuestionType.RADIO
                                ? 'Question - Choix unique :'
                                : question.type == QuestionType.CHECKBOX
                                ? 'Question - Choix multiple :'
                                : ' '}
                        </Typography>
                        <Box
                            sx={{
                                color: '#0D4447',
                                fontFamily: baseFontFamily.OpenSansRegular,
                                fontSize: 14,
                                marginTop: '15px',
                            }}
                        >
                            {question.text}
                            <Spacer height={15} />
                            {question.type !== QuestionType.TEXT && (
                                <>
                                    <Divider />
                                    <Spacer height={5} />

                                    {question.choices.map((choice) => (
                                        <ul key={choice.id} style={{
                                            paddingLeft : '25px',
                                            color : '#136668'
                                        }}>
                                            <li>{choice.text}</li>
                                        </ul>
                                    ))}
                                </>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Card>
    );
};
