import { ROUTES } from 'src/routes/routes';
import { useIdleTimer } from 'react-idle-timer';
import { FRONT_EXPIRATION_DELAY } from 'src/utils/constants/constants';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import { LOGOUT } from 'src/graphql/mutations/mutations';

const useLogout = () => {
    const navigate = useNavigate();

    const STORAGE_KEY = `token`;

    const removeUser = (url?: string) => {
        const currentUrl = window.location.origin;
        localStorage.removeItem(STORAGE_KEY);
        sessionStorage.removeItem(STORAGE_KEY);
        localStorage.removeItem('user');
        localStorage.clear();
        window.location.reload();
        window.location.href = url ? url : `${currentUrl}/login`;
    };

    const [logoutMutation, { data, loading, error }] = useMutation(LOGOUT, {
        variables: {
            token: localStorage.getItem('token'),
        },
        onCompleted: (data) => {
            removeUser();
        },
        onError: (error) => {
            console.log(error, 'There is an error');
        },
    });

    const performLogout = () => {
        logoutMutation();
        navigate(ROUTES.login);
    };

    const onIdle = () => performLogout();

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: FRONT_EXPIRATION_DELAY,
    });

    return {
        getRemainingTime,
    };
};

export default useLogout;
