import { gql } from '@apollo/client';
import { VIDEO_FRAGMENT } from '../fragments/fragments';

const LIST_MODULES = gql`
    query ListModules {
        listModules {
            id
            title
        }
    }
`;

const LIST_MODULES_BY_TYPE = gql`
    query ListModules($type: String!) {
        listModules(listModuleInput: { type: $type }) {
            id
            title
        }
    }
`;

const LIST_MODULES_BY_TYPE_WITH_VIDEO = gql`
    ${VIDEO_FRAGMENT}
    query ListModules($type: String!) {
        listModules(listModuleInput: { type: $type }) {
            id
            title
            description
            video {
                ...VideoFields
            }
            videoAnnote {
                ...VideoFields
            }
            moduleIsValidated
            type
            quizz {
                id
                type
                text
                choices {
                    text
                }
            }
        }
    }
`;

const GET_MODULE = gql`
    query GetModule($id: String!) {
        getModuleById(id: $id) {
            id
            title
            description
            moduleIsValidated
            video {
                id
            }
            videoAnnote {
                id
            }
            type
            quizz {
                id
            }
        }
    }
`;
export { LIST_MODULES, GET_MODULE, LIST_MODULES_BY_TYPE, LIST_MODULES_BY_TYPE_WITH_VIDEO };
