import { Box, Paper } from '@mui/material';
import { FC } from 'react';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { SubmenuSetting } from './SubmenuSetting/SubmenuSetting';
import { SubmenuSettingPanel } from './SubmenuSettingPanel/SubmenuSettingPanel';

const Setting: FC<any> = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();
    return (
        <Box sx={styles.rootPanel}>
            <Paper sx={styles.paperPanel}>
                <SubmenuSetting />
                <SubmenuSettingPanel />
            </Paper>
        </Box>
    );
};

export { Setting };
