import { gql } from "@apollo/client"
import { USER_FRAGMENT } from "../fragments/fragments";

const CREATE_USER = gql`
    ${USER_FRAGMENT}
    mutation CreateUser($userData: CreateUserDataInput!) {
        createUser(userData: $userData) {
            message
            user {
                ...UserFields
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation UPDATE_USER($userData: UpdateUserInput!) {
        updateUser(updateUserInput: $userData) {
            firstName
            lastName
            birthDate
            age
            birthPlace
            profession
            adress
            phoneNumber
            sex
            email
            profilePicture {
                fileUrl
            }
        }
    }
`

const ACTIVATE_USER = gql`
    ${USER_FRAGMENT}
    mutation ACTIVATE_USER($userData: ActivateUserInput!) {
        activateUser(activateUserInput: $userData) {
            ...UserFields
        }
    }
`

export { CREATE_USER, UPDATE_USER, ACTIVATE_USER }