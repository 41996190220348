import { ReactNode, useContext, useState } from 'react';

import { Question } from 'src/interfaces/interfaces';
import { QuizzCreationContext } from './QuizzContext';

type QuizzCreationPropsType = {
    children?: ReactNode;
};

export const QuizzCreationProvider = (props: QuizzCreationPropsType) => {
    const { children } = props;
    const [questions, setQuestions] = useState<Question[]>([]);
    // console.log('render QuizzCreationProvider', 'questions is ', questions);

    const addQuestion = (question: Question) => {
        setQuestions([...questions, question]);
    };

    const removeQuestion = (question: Question) => {
        setQuestions(questions.filter((q) => q.id !== question.id));
    };

    const updateQuestion = (id: string, updatedValues: any) => {
        let tempQuestions = [...questions];

        for (let i = 0; i < tempQuestions.length; i++) {
            if (tempQuestions[i].id === id) {
                tempQuestions[i] = {
                    ...tempQuestions[i],
                    ...updatedValues,
                };
            }
        }

        setQuestions(tempQuestions);
    };

    return (
        <QuizzCreationContext.Provider
            value={{
                addQuestion: addQuestion,
                removeQuestion: removeQuestion,
                updateQuestion: updateQuestion,
                initializeQuestions: setQuestions,
                questions: questions,
            }}
        >
            {children}
        </QuizzCreationContext.Provider>
    );
};

export const useQuizzCreationContext = () => useContext(QuizzCreationContext);
