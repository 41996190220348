import * as React from 'react';

function SvgMail(props) {
    return (
        <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="104" height="104" rx="10" fill="#3EABAB" />
            <g clipPath="url(#clip0_2650_15367)">
                <path
                    d="M73.2822 47.7641V71.8542C73.2867 72.4552 73.0325 73.0291 72.5847 73.4297L72.5214 73.493C72.1351 73.8223 71.6436 74.0024 71.1359 74.0006H32.8647C32.3607 74.0005 31.8732 73.8205 31.49 73.493C31.4638 73.4776 31.4421 73.4558 31.4267 73.4297C30.9754 73.0304 30.7173 72.4567 30.7179 71.8542V47.7641C30.7129 47.2285 30.9178 46.7122 31.2888 46.326C31.4401 46.1475 31.6218 45.997 31.8254 45.8817C32.1431 45.7079 32.4994 45.6169 32.8615 45.6172H71.1354C71.7362 45.628 72.3075 45.8793 72.7216 46.3147C73.0814 46.7118 73.2813 47.2282 73.2822 47.7641Z"
                    fill="#F39041"
                />
                <path
                    d="M72.724 46.312C72.5732 46.4982 52.0117 62.8502 52.0117 62.8502L31.2881 46.3302L31.2972 46.3216V46.3125L50.8382 30.416C51.5202 29.8613 52.4979 29.8613 53.18 30.416L72.724 46.312Z"
                    fill="#F39041"
                />
                <path d="M68.3521 35.2266V49.8314L52.0093 62.8491L35.6572 49.8138V35.2266H68.3521Z" fill="#FFF9F5" />
                <path
                    d="M72.5819 73.4267C72.5636 73.4496 72.5426 73.4705 72.5197 73.4889L54.1104 61.2086L55.7952 59.8516L72.5819 73.4267Z"
                    fill="#FFF9F5"
                />
                <path
                    d="M49.8983 61.2017L31.489 73.4895C31.462 73.4762 31.4401 73.4544 31.4268 73.4272L48.2048 59.8359L49.8983 61.2017Z"
                    fill="#FFF9F5"
                />
                <path d="M39.2793 39.0625H64.7206V40.1663H39.2793V39.0625Z" fill="#1B8E95" />
                <path d="M39.2793 43.3203H64.7206V44.4241H39.2793V43.3203Z" fill="#1B8E95" />
                <path d="M39.2793 47.5781H64.7206V48.6819H39.2793V47.5781Z" fill="#1B8E95" />
                <path d="M43.5518 51.8359H60.4479V52.9397H43.5518V51.8359Z" fill="#1B8E95" />
            </g>
            <defs>
                <clipPath id="clip0_2650_15367">
                    <rect width="44" height="44" fill="white" transform="translate(30 30)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgMail;
