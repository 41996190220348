import { gql } from '@apollo/client';

const LIST_TOOL_BOXS = gql`
    query ListToolBoxs {
        listToolBox {
            id
            title
            description
            fileName
            originalName
            category
            filePath
            fileUrl
            mimeType
        }
    }
`;
export { LIST_TOOL_BOXS };
