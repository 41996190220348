interface SelectedToolBoxIconProps {
    width: string;
    height: string;
    color: string;
}

const selectedtoolBoxIconProps = {
    width: '50',
    height: '50',
    color: ''
};

export const SelectedToolBoxIcon = (props: SelectedToolBoxIconProps = selectedtoolBoxIconProps) => {
    const { width, height, color } = props;
    return (
        <>
            <svg width={width} height={height} viewBox="-5 -5 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_801_20894)">
                    <rect width="40" height="40" rx="6" fill={color}  stroke='white' stroke-width="1.75" stroke-linecap="round" />
                    <g clipPath="url(#clip1_801_20894)">
                        <path d="M13.0884 27.2154H26.1875V28.5382H13.0884V27.2154Z" fill="#F6B863" />
                        <path
                            d="M21.9244 10.6994H23.5033L23.0001 8.60969C22.913 8.19468 22.5059 7.9289 22.0909 8.01602C21.6759 8.10314 21.4101 8.51022 21.4972 8.92519C21.5006 8.94126 21.5045 8.95729 21.5089 8.97311L21.9244 10.6994Z"
                            fill="#F6B863"
                        />
                        <path
                            d="M28.4036 31.0504L26.2391 22.0591H24.6602L26.9116 31.413C26.995 31.7571 27.3028 31.9997 27.6569 32.0004C27.7184 32.0003 27.7796 31.993 27.8393 31.9785C28.2514 31.878 28.504 31.4625 28.4036 31.0504V31.0504Z"
                            fill="#F6B863"
                        />
                        <path
                            d="M13.4638 22.0591L11.3001 31.0495C11.1997 31.4615 11.4522 31.877 11.8641 31.9776C11.9239 31.9924 11.9853 32 12.0468 32.0004C12.4008 32 12.7086 31.7578 12.7924 31.4139L15.0435 22.0591H13.4638Z"
                            fill="#F6B863"
                        />
                        <path
                            d="M17.7776 10.7003L18.1931 8.9752C18.3067 8.56663 18.0676 8.14337 17.659 8.02976C17.2504 7.91614 16.8272 8.15528 16.7136 8.56385C16.7092 8.57968 16.7053 8.5957 16.7019 8.61178L16.1987 10.7003H17.7776Z"
                            fill="#F6B863"
                        />
                        <path
                            d="M9.71328 9.60449H30.2865C30.7396 9.60449 31.1069 9.97183 31.1069 10.425V23.865C31.1069 24.3181 30.7396 24.6854 30.2865 24.6854H9.71328C9.26016 24.6854 8.89282 24.3181 8.89282 23.865V10.4249C8.89282 9.97183 9.26016 9.60449 9.71328 9.60449V9.60449Z"
                            fill="#DBD8DD"
                        />
                        <path d="M9.90283 10.6139H30.0977V23.6753H9.90283V10.6139Z" fill="white" />
                        <path
                            d="M27.0806 15.1514V22.6714H12.9202V19.4288L18.1955 15.9274L20.9779 19.1443L27.0806 15.1514Z"
                            fill="#3EABAB"
                        />
                        <path
                            d="M12.9202 17.1451V16.5244L18.242 12.992L21.0252 16.2103L27.0806 12.2487V12.8666L20.9305 16.8907L18.149 13.6747L12.9202 17.1451Z"
                            fill="#575B6D"
                        />
                        <path
                            d="M20.9778 17.5212C21.5492 17.5212 22.0123 17.0581 22.0123 16.4867C22.0123 15.9154 21.5492 15.4522 20.9778 15.4522C20.4065 15.4522 19.9434 15.9154 19.9434 16.4867C19.9434 17.0581 20.4065 17.5212 20.9778 17.5212Z"
                            fill="#F39041"
                        />
                        <path
                            d="M26.9115 13.692C27.4828 13.692 27.9459 13.2288 27.9459 12.6575C27.9459 12.0861 27.4828 11.623 26.9115 11.623C26.3401 11.623 25.877 12.0861 25.877 12.6575C25.877 13.2288 26.3401 13.692 26.9115 13.692Z"
                            fill="#EB6800"
                        />
                        <path
                            d="M18.2164 14.3173C18.7877 14.3173 19.2509 13.8542 19.2509 13.2829C19.2509 12.7115 18.7877 12.2484 18.2164 12.2484C17.645 12.2484 17.1819 12.7115 17.1819 13.2829C17.1819 13.8542 17.645 14.3173 18.2164 14.3173Z"
                            fill="#0D4447"
                        />
                        <path
                            d="M13.0882 17.7764C13.6595 17.7764 14.1227 17.3132 14.1227 16.7419C14.1227 16.1705 13.6595 15.7074 13.0882 15.7074C12.5169 15.7074 12.0537 16.1705 12.0537 16.7419C12.0537 17.3132 12.5169 17.7764 13.0882 17.7764Z"
                            fill="#2CBFAE"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_801_20894">
                        <rect width="40" height="40" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_801_20894">
                        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
