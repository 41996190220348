import { useQuery } from '@apollo/client';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { FC, useEffect, useState } from 'react';
import { PdfIcon } from 'src/components/Icon/PdfIcon';
import { GET_TRAME_By_CATEGORY } from 'src/graphql/queries/queries';
import { ModuleType } from 'src/utils/constants/constants';
import { TabPanelViewModel } from './TabPanelViewModel';
import { CustomButton } from 'src/components/Button/CustomButton';
import { DownloadActionButton } from 'src/pages/ToolBox/DownloadActionButton';
import { Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';

interface IProps {
    moduleType: ModuleType.EDUCATIVE_BILAN;
    moduleId?: string;
}

export const EvaluationsPanel: FC<IProps> = ({ moduleType, moduleId }) => {
    moduleId = moduleId ?? 'defaultModuleId';
    const vm = new TabPanelViewModel();
    const styles = vm.getStyles();
    const [evaluationList, setEvaluationList] = useState<any[]>([]);

    const {
        loading,
        error,
        data: dataEvaluationListQuery,
    } = useQuery(GET_TRAME_By_CATEGORY, {
        variables: {
            moduleType: moduleType,
            moduleId: moduleId,
        },
    });
    useEffect(() => {
        if (dataEvaluationListQuery?.getAllTramesByCategory) {
            let data = dataEvaluationListQuery?.getAllTramesByCategory;
            setEvaluationList((oldstate: any[]) => {
                return data.map((trames: any) => {
                    return {
                        firstName: trames.student.firstName,
                        lastName: trames.student.lastName,
                        email: trames.student.email,
                        fileName: trames?.userModuleInfo?.pdfResponse?.fileName,
                        fileUrl: trames?.userModuleInfo?.pdfResponse?.fileUrl,
                    };
                });
            });
        }
    }, [dataEvaluationListQuery]);

    return (
        <Box sx={styles.panel}>
            {loading ? (
                <CustomCircleLoader></CustomCircleLoader>
            ) : (
                <TableContainer component={Paper} style={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="role toolbox">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.green.cancel}
                                        fontSize={11}
                                    >
                                        Nom
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.green.cancel}
                                        fontSize={11}
                                    >
                                        Prénom
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.green.cancel}
                                        fontSize={11}
                                    >
                                        Email
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.green.cancel}
                                        fontSize={11}
                                    >
                                        Formulaires remplis
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.green.cancel}
                                        fontSize={11}
                                    >
                                        Action
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {evaluationList.map((trames: any) => {
                                return (
                                    <TableRow
                                        key={trames.email}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {/* <TableCell>{toolbox.id}</TableCell> */}
                                        <TableCell component="th" align="center" scope="row">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansRegular}
                                                color={baseColors.green.cancel}
                                                fontSize={11}
                                            >
                                                {trames.firstName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" align="center" scope="row">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansRegular}
                                                color={baseColors.green.cancel}
                                                fontSize={11}
                                            >
                                                {trames.lastName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" align="center" scope="row">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansRegular}
                                                color={baseColors.green.cancel}
                                                fontSize={11}
                                            >
                                                {trames.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" align="center" scope="row">
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                {trames.fileName ? (
                                                    <>
                                                        <PdfIcon width="26px" height="26px" />
                                                        &nbsp; 
                                                        <Typography
                                                            fontFamily={baseFontFamily.OpenSansRegular}
                                                            color={baseColors.green.cancel}
                                                            fontSize={11}
                                                        >
                                                            {trames.fileName}
                                                        </Typography>
                                                        
                                                    </>
                                                ) : (
                                                    <Typography
                                                        fontFamily={baseFontFamily.OpenSansRegular}
                                                        color={baseColors.green.cancel}
                                                        fontSize={11}
                                                    >
                                                        0
                                                    </Typography>
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell component="th" align="center" scope="row">
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <a
                                                    href={`${trames.fileUrl}`}
                                                    style={{ marginRight: '10px', textDecoration: 'none' }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download
                                                >
                                                    <DownloadActionButton onClick={() => {}} />
                                                </a>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};
