import { Box } from '@mui/system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import { selectCurrentModule } from 'src/redux/features/listModules/listModulesSlice';
// import { selectCurrentModule } from 'src/redux/features/currentModule/currentModuleSlice';
import { educativeBilanTabMenus } from 'src/utils/constants/constants';
import ModuleDetailHandler from './ModuleDetailHandler';
import ModuleDetailHandlerCreate from './ModuleDetailHandlerCreate';
import ModuleDetailViewModel from './ModuleDetailViewModel';

const ModuleDetailTabCreate = (props: any = {}) => {
    const vm = new ModuleDetailViewModel();
    const handler = new ModuleDetailHandlerCreate();
    const styles = vm.getStyles();
    const { selectedPanel, setSelectedPanel } = useModuleDetailContext();
    const tabMenuItems = educativeBilanTabMenus.filter((menu: any) => {
        return menu.showInCreateModule;
    });
    // const { module } = props;
    // const { currentModule, setCurrentModule } = useCurrentModuleContext();
    // const module = currentModule;
    const module = useSelector(selectCurrentModule);
    return (
        <Box sx={styles.detailTab}>
            <Box sx={styles.tabMenuContainer}>
                {handler.handleTabMenu({ tabMenuItems, selectedPanel, setSelectedPanel, module })}
            </Box>
            <Box sx={styles.separator}></Box>
        </Box>
    );
};

export default ModuleDetailTabCreate;
