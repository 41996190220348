import { Avatar, Box, Typography } from '@mui/material';
import ProfileImageViewModel from './ProfileImageViewModel';

interface ProfileImageProps {
    width: string;
    height: string;
    alt: string;
    imageUrl?: string;
    marginLeft?: number;
    marginTop?: number;
}

const profileImageProps = {
    width: '50',
    height: '50',
    alt: '',
    imageUrl: undefined,
    marginLeft: 0,
    marginTop: 0,
};
export const ProfileImage = (props: ProfileImageProps = profileImageProps) => {
    const viewModel = new ProfileImageViewModel();
    const styles = viewModel.getStyles();
    const { width, height, alt, imageUrl, marginLeft, marginTop } = props;

    return (
        <Box sx={styles.image} width={width} height={height} marginLeft={marginLeft} marginTop={marginTop} >
            {
                imageUrl ?
                    <Avatar alt={alt} src={imageUrl}/> :
                    <Typography variant="h5">{alt}</Typography>
            }
        </Box>
    );
};
