import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

export class QuestionItemViewModel {
    private styles = {
        questionCard: {
            'padding': 1,
            'marginBottom': 3,
            border: 'none',
            backgroundColor : baseColors.green.light,
            borderRadius : '10px' 
        },
        smallSelect: {
            '.MuiSelect-select': {
                fontSize: 14,
                fontFamily : baseFontFamily.OpenSansRegular,
                padding: [0, 1.2, 0, 0.7],
                border: 'none',
                color: baseColors.black,
                fontWeight: 400
            },
            'boxShadow': 'none',
            '.MuiOutlinedInput-notchedOutline': {
                border: 0
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
                boxShadow: 'none'
            },
            '&.MuiSelect-icon': {
                color: baseColors.green.primary
            },
            '& .MuiMenuItem-root': {
                fontSize: '0.6em'
            }
        },
        textarea: {
            backgroundColor: baseColors.white,
            '& .Mui-focused': {
                border: '1px solid',
                borderColor: baseColors.green.primary,
                '& fieldset': {
                    border: 'none'
                }
            },
            '&.MuiTextField-root': {
                width: '100%'
            }
        },
        choiceInput: {
            backgroundColor: baseColors.white,
            '& fieldset': {
                border: 'none'
            },
            '&.MuiTextField-root': {
                width: '100%'
            }
        },
        choiceListItem: {
            '&.MuiListItem-root': {
                '&:hover': {
                    backgroundColor: baseColors.green.light,
                    borderRadius: '5px'
                }
            }
        }
    };

    public getStyles() {
        return this.styles;
    }
}
