import { baseColors } from 'src/utils/constants/baseStyles';

interface DownloadIconProps {
    width: string;
    height: string;
}

const downloadIconProps: DownloadIconProps = {
    width: '24px',
    height: '21px',
};

export const DownloadIcon = (props: DownloadIconProps = downloadIconProps) => {
    const { width, height } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0V7.875H3L12 15.75L21 7.875H15V0H9ZM0 18.375V21H24V18.375H0Z" fill={baseColors.green.dark} />
        </svg>
    );
};
