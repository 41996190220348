import * as React from 'react';
import { Avatar, Box, Grid, ListItemButton, Typography } from '@mui/material';
import { displaySex, formatToDate } from 'src/utils/utils';

import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { CustomText } from 'src/components/Text/CustomText';
import { FC } from 'react';
import { GET_CURRENT_USER_DETAILS, GET_TRAME_STUDENT } from 'src/graphql/queries/queries';
import ProfileViewModel from './ProfileViewModel';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { CustomButton } from 'src/components/Button/CustomButton';
import { ContactUs } from './ContactUs';
import SectionEvaluation from './SectionEvaluation';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { AuthorizationCode } from 'src/utils/constants/constants';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { UploadPhoto } from 'src/components/shared/UploadPhoto/UploadPhoto';
import { UPDATE_USER } from 'src/graphql/mutations/user.mutation';
import { UserAdapter } from 'src/interfaces/adapter/UserAdapter';
import { SnackBarContext } from 'src/providers/SnackBarProvider';

interface IProps {}

export const Profile: FC<IProps> = (props) => {
    const profileViewModel = new ProfileViewModel();
    const styles = profileViewModel.getStyles();
    const { loading, error, data, refetch } = useQuery(GET_CURRENT_USER_DETAILS);
    const { showSuccessSnackBar, showErrorSnackBar } = React.useContext(SnackBarContext);
    const [currentUser, setCurrentUser] = useState<any>(null);

    
    const [createUser, datas] = useMutation(UPDATE_USER, {
        onCompleted: (data: any) => {
            // const newId = data.createUser?.user?.id;
            // if (!newId) showErrorSnackBar("Echec creation d'utilisateur: " + data.createUser?.message);
            // else {
            showSuccessSnackBar("Photo de profil importé");
            // setUserFormValue(DEFAULT_FORM_TYPE);
            // setReload(true);
            // setIsOpened(false);
            // handleCreateNewUser(UserAdapter(data.createUser.user));
            refetch();
            // }
        },
        onError: (error: any) => {
            console.log(error);
            showErrorSnackBar("Échec de modification d'utilisateur");
        },
    });

    const { codes } = useAuthorizationContext();
    const isAuthorizedToSeeEvaluation = codes?.includes(AuthorizationCode.FRONT_DISPLAY_EVALUATION);

    useEffect(() => {
        if (data) {
            console.log(data['currentUser']);
            setCurrentUser(data['currentUser']);
        }
    }, [data]);

    const handleChange = (key: string, value: any) => {
        console.log(key, value);
        
        const userData = {
            userId: currentUser.id,
            // firstName: userFormValue.firstName,
            // lastName: userFormValue.lastName,
            // email: userFormValue.email,
            // role: userFormValue.roles,
            // promotion: userFormValue.promotionId,
            photoProfilContent: value,
        };
        createUser({
            variables: { userData },
        });
    };
    if (error) {
        console.log({ error });
    }

    return (
        <Box>
            <Box sx={styles.centeredInfo}>
                <CustomText fontSize={13.33} fontFamily={baseFontFamily.BreeSerif}>
                    Espace personnel
                </CustomText>
                {!loading && (
                    <UploadPhoto
                        urlPhoto={currentUser?.profilePicture?.fileUrl}
                        onUploadedPhoto={(photo: any) => {
                            handleChange('photoProfilContent', photo);
                        }}
                    />
                )}
            </Box>
            <Box sx={styles.centeredInfo}>
                {loading ? (
                    <CustomCircleLoader></CustomCircleLoader>
                ) : (
                    <Box sx={{ marginLeft: 10, marginRight: 10 }}>
                        <Box sx={{ marginBottom: 2 }}>
                            <CustomText fontSize={13.333} fontFamily={baseFontFamily.BreeSerif}>
                                Informations
                            </CustomText>
                        </Box>
                        <Grid container spacing={10}>
                            <Grid item md={6}>
                                <Box sx={styles.blockContainer} style={{ minHeight: '150px' }}>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Nom complet : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>
                                            {currentUser?.firstName} {currentUser?.lastName}
                                        </Typography>
                                    </Box>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Date de naissance : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>
                                            {formatToDate(currentUser?.birthDate)}
                                        </Typography>
                                    </Box>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Lieu de naissance : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>{currentUser?.birthPlace}</Typography>
                                    </Box>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Age : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>{currentUser?.age}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box sx={styles.blockContainer} style={{ minHeight: '150px' }}>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Profession : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>{currentUser?.profession}</Typography>
                                    </Box>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Adresse : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>{currentUser?.adress}</Typography>
                                    </Box>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Téléphone : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>{currentUser?.phoneNumber}</Typography>
                                    </Box>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Email : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>{currentUser?.email}</Typography>
                                    </Box>
                                    <Box sx={styles.fieldLine}>
                                        <Typography sx={styles.label}>Sexe : </Typography>{' '}
                                        <Typography sx={styles.fieldValue}>{displaySex(currentUser?.sex)}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {isAuthorizedToSeeEvaluation && (
                            <>
                                <Box sx={{ marginBottom: 2, marginTop: 2 }}>
                                    <CustomText fontSize={13.333} fontFamily={baseFontFamily.BreeSerif}>
                                        Évaluations
                                    </CustomText>
                                </Box>
                                <Box sx={styles.blockContainer}>
                                    <SectionEvaluation />
                                </Box>
                            </>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
