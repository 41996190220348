import { Choice, Question } from 'src/interfaces/interfaces';
import { IconButton, List, ListItem, ListItemText, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { QuestionItemViewModel } from './QuestionItemViewModel';
import { useQuizzCreationContext } from 'src/providers/QuizzProvider';
import { v4 as uuidv4 } from 'uuid';
import { baseColors } from 'src/utils/constants/baseStyles';

interface IChoiceListProps {
    question: Question;
    readOnly: boolean;
}

export const ChoiceList = (props: IChoiceListProps) => {
    const { question, readOnly } = props;
    const questionItemViewModel = new QuestionItemViewModel();

    const questionStyles = questionItemViewModel.getStyles();

    const [choices, setChoices] = useState<Choice[]>([]);
    const [currentAddedChoiceText, setCurrentAddedChoiceText] = useState<string>('');
    const { updateQuestion } = useQuizzCreationContext();

    useEffect(() => {
        initChoices();
    }, []);

    const initChoices = () => {
        updateChoices([...question.choices]);
    };

    const addChoice = () => {
        const newChoice = {
            id: uuidv4(),
            text: currentAddedChoiceText,
        } as Choice;

        updateChoices([...choices, newChoice]);

        setCurrentAddedChoiceText('');
    };

    const deleteChoice = (choice: Choice) => {
        updateChoices(choices.filter((c) => c !== choice));
    };

    const updateQuestionChoices = (choices: Choice[]) => {
        updateQuestion(question.id, {
            choices: [...choices],
        });
    };

    const updateChoices = (choices: Choice[]) => {
        setChoices(choices);
        updateQuestionChoices(choices);
    };

    return (
        <List dense={true}>
            {choices.map((choice) => {
                return (
                    <ListItem
                        key={choice.id}
                        secondaryAction={
                            !readOnly && (
                                <IconButton edge="end" aria-label="delete" onClick={(event) => deleteChoice(choice)}>
                                    <DeleteIcon />
                                </IconButton>
                            )
                        }
                        sx={questionStyles.choiceListItem}
                    >
                        <ListItemText primary={choice.text}></ListItemText>
                    </ListItem>
                );
            })}
            {!readOnly && (
                <ListItem
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={addChoice}
                            disabled={'' === currentAddedChoiceText}
                        >
                            <AddIcon />
                        </IconButton>
                    }
                    sx={{
                        paddingLeft: 0,
                    }}
                >
                    <ListItemText>
                        <TextField
                            value={currentAddedChoiceText}
                            onChange={(event) => setCurrentAddedChoiceText(event.target.value)}
                            sx={questionStyles.textarea}
                            placeholder="Entrer un choix de réponse..."
                        ></TextField>
                    </ListItemText>
                </ListItem>
            )}
        </List>
    );
};
