import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        minHeight: '68px',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
        justifyItems: 'center',
        marginTop: '15px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
});

export default useStyles;
