import { IconButton, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { CustomButton } from 'src/components/Button/CustomButton';
import { baseColors } from 'src/utils/constants/baseStyles';
import { PdfIcon } from 'src/components/Icon/PdfIcon';

const ListItemPdf = (props: any) => {
    const { trame, setOpen, setCurrentPdfResponseId } = props;

    const formatDate = (date: any) => {
        return new Date(date).toLocaleDateString('fr-FR');
    };
    return (
        <ListItem
            key={trame.moduleId}
            disableGutters
            disablePadding
            sx={{ backgroundColor: baseColors.green.light, borderRadius: '12px', marginBottom: '5px' }}
        >
            <ListItemButton key={trame.moduleId} className="dd-fy">
                <ListItemText
                    inset
                    primary={formatDate(trame.createdAt)}
                    secondary={
                        <>
                            <IconButton>
                                <PdfIcon width="26px" height="26px" />
                            </IconButton>
                            {trame.originalName}
                        </>
                    }
                    style={{ paddingLeft: '0' }}
                />
                <ListItemSecondaryAction>
                    <a
                        href={`${trame.fileUrl}`}
                        style={{ marginRight: '10px', textDecoration: 'none' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                    >
                        <CustomButton onClick={() => {}} width={140} label={'Télécharger'} />
                    </a>

                    <CustomButton
                        onClick={() => {
                            setCurrentPdfResponseId(trame.fileId);
                            setOpen(true);
                        }}
                        width={140}
                        label={'Envoyer'}
                    />
                </ListItemSecondaryAction>
            </ListItemButton>
        </ListItem>
    );
};

export default ListItemPdf;
