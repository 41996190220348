import { baseColors } from 'src/utils/constants/baseStyles';

export class TabPanelViewModel {
    private styles = {
        panelContainer: {
            backgroundColor: baseColors.green.light,
            boxSizing : 'border-box',
        },
        panel: {
            backgroundColor: baseColors.white,
            // backgroundColor: 'red',
            width: 'auto',
            height: '100%',
            paddingTop: '25px',
            boxSizing : 'border-box',
        },
    };

    public getStyles() {
        return this.styles;
    }
}
