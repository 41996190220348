import { Box } from '@mui/material';
import SidebarMenuViewModel from 'src/pages/Home/Sidebar/SidebarMenuViewModel';
import { settingMenuItems } from 'src/utils/constants/constants';
import SettingPanelViewModel from '../SettingViewModel';

const SubmenuSetting = () => {
    const vm = new SettingPanelViewModel();
    const style = vm.getStyles();
    const sideBarVm = new SidebarMenuViewModel();
    const sideBarStyle = sideBarVm.getStyles();
    const subMenuParameters = settingMenuItems;

    const handleSubMenuSetting = (subMenu: any) => {
        return (
            <Box
                sx={{
                    ...sideBarStyle.sidebarMenuItem,
                    minWidth: '155px',
                }}
            >
                <Box sx={sideBarStyle.titleBlock}>
                    <Box sx={sideBarStyle.icon}>{sideBarVm.parseIcon(subMenu.icon)}</Box>
                    <Box
                        sx={{
                            ...sideBarStyle.title,
                        }}
                    >
                        {subMenu.title}
                    </Box>
                </Box>
                <Box sx={vm.handleIndicator(subMenu.selected)}></Box>
            </Box>
        );
    };
    return (
        <Box
            sx={{
                ...style.box,
                width: '15%',
                display: 'flex',
                backgroundColor: 'white',
                flexDirection: 'column',
                marginBottom: '2px',
                minWidth: '155px',
            }}
        >
            {subMenuParameters.map(handleSubMenuSetting)}
        </Box>
    );
};

export { SubmenuSetting };
