import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const httpLink =  createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext((_, { headers }) => {

    const token = localStorage.getItem('token');

    return token?{
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }:{};
});


const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
