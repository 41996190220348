import { CustomButton } from 'src/components/Button/CustomButton';
import ShowVideo from './ShowVideo';
interface FirstVideoPanelProps {
    videoFile?: any;
    videoUrl?: any;
    moduleTitle?: string | undefined;
    moduleId?: any;
    moduleType?: any;
    videoType?: any;
    loadingVideo?: boolean;
    evaluationStatus?: boolean;
    startEvaluationOnBackend: any;
    hasSeenVideo: boolean;
    setHasSeenVideo: any;
    progressionVideo?: any;
}
const FirstVideoPanel = (props: FirstVideoPanelProps) => {
    const {
        videoUrl,
        videoFile,
        moduleTitle,
        loadingVideo,
        evaluationStatus,
        startEvaluationOnBackend,
        moduleId,
        moduleType,
        videoType,
        hasSeenVideo,
        setHasSeenVideo,
        progressionVideo,
    } = props;

    return (
        <>
            <ShowVideo
                videoUrl={videoUrl}
                videoFile={videoFile}
                moduleTitle={moduleTitle}
                moduleId={moduleId}
                moduleType={moduleType}
                videoType={videoType}
                loadingVideo={loadingVideo}
                hasSeenVideo={hasSeenVideo}
                setHasSeenVideo={setHasSeenVideo}
                progressionVideo={progressionVideo}
            ></ShowVideo>
            {!evaluationStatus && (
                <CustomButton
                    label="Passer à l'évaluation"
                    onClick={() => {
                        if (hasSeenVideo) {
                            startEvaluationOnBackend();
                        }
                    }}
                    width="30%"
                    lower={true}
                    fontSize={14}
                    disabled={!hasSeenVideo}
                ></CustomButton>
            )}
        </>
    );
};

export default FirstVideoPanel;
