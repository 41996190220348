import React, { FC, ReactNode, useState } from 'react';

import { AlertColor } from '@mui/material';
import { createContext } from 'react';

type ActionType = {
    closeModal: boolean;
    setCloseModal: (val: boolean) => void;
};

export const ActionContext = createContext<ActionType>({
    closeModal: false,
    setCloseModal: () => {},
});

type IActionTypeProps = {
    children: ReactNode
};

const ActionProvider = (props: IActionTypeProps) => {
    const { children } = props;
    const [closeModal, setCloseModal] = useState<boolean>(false);
    

    return (
        <ActionContext.Provider
            value={{
                closeModal,
                setCloseModal
            }}
        >
            {children}
        </ActionContext.Provider>
    );
};

export default ActionProvider;
