import { baseColors } from 'src/utils/constants/baseStyles';

class ProfileViewModel {
    private styles = {
        blockContainer: {
            backgroundColor: baseColors.white,
            border: '10px solid #E6F4F4',
            borderRadius: '10px',
            padding: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        centeredInfo: {
            width: '100%',
            textAlign: 'center',
        },
        avatar: {
            margin: 'auto',
            width: '120px',
            height: '120px',
        },
        fieldLine: {
            display: 'flex',
        },
        label: {
            fontWeight: 'bold',
            color: baseColors.black,
            fontSize: 10.66,
            marginY: 0.75
        },
        fieldValue: {
            color: baseColors.green.secondary,
            paddingLeft: 1,
            fontSize: 10.66,
            marginY: 0.75
        },
    };

    public getStyles() {
        return this.styles;
    }
}

export default ProfileViewModel;
