import { Box, LinearProgress } from '@mui/material';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { sxStyles } from '../../components/Cards/styles/videoCardListStyles';
import { baseColors } from 'src/utils/constants/baseStyles';
import { StudentData } from 'src/components/Cards/Interface/StudentData';
import { StudentCardList } from 'src/components/Cards/StudentCardList';
import { CustomText } from 'src/components/Text/CustomText';
import { LIST_STUDENTS } from 'src/graphql/queries/queries';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const selectStyle = {
    color:baseColors.green.primary,
    outline:'none',
    borderRadius :"7px",
    margin:'0 10px',
    ':checked ': {
      color: 'red'
    }
};


export interface Promotion {
    id: string,
    name: string,
}


export const StudentListPanel = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();

    const { loading, data, error } = useQuery(LIST_STUDENTS)
    const [dataStudentList, setStudentList] = useState<StudentData[]>([])
    const [dataPromotionList, setPromotionList] = useState<Promotion[]>([])

    useEffect(() => {
        if(data){
            var studentList: StudentData[] = [];
            var promotionList : Promotion[] = [];
            data.listStudent.map((element: any) => {
                const studentData: StudentData = {
                    id: element.student.id,
                    fullName: element.student.firstName + ' ' + element.student.lastName,
                    photo: '',
                    progress: element.moduleInfo[0].progressionVideo
                };

                const promotion : Promotion = element.student.promotion;
                
                if(!promotionList.includes(promotion)){
                    promotionList.push(promotion);
                }
                setPromotionList(promotionList);
                studentList.push(studentData);
                setStudentList(studentList);
                
            })
        }
    }, [data])


    const handleChange = (event: { target: { value: any; }; }) => {
        const id = event.target.value;
        var selectedStudentList : StudentData[] = [];
        if(data){
            data.listStudent.map((element: any) => {
                if(element.student.promotion.id == id){
                    const studentData: StudentData = {
                        id: element.student.id,
                        fullName: element.student.firstName + ' ' + element.student.lastName,
                        photo: '',
                        progress: element.moduleInfo[0].progressionVideo
                    };
                    selectedStudentList.push(studentData);
                }
            })
        }
        setStudentList(selectedStudentList);
    };


    return (
        <Box sx={styles.rootPanel}>
            <Box sx={sxStyles.container}>
                <Box  marginRight={1}>
                    <StudentCardList dataRows={dataStudentList}/>
                </Box>
                <Box flex={9} marginRight={1} style={{display: 'flex', flexDirection: 'column', width: '67vw'}}>
                    <Box  style={{backgroundColor: baseColors.white, padding:'10px 0', borderRadius: 6, width: '100%' , display:'flex'}}>
                        <div style={{display:'flex',padding:'0 10px',flexBasis:'50%'}}>
                            <label htmlFor="cars"> 
                                <CustomText fontFamily={'baseFontFamily.BreeSerif'} style={{fontWeight: 525 }} color={baseColors.black} fontSize={18}>
                                    Promotion:
                                </CustomText>
                            </label>
                            <select onChange={handleChange} style={{background:baseColors.green.light , padding:'0 100px' ,border:'none',...selectStyle}}>
                                <option value="none" defaultValue={0} hidden >Sélectionnez une prommotion</option>
                                {
                                    dataPromotionList.map(promotion=>{
                                        return  <option value={promotion.id}  style={{color:baseColors.green.primary}}>{promotion.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div  style={{flexBasis:'40%'}}>
                            <CustomText fontFamily={'baseFontFamily.OpenSansBold'} fontSize={14} color={baseColors.black} style={{fontWeight: 600}}>
                                Avancement global de la promotion
                            </CustomText>
                            <LinearProgress variant="determinate" value={30} color="warning" style={{ marginRight: 30 }} sx={{ borderRadius: 2, backgroundColor: baseColors.green.light,height:'10px' }} />
                        </div>
                    </Box>
                    <Box style={{backgroundColor: baseColors.white, marginTop: 10, height: '100vh', borderRadius: 6}}>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
