import { gql } from "@apollo/client";

export const DISABLE_EXPLICATIVE_VIDEO = gql`
    mutation DisableExplicativeVideo {
        disableExplicativeVideo {
            message
            status
            code
            isFirstSession
        }
    }
`;
