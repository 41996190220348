interface HeaderIconProps {
    width: string;
    height: string;
}

const headerIconProps = {
    width: '109',
    height: '46',
};

export const HeaderIcon = (props: HeaderIconProps = headerIconProps) => {
    const { width, height } = props;

    return (
        <>
            <svg width={width} height={height} viewBox="0 0 109 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2733_15009)">
                    <path
                        d="M22.4103 36.2649C21.2193 28.9809 18.6847 26.7646 11.3709 26.7646C4.07238 26.7646 1.53775 28.9654 0.331514 36.2649C-0.493003 41.3483 6.08786 44.1069 9.61496 45.2692C10.2257 45.4707 10.8365 44.9748 10.7754 44.3394L10.6074 42.3246C10.5922 42.0612 10.4548 41.8132 10.2257 41.6582C8.10335 40.1394 4.24034 37.4738 4.24034 34.9321C4.24034 32.7314 5.99625 30.9491 8.16443 30.9491C9.17217 30.9336 10.1188 31.3366 10.8365 32.003C11.2029 32.3439 11.7221 32.3439 12.0733 32.003C12.7909 31.3366 13.7528 30.9336 14.7453 30.9491C16.8982 30.9646 18.6694 32.7469 18.6694 34.9321C18.6694 37.5513 15.0354 40.1394 12.8825 41.6582C12.6687 41.8132 12.516 42.0767 12.5008 42.3556L12.3023 44.3549C12.2412 45.0058 12.9894 45.5637 13.6001 45.3622C17.1883 44.1224 23.2348 41.2553 22.4103 36.2649Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M14.4087 14.2426C12.5612 14.94 11.248 16.7378 11.248 18.861C11.248 21.5886 13.4162 23.7893 16.1035 23.7893C18.7909 23.7893 20.959 21.5886 20.959 18.861C20.959 16.1333 18.7909 13.9326 16.1035 13.9326C15.5081 13.9326 14.9431 14.0411 14.4087 14.2426Z"
                        stroke="white"
                        strokeWidth="0.30631"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M16.118 23.1701C18.4847 23.1701 20.3933 21.2328 20.3933 18.8306C20.3933 16.4285 18.4847 14.4912 16.118 14.4912C13.7514 14.4912 11.8428 16.4285 11.8428 18.8306C11.8428 21.2328 13.7666 23.1701 16.118 23.1701Z"
                        stroke="#3EABAB"
                        strokeWidth="1.0989"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M11.3543 8.22764C13.5553 8.22764 15.3395 6.41665 15.3395 4.18267C15.3395 1.94869 13.5553 0.137695 11.3543 0.137695C9.15336 0.137695 7.36914 1.94869 7.36914 4.18267C7.36914 6.41665 9.15336 8.22764 11.3543 8.22764Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M12.3165 6.38536C11.9959 6.35436 11.6905 6.33887 11.3546 6.33887C11.1561 6.33887 10.9576 6.33886 10.7591 6.35436C5.62879 6.66432 1.53674 10.8953 1.35352 16.1336C1.35352 16.2576 1.35352 16.3816 1.35352 16.4901C1.35352 17.8074 1.59782 19.0627 2.04061 20.2096C1.97954 19.7446 1.93373 19.2797 1.93373 18.7993C1.93373 17.8849 2.23911 17.2185 2.48341 16.3816C6.33116 16.3661 9.49181 13.9484 11.1714 10.7093C12.4692 14.8318 16.6987 17.8229 21.1725 17.8384C21.203 18.1638 21.2183 18.5203 21.2183 18.8612C21.2183 19.3417 21.0198 19.9771 20.9434 20.442C21.2335 19.6826 21.1725 18.6598 21.2794 17.8229C21.3404 17.3889 21.3557 16.955 21.3557 16.5055C21.371 11.2207 17.4011 6.8813 12.3165 6.38536Z"
                        fill="#3EABAB"
                        stroke="white"
                        strokeWidth="0.30631"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M8.72812 14.4284C8.08683 14.103 7.35393 13.917 6.57522 13.917C3.8879 13.917 1.71973 16.1177 1.71973 18.8454C1.71973 19.3568 1.79607 19.8372 1.93348 20.3022"
                        stroke="white"
                        strokeWidth="0.30631"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M6.60633 23.1701C8.973 23.1701 10.8816 21.2328 10.8816 18.8306C10.8816 16.4285 8.973 14.4912 6.60633 14.4912C4.23966 14.4912 2.33105 16.4285 2.33105 18.8306C2.33105 21.2328 4.23966 23.1701 6.60633 23.1701Z"
                        fill="white"
                        stroke="#3EABAB"
                        strokeWidth="1.0989"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M16.6974 18.6736C16.7126 18.7821 16.7279 18.9371 16.7126 19.1075C16.7126 19.154 16.7279 19.185 16.7737 19.185C16.8959 19.1695 17.1707 19.092 17.415 18.8131C17.4455 18.7666 17.415 18.7046 17.3692 18.7046C17.2165 18.7201 16.9875 18.7201 16.7584 18.6116C16.7279 18.5806 16.6821 18.6271 16.6974 18.6736Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M16.6665 18.5956C16.4527 18.6886 15.6435 18.9986 14.9869 18.4562C14.9411 18.4252 14.88 18.4717 14.9106 18.5336C15.0633 18.8746 15.5213 19.448 16.7276 19.1846C16.7428 19.1846 16.7581 19.1691 16.7581 19.1536C16.7886 19.0606 16.8955 18.7816 16.7734 18.6266C16.7581 18.5801 16.7123 18.5801 16.6665 18.5956Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M5.39941 18.83C5.39941 18.83 6.30028 17.3887 7.21641 18.83"
                        stroke="#3EABAB"
                        strokeWidth="0.612621"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M37.8467 20.1973C37.8467 19.9029 37.862 19.6859 37.8772 19.5464C37.8925 19.4069 37.9536 19.2365 38.0604 19.0505C38.2284 18.7095 38.717 18.5391 39.511 18.5391C40.3813 18.5391 40.9004 18.7715 41.0531 19.2365C41.1142 19.4534 41.1447 19.7789 41.1447 20.2129V30.736C41.1447 31.0459 41.1295 31.2629 41.1142 31.4024C41.0989 31.5419 41.0379 31.7124 40.931 31.8983C40.763 32.2393 40.2744 32.4098 39.4804 32.4098C38.6101 32.4098 38.1063 32.1773 37.9536 31.6969C37.8772 31.4954 37.8467 31.1699 37.8467 30.736V20.1973Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M55.5733 18.7413C56.3062 19.1133 56.6727 19.5162 56.6727 19.9502C56.6727 20.1517 56.5811 20.4616 56.3978 20.8646L51.2522 31.4497C51.1148 31.7287 50.9011 31.9456 50.6262 32.1161C50.3514 32.2866 50.0613 32.3641 49.7559 32.3641H49.5727C49.2673 32.3641 48.9619 32.2866 48.6871 32.1161C48.4122 31.9456 48.1985 31.7287 48.0611 31.4497L42.9155 20.8646C42.7322 20.4616 42.6406 20.1517 42.6406 19.9502C42.6406 19.5162 43.0071 19.0978 43.74 18.7413C44.1522 18.5244 44.4576 18.4159 44.6867 18.4159C44.9004 18.4159 45.0684 18.4469 45.1753 18.4934C45.2821 18.5399 45.389 18.6329 45.4959 18.7568C45.6028 18.9118 45.7097 19.0823 45.8013 19.2528C45.8929 19.4388 46.1372 19.9657 46.5189 20.8181C46.9006 21.686 47.3129 22.6003 47.7557 23.5767C48.1985 24.5531 48.6107 25.4675 49.0077 26.3354C49.4047 27.2033 49.6185 27.6837 49.6643 27.7767L53.3899 19.4853C53.4815 19.2528 53.5731 19.0513 53.6495 18.9118C53.8785 18.5709 54.1533 18.4004 54.474 18.4004C54.7946 18.4159 55.1611 18.5244 55.5733 18.7413Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M61.4527 29.0456H67.1938C67.4839 29.0456 67.6977 29.0611 67.8351 29.0766C67.9725 29.0921 68.1405 29.1541 68.3237 29.2626C68.6596 29.433 68.8276 29.929 68.8276 30.7349C68.8276 31.6183 68.5985 32.1297 68.1405 32.2847C67.9267 32.3622 67.6061 32.3932 67.1785 32.3932H59.8037C58.9334 32.3932 58.4295 32.1607 58.2768 31.6803C58.2005 31.4788 58.1699 31.1533 58.1699 30.7194V20.1962C58.1699 19.5453 58.2921 19.1114 58.5211 18.8789C58.7501 18.6464 59.2082 18.5225 59.8648 18.5225H67.2091C67.4992 18.5225 67.7129 18.538 67.8504 18.5535C67.9878 18.569 68.1557 18.631 68.339 18.7394C68.6749 18.9099 68.8428 19.4059 68.8428 20.2117C68.8428 21.0951 68.6138 21.6066 68.1557 21.7615C67.942 21.839 67.6213 21.87 67.1938 21.87H61.468V23.7918H65.163C65.4532 23.7918 65.6669 23.8073 65.8043 23.8228C65.9418 23.8383 66.1097 23.9003 66.2929 24.0088C66.6289 24.1792 66.7968 24.6752 66.7968 25.4811C66.7968 26.3644 66.5678 26.8759 66.0944 27.0309C65.8807 27.1084 65.56 27.1393 65.1325 27.1393H61.4833V29.0456H61.4527Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M78.9655 19.0339C79.0724 18.8479 79.2251 18.7084 79.4694 18.6309C79.6984 18.5535 80.0038 18.5225 80.3702 18.5225C80.7367 18.5225 81.0115 18.5535 81.2253 18.6154C81.4391 18.6774 81.5917 18.7549 81.7139 18.8479C81.8208 18.9409 81.9124 19.0649 81.9735 19.2199C82.0345 19.4368 82.0651 19.7623 82.0651 20.1962V30.6884C82.0651 30.9829 82.0498 31.1998 82.0345 31.3393C82.0193 31.4788 81.9582 31.6493 81.8513 31.8352C81.6834 32.1762 81.1948 32.3467 80.4008 32.3467C79.5304 32.3467 79.0266 32.1142 78.8739 31.6493C78.7975 31.4323 78.767 31.1068 78.767 30.6729V26.8294H73.8352V30.6884C73.8352 30.9829 73.8199 31.1998 73.8046 31.3393C73.7894 31.4788 73.7283 31.6493 73.6214 31.8352C73.4535 32.1762 72.9649 32.3467 72.1709 32.3467C71.3006 32.3467 70.7967 32.1142 70.644 31.6493C70.5676 31.4323 70.5371 31.1068 70.5371 30.6729V20.1807C70.5371 19.8863 70.5524 19.6693 70.5676 19.5298C70.5829 19.3903 70.644 19.2199 70.7509 19.0339C70.9188 18.6929 71.4074 18.5225 72.2014 18.5225C73.0717 18.5225 73.5909 18.7549 73.7436 19.2199C73.8046 19.4368 73.8352 19.7623 73.8352 20.1962V24.0552H78.767V20.1807C78.767 19.8863 78.7823 19.6693 78.7975 19.5298C78.7975 19.3903 78.8586 19.2354 78.9655 19.0339Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M84.9052 18.5068H95.2727C95.6697 18.5068 95.9598 18.5533 96.1431 18.6463C96.3263 18.7393 96.4484 18.8943 96.5095 19.0958C96.5706 19.2972 96.6011 19.5607 96.6011 19.8862C96.6011 20.2116 96.5706 20.4751 96.5095 20.6766C96.4484 20.878 96.3415 21.002 96.1889 21.0795C95.9598 21.188 95.6544 21.25 95.2422 21.25H91.6846V30.7657C91.6846 31.0602 91.6693 31.2772 91.654 31.4012C91.6387 31.5406 91.5777 31.6956 91.4861 31.8816C91.3944 32.0676 91.2265 32.1916 90.9975 32.2691C90.7684 32.3465 90.4478 32.3775 90.0661 32.3775C89.6843 32.3775 89.379 32.3465 89.1499 32.2691C88.9209 32.1916 88.7529 32.0676 88.6613 31.8816C88.5697 31.6956 88.5086 31.5406 88.4934 31.3857C88.4781 31.2462 88.4628 31.0292 88.4628 30.7347V21.2345H84.8899C84.4929 21.2345 84.2028 21.188 84.0196 21.095C83.8364 21.002 83.7142 20.847 83.6531 20.6456C83.5921 20.4441 83.5615 20.1806 83.5615 19.8552C83.5615 19.5297 83.5921 19.2662 83.6531 19.0648C83.7142 18.8633 83.8211 18.7393 83.9738 18.6618C84.1723 18.5688 84.4929 18.5068 84.9052 18.5068Z"
                        fill="#3EABAB"
                    />
                    <path
                        d="M101.396 29.0456H107.137C107.427 29.0456 107.641 29.0611 107.778 29.0766C107.916 29.0921 108.084 29.1541 108.267 29.2626C108.603 29.433 108.771 29.929 108.771 30.7349C108.771 31.6183 108.542 32.1297 108.084 32.2847C107.87 32.3622 107.549 32.3932 107.122 32.3932H99.747C98.8767 32.3932 98.3729 32.1607 98.2202 31.6803C98.1438 31.4788 98.1133 31.1533 98.1133 30.7194V20.1962C98.1133 19.5453 98.2354 19.1114 98.4645 18.8789C98.6935 18.6464 99.1516 18.5225 99.8081 18.5225H107.152C107.443 18.5225 107.656 18.538 107.794 18.5535C107.931 18.569 108.099 18.631 108.282 18.7394C108.618 18.9099 108.786 19.4059 108.786 20.2117C108.786 21.0951 108.557 21.6066 108.099 21.7615C107.885 21.839 107.565 21.87 107.137 21.87H101.411V23.7918H105.106C105.397 23.7918 105.61 23.8073 105.748 23.8228C105.885 23.8383 106.053 23.9003 106.236 24.0088C106.572 24.1792 106.74 24.6752 106.74 25.4811C106.74 26.3644 106.511 26.8759 106.038 27.0309C105.824 27.1084 105.503 27.1393 105.076 27.1393H101.427V29.0456H101.396Z"
                        fill="#3EABAB"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2733_15009">
                        <rect width="109" height="46" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
