import { FC } from "react"
import ProgressionBarChart from "src/components/shared/GlobalAvancementBarChart/ProgressionBarChart/ProgressionBarChart";
import { SummaryProgression } from "src/components/shared/SummaryProgression";

type GlobalAvancementBarChartProps = {
    data: SummaryProgression[],
}
const GlobalAvancementBarChart: FC<GlobalAvancementBarChartProps> = ({ data }) => {
    const initialValue = [{
        value: 0,
        total: 0,
        label: "Vidéos visionnées",
        legend: "Bilan éducatif"
    }, {
        value: 0,
        total: 0,
        label: "Formulaires remplis",
        legend: "Bilan éducatif"
    }, {
        value: 0,
        total: 0,
        label: "Vidéos visionnées",
        legend: "Atelier éducatif"
    }, {
        value: 0,
        total: 0,
        label: "Formulaires remplis",
        legend: "Atelier éducatif"
    }];

    const dataToPlot = data.reduce((acc, current) => {
        acc[0] = {
            ...acc[0],
            value: current.educativeBilan.seenVideos + acc[0].value,
            total: current.educativeBilan.totalVideos + acc[0].total,
        };
        acc[1] = {
            ...acc[1],
            value: current.educativeBilan.filledForm + acc[1].value,
            total: current.educativeBilan.totalForm + acc[1].total,
        };
        acc[2] = {
            ...acc[2],
            value: current.educativeWorkshop.seenVideos + acc[2].value,
            total: current.educativeWorkshop.totalVideos + acc[2].total,
        };
        acc[3] = {
            ...acc[3],
            value: current.educativeWorkshop.filledForm + acc[3].value,
            total: current.educativeWorkshop.totalForm + acc[3].total,
        };
        return acc;
    }, [...initialValue]);

    return <ProgressionBarChart data={dataToPlot} />
}

export { GlobalAvancementBarChart }