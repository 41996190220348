import { Box, Typography } from '@mui/material';
import VideoPreviewViewModel from './VideoPreviewViewModel';

interface VideoPreviewProps {
    width: string;
    height: string;
    alt: string;
}

const videoPreviewProps = {
    width: '50',
    height: '50',
    alt: '',
};
export const VideoPreview = (props: VideoPreviewProps = videoPreviewProps) => {
    const viewModel = new VideoPreviewViewModel();
    const styles = viewModel.getStyles();
    const { width, height, alt } = props;

    return (
        <Box sx={styles.video} width={width} height={height}>
            <span>
                <b>{alt}</b>
            </span>
        </Box>
    );
};
