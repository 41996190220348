import { MenuIconName, menuItems } from 'src/utils/constants/constants';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { LIST_AUTHORIZED_FEATURE_CODES } from 'src/graphql/queries/authorizations';
import SidebarMenuHelper from './SidebarMenuHelper';
import SidebarWrapperViewModel from './SidebarWrapperViewModel';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useSidebarContext } from 'src/providers/SidebarProvider';

interface SidebarWrapperProps {}

const sidebarWrapperProps: SidebarWrapperProps = {};

export const SidebarWrapper = (props: SidebarWrapperProps = sidebarWrapperProps) => {
    const viewModel = new SidebarWrapperViewModel();
    const styles = viewModel.getStyles();
    const helper = new SidebarMenuHelper();

    const { codes } = useAuthorizationContext();

    const [selectedMenuId, setSelectedMenuId] = useState<number>(viewModel.getSelectedIndicator(menuItems, codes).id);

    const { selectedPanel, setSelectedPanel } = useSidebarContext();

    useEffect(() => {
        const newMenuItem = viewModel.getSelectedIndicator(menuItems, codes);

        if (newMenuItem) {
            setSelectedMenuId(newMenuItem.id);
            setSelectedPanel(newMenuItem.icon);
        }
    }, [codes]);

    const navigate = useNavigate();

    const menuItemOnClick = () => {
        navigate('/');
    };

    return (
        <Box
            sx={{
                ...styles.wrapper,
                '@media screen and (max-width: 850px)': {
                    width: '55px',
                    height: '100vh',
                },
            }}
        >
            {helper.displaySidebarMenuItems({
                selectedMenuId,
                setSelectedMenuId: setSelectedMenuId,
                selectedPanel: selectedPanel,
                setSelectedPanel: setSelectedPanel,
                codes,
                menuItemOnClick,
            })}
        </Box>
    );
};
