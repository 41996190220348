import React from 'react';

interface DeleteIconButtonProps {
    size?: string;
}

const DeleteIconButton: React.FC<DeleteIconButtonProps> = ({ size }) => {
    return (
        <svg
            width={size || '40'}
            height={size || '40'}
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="0.228516" width="40" height="40" rx="10" fill="#EA6808" />
            <path
                d="M13.0625 15.8535C13.0625 15.5083 13.3215 15.2285 13.6411 15.2285L15.6393 15.2282C16.0363 15.2173 16.3865 14.9446 16.5216 14.5412C16.5252 14.5306 16.5292 14.5175 16.5439 14.47L16.63 14.191C16.6827 14.0199 16.7286 13.8708 16.7928 13.7375C17.0466 13.2111 17.5161 12.8456 18.0586 12.752C18.1959 12.7283 18.3414 12.7284 18.5083 12.7285H21.1168C21.2838 12.7284 21.4292 12.7283 21.5665 12.752C22.1091 12.8456 22.5786 13.2111 22.8323 13.7375C22.8966 13.8708 22.9425 14.0199 22.9951 14.191L23.0812 14.47C23.0959 14.5175 23.1 14.5306 23.1035 14.5412C23.2386 14.9446 23.6583 15.2177 24.0554 15.2285H25.9839C26.3035 15.2285 26.5625 15.5083 26.5625 15.8535C26.5625 16.1987 26.3035 16.4785 25.9839 16.4785H13.6411C13.3215 16.4785 13.0625 16.1987 13.0625 15.8535Z"
                fill="white"
            />
            <path
                d="M19.7051 27.7284H20.2953C22.3259 27.7284 23.3412 27.7284 24.0013 27.081C24.6615 26.4336 24.729 25.3716 24.8641 23.2477L25.0587 20.1873C25.132 19.0349 25.1687 18.4587 24.8375 18.0935C24.5063 17.7284 23.9471 17.7284 22.8286 17.7284H17.1718C16.0533 17.7284 15.494 17.7284 15.1629 18.0935C14.8317 18.4587 14.8683 19.0349 14.9416 20.1873L15.1363 23.2477C15.2713 25.3716 15.3389 26.4336 15.999 27.081C16.6591 27.7284 17.6745 27.7284 19.7051 27.7284Z"
                fill="white"
            />
        </svg>
    );
};

export default DeleteIconButton;
