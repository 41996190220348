import { VideoCardItem } from './VideoCardItem';
import useStyles from './styles/videoCardListStyles';
import { Box } from '@mui/material';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors } from 'src/utils/constants/baseStyles';
import { useState } from 'react';
import CustomCircleLoader from '../Loader/CustomCircleLoader';
import { VideoData } from './Interface/VideoData';
import { VideoCardListProps } from './Interface/VideoCardListProps';
import { CustomButton } from '../Button/CustomButton';
import { Category } from '@mui/icons-material';
import { VideoContent } from 'src/pages/Home/Content/Module/VideoContent';

export const VideoCardList = (
    props: VideoCardListProps = { dataRows: [], loading: false, moduleId: '', readOnly: false, setReloadVideo: false },
) => {
    const dataRows = props.dataRows;
    const loading = props.loading;
    const moduleId = props.moduleId;
    const readOnly = props.readOnly;
    const setReloadVideo = props.setReloadVideo;
    const classes = useStyles();

    return (
        <Box className={classes.videoContainer}>
            <div className={classes.patientListContainer}>
                <>
                    {readOnly ? (
                        <Box style={{
                            width : '100%',
                            height: '100%',
                            display : 'flex',
                            justifyContent : 'space-around'
                        }}>
                            {
                                dataRows.map((video: any) => (<div style={{
                                    display : 'flex',
                                    justifyContent : 'center',
                                    // alignItems : 'center'
                                }}>
                                    <VideoContent width='100%' height='250px' src={video.fileUrl} />
                                </div>))
                            }
                        </Box>
                    ) : (
                        <>
                            {loading ? (
                                <CustomCircleLoader width={60} />
                            ) : (
                                dataRows.map((video: any) => (
                                    <div key={video.id}>
                                        <VideoCardItem
                                            moduleId={moduleId}
                                            fileName={video.fileName}
                                            originalName={video.originalName}
                                            category={video.category}
                                            description={video.description}
                                            id={video.id}
                                            title={video.title}
                                            photo={video.photo}
                                            readOnly={readOnly}
                                            setReloadVideo={setReloadVideo}
                                            fileUrl={video.fileUrl}
                                        />
                                    </div>
                                ))
                            )}
                        </>
                    )}
                </>
            </div>
        </Box>
    );
};
