import { gql } from '@apollo/client';

const ADD_USER_CONSENT_MUTATION = gql`
    mutation AddUserConsent($cgu: Int, $major: Int, $services: Int, $statistics: Int, $communication: Int) {
        saveUserConsent(
            userConsentData: {
                cgu: $cgu
                major: $major
                services: $services
                statistics: $statistics
                communication: $communication
            }
        ) {
            id
            message
            cgu
            major
            services
            statistics
            communication
        }
    }
`;

export { ADD_USER_CONSENT_MUTATION };
