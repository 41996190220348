export const isValidMail = (email: string | undefined) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

export const isSamePassword = (password: string, confirmPassword: string) => {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
    return regex.test(password) && (password?.trim() === confirmPassword?.trim());
}

export const isValidPassword = (password:string) => {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
    return regex.test(password)
}

export const isValideName = (name: string) => {
    const regex = new RegExp("^(?=.*[A-Za-z0-9]).{3,50}$")
    return regex.test(name)
}

export const isNumber = (str: string) => {
    const reg = new RegExp('^[0-9]+$');
    return str.match(reg);
};

export const isPhoneNumber = (str: string | undefined) => {
    const reg = new RegExp('^[0-9]+$');
    return str?.match(reg) && str?.trim().length === 10;
};

export const isNotBlank = (str: string | undefined) => str && str?.trim().length > 1;

