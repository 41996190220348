import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ErrorText } from 'src/components/Error/ErrorText';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { CustomText } from 'src/components/Text/CustomText';
import { UPDATE_PROGRESSION_VIDEO_OF_USER } from 'src/graphql/mutations/module.mutations';
import Video from './Video';
import { Box, Typography } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface VideoProp {
    videoUrl: any | undefined;
    videoFile: any;
    moduleTitle: string | undefined;
    moduleId: any;
    moduleType: any;
    loadingVideo?: boolean;
    videoType: any;
    hasSeenVideo: boolean;
    setHasSeenVideo?: any;
    setHasSeenVideoAnnote?: any;
    progressionVideo?: any;
}

const ShowVideo = (props: VideoProp) => {
    const {
        videoUrl,
        videoFile,
        moduleTitle,
        loadingVideo,
        moduleId,
        moduleType,
        videoType,
        hasSeenVideo,
        setHasSeenVideo,
        setHasSeenVideoAnnote,
        progressionVideo,
    } = props;
    const [progression, setProgression] = useState<number>(0);
    const [i, setI] = useState<number>(0);
    const [updateProgressionVideoOfUserMutation, mutationData] = useMutation(UPDATE_PROGRESSION_VIDEO_OF_USER);

    useEffect(() => {
        setI(Math.floor(progression));
    }, [progression]);

    useEffect(() => {
        let progressionPercent = Math.floor(progression);
        //update progression backend
        if (!hasSeenVideo) {
            updateProgressionVideoOfUserMutation({
                variables: {
                    input: {
                        moduleId: moduleId,
                        videoType: videoType,
                        progression: progressionPercent,
                    },
                },
                onCompleted: (data) => {
                    setHasSeenVideo?.(data.updateProgressionVideoOfUser.hasSeenVideo);
                    setHasSeenVideoAnnote?.(data.updateProgressionVideoOfUser.hasSeenVideoAnnote);
                },
            });
        }
    }, [i]);

    return (
        <>
            {loadingVideo || !videoUrl ? (
                <CustomCircleLoader width={100} />
            ) : !loadingVideo ? (
                <>
                    <Box>
                        <Typography
                            fontFamily={baseFontFamily.BreeSerif}
                            fontWeight={400}
                            color={baseColors.green.cancel}
                            fontSize={18}
                        >
                            {moduleTitle}
                        </Typography>
                    </Box>
                    <Box mt='25px'>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansBold}
                            color={baseColors.green.cancel}
                            fontSize={14}
                        >
                            Titre de la vidéo : 
                        </Typography>
                    </Box>
                    <Box mt='25px' mb='25px'>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            fontWeight={400}
                            color={baseColors.green.cancel}
                            fontSize={14}
                        >
                            {videoFile?.title} 
                        </Typography>
                    </Box>
                    <Box mb='5px'>
                    {videoUrl && (
                        <Video
                            onProgress={(value: any) => {
                                setProgression(value);
                            }}
                            videoFile={videoFile}
                            videoUrl={videoUrl}
                            progressionVideo={progressionVideo}
                        ></Video>
                    )}</Box>
                </>
            ) : (
                <ErrorText>Ce module n'a pas de vidéo disponible</ErrorText>
            )}
        </>
    );
};

export default ShowVideo;
