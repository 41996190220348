import { CSSProperties, FC } from 'react';
import { Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import { specialCharToUnderscore } from 'src/utils/utils';

interface CustomLoadingButtonProps {
    label: string;
    width?: number;
    handleClick: Function;
    size?: any;
    loading?: boolean;
    disabled?: boolean;
    outlined?: boolean;
    marginRight?: number;
    marginLeft?: number;
    isDecline?: boolean;
    visible?: any;
    style?: CSSProperties;
}

const CustomLoadingButton: FC<CustomLoadingButtonProps> = (props) => {
    const {
        label,
        width,
        handleClick,
        loading,
        disabled,
        outlined,
        marginRight,
        marginLeft,
        isDecline,
        visible,
        style,
    } = props;

    return (
        <LoadingButton
            loading={loading}
            loadingPosition="center"
            disabled={disabled}
            onClick={() => handleClick()}
            style={{
                visibility: visible,
                backgroundColor: outlined
                    ? 'transparent'
                    : isDecline
                    ? baseColors.green.light
                    : baseColors.green.primary,
                width: width ?? 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: marginRight ?? undefined,
                marginLeft: marginLeft ?? undefined,
                border: 'none',
                boxShadow: 'none',
                ...style,
            }}
            size={'large'}
            variant={outlined ? 'outlined' : 'contained'}
            id={specialCharToUnderscore(label ?? '')}
        >
            <Typography
                style={{
                    color: isDecline
                        ? baseColors.green.lightPastel
                        : outlined
                        ? baseColors.green.primary
                        : loading
                        ? 'transparent'
                        : baseColors.white,
                    fontSize: 12,
                    textTransform: 'capitalize',
                    fontWeight: baseFontWeight.semiBold,
                    fontFamily: baseFontFamily.OpenSansRegular,
                    opacity: disabled ? 0.5 : 1,
                }}
            >
                {label}
            </Typography>
        </LoadingButton>
    );
};

export default CustomLoadingButton;
