import WrapperComponent from "./WrapperComponent";

const Wrapper = (WrappedComponent: any) => {
    return (
        <WrapperComponent>
            <WrappedComponent />
        </WrapperComponent>
    )
};

export default Wrapper;