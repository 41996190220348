class LogoutHandler {
    public logout(action: any, token: any) {
        const mutate = action.logout({ variables: { token: token } });
        mutate
            .then((result: any) => {
                localStorage.clear();
                sessionStorage.clear();
                action.logoutFront();
                action.cb();
                window.location.reload();
            })
            .catch((error: any) => {});
    }
}

export default LogoutHandler;
