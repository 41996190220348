import { gql } from "@apollo/client";

const LIST_AUTHORIZED_FEATURE_CODES = gql`
    query {
        listAuthorizedFeatureCodes {
            items
        }
    }
`;

export {
        LIST_AUTHORIZED_FEATURE_CODES
};