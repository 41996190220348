import * as React from 'react';
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { createRef, useEffect, useState } from 'react';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { styled } from '@mui/material/styles';
import AttachFileModal from './AttachFileModal';
import { LIST_TOOL_BOXS } from 'src/graphql/queries/toolbox.queries';
import { useQuery } from '@apollo/client';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import { CustomButton } from 'src/components/Button/CustomButton';
import { PdfIcon } from 'src/components/Icon/PdfIcon';
import { DownloadActionButton } from './DownloadActionButton';
import { EditActionButton } from './EditActionButton';
import { DeleteActionButton } from './DeleteActionButton';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import EditToolBoxModal from './EditToolBoxModal';
import DeleteToolBoxModal from './DeleteToolBoxModal';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { AuthorizationCode } from 'src/utils/constants/constants';

export const ToolBoxPanel = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const [toolboxList, setToolboxList] = useState<any[]>([]);
    const styles = viewModel.getStyles();
    const [modalDocVisible, setModalDocVisible] = useState(false);
    const toggleModal = () => setModalDocVisible(!modalDocVisible);
    const [modalDocVisible2, setModalDocVisible2] = useState(false);
    const toggleModal2 = () => setModalDocVisible2(!modalDocVisible2);

    const [modalDocVisible3, setModalDocVisible3] = useState(false);
    const toggleModal3 = () => setModalDocVisible3(!modalDocVisible3);

    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [originalName, setOriginalName] = useState<string>('');

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const { codes } = useAuthorizationContext();

    const { loading, data: dataToolBoxListQuery, error, refetch } = useQuery(LIST_TOOL_BOXS);

    const isAuthorizedToSeeAddToolBox = codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_CREATION);

    const isAuthorizedToSeeUpdateToolBox = codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_EDITION);

    const isAuthorizedToSeeDeleteToolBox = codes?.includes(AuthorizationCode.FRONT_DISPLAY_TOOL_BOX_ITEM_DELETION);

    useEffect(() => {
        if (dataToolBoxListQuery?.listToolBox) {
            let data = dataToolBoxListQuery.listToolBox;
            setToolboxList((oldstate: any[]) => {
                return data.map((toolbox: any) => {
                    return {
                        id: toolbox.id,
                        title: toolbox.title,   
                        description: toolbox.description,
                        fileName: toolbox.title,
                        originalName: toolbox.originalName,
                        category: toolbox.category,
                        filePath: toolbox.filePath,
                        fileUrl: toolbox.fileUrl,
                        mimeType: toolbox.mimeType,
                    };
                });
            });
        }
    }, [dataToolBoxListQuery]);
    return (
        <Box sx={styles.toolBoxPanel}>
            <Grid container spacing={1} sx={{width:'100%'}}>
                <Grid style={{ height: '100%', padding: '0 20px', width: '100%' }}>
                    <div style={{ padding: '25px 25px 25px 25px', flexBasis: '50%' }}>
                        <label htmlFor="cars" style={{ lineHeight: '2.5' }}>
                            <Typography
                                fontFamily={baseFontFamily.BreeSerif}
                                fontWeight={400}
                                color={baseColors.green.cancel}
                                fontSize={16}
                            >
                                Outils
                            </Typography>
                        </label>
                        {isAuthorizedToSeeAddToolBox && (
                            <span style={{ float: 'right' }}>
                                <CustomButton
                                    onClick={() => {
                                        setModalDocVisible(true);
                                    }}
                                    width={'auto'}
                                    label={'Ajouter un outil'}
                                    fontSize={14}
                                    lower={true}
                                />
                            </span>
                        )}
                    </div>
                    {loading ? (
                        <CustomCircleLoader></CustomCircleLoader>
                    ) : (
                        <TableContainer
                            component={Paper}
                            style={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)', width: '100%' }}
                        >
                            <Table sx={{ minWidth: 650 }} aria-label="role toolbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.green.cancel}
                                                fontSize={11}
                                            >
                                                Nom
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.green.cancel}
                                                fontSize={11}
                                            >
                                                Description
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.green.cancel}
                                                fontSize={11}
                                            >
                                                Fichier
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.green.cancel}
                                                fontSize={11}
                                            >
                                                Action
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {toolboxList.map((toolbox: any) => {
                                        return (
                                            <TableRow
                                                key={toolbox.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" align="center" scope="row">
                                                    <Typography
                                                        fontFamily={baseFontFamily.OpenSansRegular}
                                                        color={baseColors.green.cancel}
                                                        fontSize={11}
                                                    >
                                                        {toolbox.title}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" align="center" scope="row">
                                                    <Typography
                                                        fontFamily={baseFontFamily.OpenSansRegular}
                                                        color={baseColors.green.cancel}
                                                        fontSize={11}
                                                    >
                                                        {toolbox.description}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" align="center" scope="row">
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <PdfIcon width="26px" height="26px" />
                                                        &nbsp;{' '}
                                                        <Typography
                                                            fontFamily={baseFontFamily.OpenSansRegular}
                                                            color={baseColors.green.cancel}
                                                            fontSize={11}
                                                        >
                                                            {toolbox.originalName}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell component="th" align="center" scope="row">
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="right"
                                                        color="red"
                                                    >
                                                        <a
                                                            href={`${toolbox.fileUrl}`}
                                                            download
                                                            style={{ marginRight: '10px', textDecoration: 'none' }}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <DownloadActionButton onClick={() => {}} />
                                                        </a>
                                                        {isAuthorizedToSeeUpdateToolBox && (
                                                            <EditActionButton
                                                                onClick={() => {
                                                                    setId(toolbox.id);
                                                                    setTitle(toolbox.fileName);
                                                                    setDescription(toolbox.description);
                                                                    setOriginalName(toolbox.originalName);
                                                                    setModalDocVisible2(true);
                                                                }}
                                                            />
                                                        )}
                                                        {isAuthorizedToSeeDeleteToolBox && (
                                                            <DeleteActionButton
                                                                onClick={() => {
                                                                    setModalDocVisible3(true);
                                                                    setId(toolbox.id);
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <AttachFileModal
                                        refetch={refetch}
                                        modalDocVisible={modalDocVisible}
                                        toggleModal={toggleModal}
                                    />
                                    <EditToolBoxModal
                                        refetch={refetch}
                                        modalDocVisible={modalDocVisible2}
                                        toggleModal={toggleModal2}
                                        id={id}
                                        title={title}
                                        description={description}
                                        originalName={originalName}
                                    />
                                    <DeleteToolBoxModal
                                        refetch={refetch}
                                        modalDocVisible={modalDocVisible3}
                                        toggleModal={toggleModal3}
                                        id={id}
                                    />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
