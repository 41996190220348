import './App.css';
import { ApolloProvider } from '@apollo/client';
import client from './apollo/clients';
import { Provider } from './providers';
import { RouterProvider } from 'react-router-dom';
import routers from './routes/routes';

function App() {
    return (
        <ApolloProvider client={client}>
            <Provider>
                <RouterProvider router={routers} />
            </Provider>
        </ApolloProvider>
    );
}

export default App;
