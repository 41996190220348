import { baseColors } from "src/utils/constants/baseStyles";

class TabViewModel {
    private style = {
        table: {
            width: '100%',
            borderCollapse: 'collapse' as 'collapse',
        },
        tableHeader: {
            borderBottom: '1px solid #ccc',
        },
        tableRow: {
            borderBottom: '1px solid #ccc',
            '&last-child': {
                borderBottom: '1px solid #f00',
            },
        },
        tableCell: {
            textAlign: 'left' as 'left',
            padding: '20px 10px',
        },
        tableHeaderCell: {
            color: baseColors.green.primary,
        },
    };

    public getStyle = () => {
        return this.style;
    };
}

export default TabViewModel;