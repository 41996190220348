import { baseColors } from 'src/utils/constants/baseStyles';

interface selectedDashboardIconProps {
    width?: string;
    height?: string;
    color?: string;
}

export const SelectedDashboardIcon: React.FC<selectedDashboardIconProps> = ({ width, height, color }) => {
    return (
        <svg
            width={width || '50'}
            height={height || '50'}
            viewBox="-5 -5 55 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_801_20303)">
                <rect
                    width="40"
                    height="40"
                    rx="6"
                    fill={color || baseColors.green.light}
                    stroke="white"
                    stroke-width="1.75"
                    stroke-linecap="round"
                />
                <path
                    d="M25.1426 8H29.0625C30.6843 8 32 9.32708 32 10.9642V14.9169C32 16.5528 30.6843 17.8812 29.0625 17.8812H25.1426C23.5196 17.8812 22.2039 16.5528 22.2039 14.9169V10.9642C22.2039 9.32708 23.5196 8 25.1426 8ZM10.9387 8H14.8574C16.4804 8 17.7961 9.32708 17.7961 10.9642V14.9169C17.7961 16.5528 16.4804 17.8812 14.8574 17.8812H10.9387C9.31566 17.8812 8 16.5528 8 14.9169V10.9642C8 9.32708 9.31566 8 10.9387 8ZM10.9387 22.1188H14.8574C16.4804 22.1188 17.7961 23.4459 17.7961 25.0843V29.0358C17.7961 30.6729 16.4804 32 14.8574 32H10.9387C9.31566 32 8 30.6729 8 29.0358V25.0843C8 23.4459 9.31566 22.1188 10.9387 22.1188ZM25.1426 22.1188H29.0625C30.6843 22.1188 32 23.4459 32 25.0843V29.0358C32 30.6729 30.6843 32 29.0625 32H25.1426C23.5196 32 22.2039 30.6729 22.2039 29.0358V25.0843C22.2039 23.4459 23.5196 22.1188 25.1426 22.1188Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_801_20303">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
