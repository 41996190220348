import Home from 'src/pages/Home/Home';
import Login from 'src/pages/Login/Login';
import NotifSeeInbox from 'src/pages/Login/NotifSeeInbox';
import PasswordForgot from 'src/pages/Login/PasswordForgot';
import { PasswordReset } from 'src/pages/Login/PasswordReset';
import { Profile } from 'src/pages/Profile/Profile';
import UserConsent from 'src/pages/UserConsent/UserConsent';
import Wrapper from 'src/components/Wrapper/Wrapper';
import { createBrowserRouter } from 'react-router-dom';
import { ActivateUser } from 'src/pages/Login/ActivateUser';

export const ROUTES = {
    home: '/',
    login: '/login',
    passwordForgot: '/passwordForgot',
    notFound: '/404',
};

const routers = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: 'login',
        element: <Login />,
    },
    {
        path: 'dashboard',
        element: <Home />,
    },
    {
        path: 'profile',
        element: Wrapper(Profile),
    },
    {
        path: 'user-consent',
        element: Wrapper(UserConsent),
    },
    {
        path: 'passwordForgot',
        element: <PasswordForgot />,
    },
    {
        path: 'notifSeeInbox',
        element: <NotifSeeInbox />,
    },
    {
        path: 'password-reset',
        element: <PasswordReset />,
    },
    {
        path: 'activate-user',
        element: <ActivateUser />,
    },
]);

export default routers;
