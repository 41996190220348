import React, { FC, useEffect, useState } from 'react';
// import { Prompt } from 'react-router-dom';
import { Box } from '@mui/material';
import CustomLoadingButton from '../Button/CustomLoadingButton';
import CustomModal from '../CustomModal/CustomModal';
import { Spacer } from '../Spacer/Spacer';
import { CustomText } from '../Text/CustomText';
import { Typography } from '@mui/material';
import { baseFontFamily } from 'src/utils/constants/baseStyles';

interface CustomConfirmProps {
    message: string;
    callBackconfirm: any;
    callbackCancel?: any;
    yesLabel?: string;
    noLabel?: string;
    modalVisible: boolean;
    updateModalVisible?: Function;
    defaultLoading?: boolean;
    modalTitle?: string;
    disableYesButton?: boolean;
    disableNoButton?: boolean;
    disableBackdropClick?: boolean;
}
export const CustomConfirm: FC<CustomConfirmProps> = (props) => {
    const {
        callbackCancel,
        callBackconfirm,
        message,
        yesLabel,
        noLabel,
        modalVisible,
        updateModalVisible,
        defaultLoading,
        modalTitle,
        disableYesButton,
        disableNoButton,
        disableBackdropClick,
    } = props;

    const [loading, setLoading] = useState(false);

    const messages = message.split('\n');

    const closeModal = () => {
        updateModalVisible?.(false);
        setLoading(false);
    };

    const handleConfirmNavigationClick = () => {
        setLoading(true);
        try {
            callBackconfirm?.();
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(defaultLoading ?? false);
    }, [defaultLoading]);

    return (
        <>
            <CustomModal
                disableBackdropClick={disableBackdropClick}
                open={modalVisible}
                toggleModal={updateModalVisible}
                title={modalTitle}
            >
                <Box style={{ textAlign: 'center' }}>
                    {messages.map((text: string, index: number) => (
                        <Typography
                            key={`text_${index}`}
                            textAlign={'center'}
                            fontFamily={baseFontFamily.OpenSansRegular}
                            fontWeight={700}
                            fontSize={14}
                            color="#0D4447"
                        >
                            {text}
                        </Typography>
                    ))}

                    <Spacer height={30} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            {!disableNoButton && (
                                <CustomLoadingButton
                                    key="no_button"
                                    disabled={loading}
                                    label={noLabel ? noLabel : 'Non'}
                                    isDecline
                                    handleClick={() => {
                                        callbackCancel?.();
                                        closeModal();
                                    }}
                                />
                            )}
                            <Spacer width={25} />
                            {!disableYesButton && (
                                <CustomLoadingButton
                                    key="yes_button"
                                    label={yesLabel ? yesLabel : 'Oui'}
                                    loading={loading}
                                    disabled={disableYesButton}
                                    handleClick={() => {
                                        handleConfirmNavigationClick();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Box>
            </CustomModal>
        </>
    );
};
