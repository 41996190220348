const SetUserRole = async (value: string) => {
    try {
        await localStorage.setItem("userRole", JSON.stringify(value))
    } catch (error) {
        throw new Error('Error setting user role')
    }
}

const GetUserRole = async () => {
    try {
        const res =  await localStorage.getItem("userRole") ?? ''
        return JSON.parse(res)
    } catch (error) {
        throw new Error('Error getting user role')
    }
}

const ClearStorage = async () => {
    try {
        await localStorage.clear();
    } catch (error) {
        throw new Error('Error clearing localStorageService')
    }
}

export const LocalStorageService = {
    SetUserRole,
    GetUserRole,
    ClearStorage
}