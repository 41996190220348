import { BilanTabMenuIconName } from 'src/utils/constants/constants';
import { EvaluationsPanel } from './EvaluationsPanel';
import { InformationPanel } from './InformationPanel';
import { ObservationsPanel } from './ObservationsPanel';
import { QuizzCreationProvider } from 'src/providers/QuizzProvider';
import { QuizzPanel } from './Quizz/QuizzPanel';
import { VideoPanel } from './VideoPanel/VideoPanel';

export class TabPanelHandler {
    public handlePanelSelection(data: any) {
        const { moduleType, selectedPanel, setSelectedModuleId, setSelectedPanel, module, isCreatingNewModule } = data;
        let panel = null;
        switch (selectedPanel) {
            case BilanTabMenuIconName.INFORMATION:
                panel = (
                    <InformationPanel
                        setSelectedModuleId={setSelectedModuleId}
                        moduleType={moduleType}
                        module={module}
                    />
                );
                break;
            case BilanTabMenuIconName.VIDEO:
                panel = <VideoPanel module={module} />;
                break;
            case BilanTabMenuIconName.QUIZZ:
                panel = (
                    <QuizzCreationProvider>
                        <QuizzPanel module={module} />
                    </QuizzCreationProvider>
                );
                break;
            case BilanTabMenuIconName.OBSERVATIONS:
                panel = <ObservationsPanel moduleType={moduleType} module={module} />;
                break;
            case BilanTabMenuIconName.EVALUATIONS:
                panel = <EvaluationsPanel moduleType={moduleType} moduleId={module?.id} />;
                break;
        }

        return panel;
    }
}
