import { FC, ReactNode } from 'react';

import AuthProvider from './AuthProvider';
import { AuthorizationProvider } from './AuthorizationProvider';
import { ExplicativeVideoProvider } from './ExplicativeVideoProvider';
import SnackBarProvider from './SnackBarProvider';
import ActionProvider from './ActionProvider';
import AvancementProvider from './AvancementProvider';

interface IProps {
    children: ReactNode;
}

export const Provider: FC<IProps> = ({ children }) => (
    <AuthorizationProvider>
        <AuthProvider isLoggedIn={false}>
            <AvancementProvider>
                <ActionProvider>
                    <SnackBarProvider>{children}</SnackBarProvider>
                </ActionProvider>
            </AvancementProvider>
        </AuthProvider>
    </AuthorizationProvider>
);
