import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

type AppProps = {
    label: String;
    value: boolean;
    handleChange: Function;
    color?: string;
    labelColor?: string;
};

export const CustomCheckbox = ({ label, value, handleChange, color, labelColor }: AppProps) => {
    return (
        <FormGroup style={{ marginLeft: 11 }}>
            <FormControlLabel
                label={label}
                style={{ color: baseColors.black }}
                sx={{
                    '& .MuiTypography-root': {
                        fontFamily: baseFontFamily.OpenSansRegular,
                        color: { labelColor },
                        fontSize: 10.66,
                    },
                }}
                control={
                    <Checkbox
                        checked={value}
                        onChange={(event) => handleChange(event)}
                        sx={{
                            '&.Mui-checked': {
                                color: { color },
                            },
                            '& .MuiSvgIcon-root': {
                                fontSize: 15, // Adjust the fontSize value to change the size of the Checkbox
                            },
                        }}
                    />
                }
            />
        </FormGroup>
    );
};
