import { Box, Paper } from '@mui/material';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';

export const StudentProgressPanel = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();

    return (
        <Box sx={styles.rootPanel}>
            <Paper sx={styles.paperPanel}>Student Progress</Paper>
        </Box>
    );
};
