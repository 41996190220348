interface PdfIconProps {
    width: string;
    height: string;
}

const pdfIconProps: PdfIconProps = {
    width: '26px',
    height: '20px',
};

export const PdfIcon = (props: PdfIconProps = pdfIconProps) => {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3307 10.6889C12.2668 10.0311 11.3664 9.41414 10.7455 8.97266C10.5322 9.67684 10.2354 10.5903 9.99951 11.3054C10.9734 11.0357 12.1001 10.8437 13.3565 10.7326L13.2836 10.6907L13.3307 10.6889Z"
                fill="#3EABAB"
            />
            <path
                d="M22.061 3.50586L17.6999 0.15094C17.5742 0.0542313 17.4039 0 17.2259 0H4.41332C4.04313 0 3.74316 0.230741 3.74316 0.515506V19.4845C3.74316 19.7693 4.04313 20 4.41332 20H21.5871C21.9573 20 22.2572 19.7693 22.2572 19.4845V3.87022C22.2572 3.7333 22.1867 3.60257 22.061 3.50586ZM19.3611 12L19.3512 12.0431C19.1657 12.6068 18.6668 12.9266 17.9819 12.9266C17.5482 12.9266 17.1742 12.7914 17.1037 12.7641L17.0678 12.7489C16.3121 12.3849 15.5355 11.9775 14.7549 11.5344C13.3168 11.5923 11.2859 11.7867 9.64324 12.3779L9.55344 12.645C9.01838 14.1603 7.96945 14.3239 7.54323 14.3239C7.44619 14.3239 7.37676 14.315 7.35371 14.3107L7.29393 14.2983C7.01139 14.2193 6.81276 14.0758 6.70794 13.8801C6.49725 13.4838 6.76236 13.019 6.81758 12.9299L6.90443 12.8281C7.36416 12.4233 7.95283 12.0672 8.65462 11.7688C8.97603 10.8058 9.52824 9.13044 9.77271 8.25965C9.48937 8.04541 9.32263 7.91199 9.28725 7.88374C8.61763 7.31174 8.36806 6.80613 8.53667 6.37475C8.69644 5.96441 9.19021 5.7646 9.45693 5.71119C9.88047 5.62253 10.3319 5.71305 10.6123 5.94977C11.0098 6.28609 11.1524 6.93521 11.0361 7.87921C11.0334 7.90127 11.0294 7.92663 11.0238 7.95571C11.7918 8.51885 13.2983 9.57997 15.1157 10.6337C15.3913 10.6264 15.6685 10.6227 15.9398 10.6227C16.8501 10.6227 17.4594 10.6615 17.5723 10.6693C17.5849 10.6693 18.5301 10.6906 19.0544 11.1636C19.3064 11.3921 19.4125 11.681 19.3611 12ZM16.6338 4.60698C16.5435 4.60698 16.4574 4.57955 16.3936 4.53048C16.3301 4.4814 16.2939 4.4148 16.2939 4.34551L16.2944 1.22649L20.6893 4.60718L16.6338 4.60698Z"
                fill="#3EABAB"
            />
        </svg>
    );
};
