import { Box, CardMedia } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import { useExplicativeVideoContext } from 'src/providers/ExplicativeVideoProvider';
import { ExplicativeVideoHandler } from './ExplicativeVideoHandler';
import { CustomButton } from 'src/components/Button/CustomButton';
import { useMutation, useQuery } from '@apollo/client';
import { DISABLE_EXPLICATIVE_VIDEO } from 'src/graphql/mutations/explicativeVideo';
import { useNavigate } from 'react-router';
import UserConsent from '../UserConsent/UserConsent';
import { GET_USER_CONSENT } from 'src/graphql/queries/queries';
import { isNull } from 'src/utils/utils';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { AuthorizationCode } from 'src/utils/constants/constants';

export const ExplicativeVideo = () => {
    const { codes } = useAuthorizationContext();

    const {
        loading: loadingData,
        data: userConsentData,
        error: errorData,
        refetch: refetchUserConsent,
    } = useQuery(GET_USER_CONSENT);
    let {
        isFirstLogin,
        fileUrl,
        disableExplicativeVideo: disableExplicativeVideoHandler,
    } = useExplicativeVideoContext();
    const isAuthorizedToSeeUserConsent = codes?.includes(AuthorizationCode.FRONT_DISPLAY_USER_CONSENT_SIDEBAR_MENU);
    const isAuthorizedToSeeVideoExplicative = codes?.includes(AuthorizationCode.FRONT_DISPLAY_EXPLICATIVE_VIDEO);

    const [open, setOpen] = useState<boolean>(false);

    const handler = new ExplicativeVideoHandler();
    const [videoLink, setVideoLink] = useState<string>('');
    const [hideNextBtn, setHideNextBtn] = useState<boolean>(true);
    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);

    const [disableExplicativeVideo] = useMutation(DISABLE_EXPLICATIVE_VIDEO);
    const videoRef = useRef<HTMLVideoElement>(null);
    let timeTrackingType: any = {
        currentTime: 0,
        watchedTime: 0,
    };
    const [timeTracking, setTimeTracking] = useState<any>(timeTrackingType);
    const [lastUpdated, setLastUpdated] = useState<string>('currentTime');
    let delta: any;

    useEffect(() => {
        if (isAuthorizedToSeeVideoExplicative) {
            if (isFirstLogin) {
                setOpen(Boolean(isFirstLogin));
                setVideoLink(fileUrl ?? '');
                // console.log(`initial > startTime : ${startTime} and endTime : ${endTime}`);
            } else {
                if (userConsentFilled) {
                    setOpen(false);
                } else {
                    setOpen(true);
                }
            }
        }
    }, [isFirstLogin]);

    useEffect(() => {
        setVideoLink(fileUrl ?? '');
        videoRef.current?.load();
    }, [fileUrl]);

    const [showVideo, setShowVideo] = useState(true);
    const [userConsentFilled, setUserConsentFilled] = useState(false);

    useEffect(() => {
        if (userConsentData) {
            if (isAuthorizedToSeeUserConsent) {
                if (!isNull(userConsentData.getUserConsentCurrentUser)) {
                    setUserConsentFilled(true);
                } else {
                    if (!isFirstLogin) {
                        setUserConsentFilled(false);
                        setShowVideo(false);
                        setOpen(true);
                    }
                }
            }
        }
    }, [userConsentData]);
    return (
        <>
            {!userConsentFilled || isFirstLogin ? (
                <Modal
                    open={open}
                    onClose={() => {}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    hideBackdrop={false}
                    disableEscapeKeyDown={true}
                    style={{ backdropFilter: 'blur(5px)' }}
                >
                    {showVideo === true ? (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                marginX: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'none',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: 'none',
                                    width: '560px',
                                    height: '315px',
                                }}
                            >
                                <CardMedia
                                    ref={videoRef}
                                    id="explicativeVideo"
                                    sx={{ width: '100%', height: '100%' }}
                                    component="video"
                                    image={videoLink}
                                    onEnded={() => {
                                        // DON'T TOUCH THIS COMMENT
                                        //     const endingTime = handler.dateNow();

                                        //     setEndTime(endingTime);

                                        //     handler.handleEndVideo({
                                        //         startTime: startTime,
                                        //         endTime: endTime,
                                        //         setHideNextBtn: setHideNextBtn,
                                        //     });
                                        setHideNextBtn(false);
                                    }}
                                    onPlaying={() => {
                                        const beginTime = handler.dateNow();
                                        setStartTime(beginTime);
                                        console.log('startTime', startTime);
                                    }}
                                    controls
                                    onTimeUpdate={() => {
                                        if (!videoRef.current?.seeking) {
                                            if (videoRef.current) {
                                                if (videoRef.current.currentTime > timeTracking.watchedTime) {
                                                    setTimeTracking((prevState: any) => ({
                                                        ...prevState.currentTime,
                                                        watchedTime: videoRef.current?.currentTime,
                                                    }));
                                                    setLastUpdated('watchedTime');
                                                } else {
                                                    setTimeTracking((prevState: any) => ({
                                                        currentTime: videoRef.current?.currentTime,
                                                        ...prevState.watchedTime,
                                                    }));
                                                    setLastUpdated('currentTime');
                                                }
                                            }
                                        }
                                    }}
                                    onSeeking={() => {
                                        if (videoRef.current) {
                                            if (timeTracking[lastUpdated] !== 0) {
                                                delta = videoRef.current?.currentTime - timeTracking[lastUpdated];
                                                //DOESN'T SHOW THIS IF STATEMENT(delta>0) FOR DEV ENVIRONMENT
                                                if (process.env.REACT_APP_ENV !== 'dev') {
                                                    if (delta > 0) {
                                                        videoRef.current.pause();
                                                        videoRef.current.currentTime = timeTracking[lastUpdated];
                                                        videoRef.current.play();
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    backgroundColor: 'none',
                                    width: '41%',
                                    height: '15%',
                                    display: !hideNextBtn ? 'flex' : 'none',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CustomButton
                                    label="Suivant"
                                    width="150px"
                                    onClick={() => {
                                        disableExplicativeVideo().then(() => {
                                            disableExplicativeVideoHandler?.();
                                            setShowVideo(false);
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                width: '45%',
                                height: '100vh',
                                marginX: 'auto',
                                // paddingRight: '1.5em',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                // overflowY: 'scroll',
                                paddingY: '20px',
                                paddingBottom: '20px',
                                boxSizing: 'border-box',
                            }}
                        >
                            <UserConsent
                                userConsentUpdated={() => {
                                    refetchUserConsent();
                                }}
                                setModal={setOpen}
                            />
                        </Box>
                    )}
                </Modal>
            ) : (
                ''
            )}
        </>
    );
};
