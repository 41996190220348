interface StudentProgressIconProps {
    width: string;
    height: string;
}

const studentProgressIconProps = {
    width: '50',
    height: '50',
};
export const StudentProgressIcon = (props: StudentProgressIconProps = studentProgressIconProps) => {
    const { width, height } = props;

    return (
        <>
            <svg width={width} height={height} viewBox="-10 -10 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 1.14258H32.6923C35.4537 1.14258 37.6923 3.38115 37.6923 6.14258V39.9997H5.00001C2.23858 39.9997 0 37.7611 0 34.9997V1.14258Z"
                    fill="white"
                />
                <path
                    d="M3.0769 3.42871H29.6154C32.3768 3.42871 34.6154 5.66729 34.6154 8.42871V37.7144H8.0769C5.31548 37.7144 3.0769 35.4759 3.0769 32.7144V3.42871Z"
                    fill="#64C7C7"
                />
                <rect x="10.7693" y="12.5713" width="18.4615" height="1.14286" rx="0.571429" fill="white" />
                <rect x="10.7693" y="18.2861" width="20" height="1.14286" rx="0.571428" fill="white" />
                <rect x="10.7693" y="24" width="13.0769" height="1.14286" rx="0.571428" fill="white" />
                <rect x="10.7693" y="29.7139" width="20" height="1.14286" rx="0.571429" fill="white" />
                <ellipse cx="7.3266" cy="13.2365" rx="0.769231" ry="0.571428" fill="white" />
                <ellipse cx="7.3266" cy="18.9503" rx="0.769231" ry="0.571428" fill="white" />
                <ellipse cx="7.3266" cy="24.6652" rx="0.769231" ry="0.571429" fill="white" />
                <ellipse cx="7.3266" cy="30.379" rx="0.769231" ry="0.571428" fill="white" />
                <path
                    d="M13.1147 3.28801C13.1147 2.81721 13.4964 2.43555 13.9672 2.43555H24.0656C24.5364 2.43555 24.918 2.81721 24.918 3.28801V4.87114H13.1147V3.28801Z"
                    fill="#1B8E95"
                />
                <path
                    d="M15.082 0.852458C15.082 0.381658 15.4637 0 15.9345 0H22.0984C22.5692 0 22.9509 0.381659 22.9509 0.852459V2.4356H15.082V0.852458Z"
                    fill="#1B8E95"
                />
                <ellipse cx="32.3078" cy="32.5717" rx="7.69231" ry="5.71428" fill="#F39041" />
                <path
                    d="M30 32.2859L31.5954 33.7144L35.3846 31.4287"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </svg>
        </>
    );
};
