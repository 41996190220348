import { gql } from '@apollo/client';

const USER_FRAGMENT = gql`
    fragment UserFields on User {
        id
        firstName
        lastName
        email
        roles
        activated
    }
`;

const VIDEO_FRAGMENT = gql`
    fragment VideoFields on File {
        id
        title
        description
        fileUrl
        filePath
        originalName
        fileName
        category
    }
`;

const MODULE_FRAGMENT = gql`
    ${VIDEO_FRAGMENT}
    fragment ModuleFields on Module {
        id
        title
        description
        moduleIsValidated
        type
        video {
            ...VideoFields
        }
        videoAnnote {
            ...VideoFields
        }
        quizz {
            id
            type
            text
            choices {
                text
            }
        }
    }
`;
export { USER_FRAGMENT, VIDEO_FRAGMENT, MODULE_FRAGMENT };
