import { useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GET_CURRENT_USER } from 'src/graphql/queries/queries';
import { HeaderIcon } from 'src/components/Icon/HeaderIcon';
import HeaderViewModel from './HeaderViewModel';
import { NotificationActiveIcon } from 'src/components/Icon/NotificationActiveIcon';
import { ProfileImage } from './ProfileImage';
import { useAuthContext } from 'src/providers/AuthProvider';
import { useQuery } from '@apollo/client';
import HeaderHandler from './HeaderHandler';
import CurrentUserViewModel from './CurrentUserViewModel';
import { AccountMenu } from 'src/components/AccountMenu/AccountMenu';
import { useExplicativeVideoContext } from 'src/providers/ExplicativeVideoProvider';
import useLogout from 'src/hooks/useLogout';

export const Header = (props: any) => {
    const viewModel = new HeaderViewModel();
    const styles = viewModel.getStyles();
    const { isLoggedIn } = useAuthContext();
    const query = useQuery(GET_CURRENT_USER);
    const [currentUserViewModel, setCurrentUserViewModel] = useState<Partial<CurrentUserViewModel>>({});
    const handler = new HeaderHandler();
    const { getRemainingTime } = useLogout();

    useEffect(() => {
        getRemainingTime();
        handler.parseCurrentUser({ query }, { currentUserViewModel, setCurrentUserViewModel });
    }, [query.data]);

    return (
        <Box
            sx={{
                display: 'flex',
                height: 75,
                backgroundColor: 'white',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                boxShadow: 'none',
                padding: '0px 25px 0px 25px',
                margin: '0px 0px 0px 0px',
                boxSizing : 'border-box',
                borderRadius : '10px'
            }}
        >
            <Box>
                <HeaderIcon width="100" height="60" />
            </Box>
            <Box pr='25px'>
                <Box display='flex' justifyContent='space-between' alignItems='center' >
                    <Box>
                        <ProfileImage
                            width="36px"
                            height="36px"
                            imageUrl={currentUserViewModel.profileUrl}
                            alt={Array.from(currentUserViewModel.firstName ?? 'N')[0].toUpperCase()}
                        />
                    </Box>
                    <Box pl='10px' pr='10px'>
                        <AccountMenu
                            fullName={
                                (currentUserViewModel.firstName ?? '') + ' ' + (currentUserViewModel.lastName ?? '')
                            }
                        />
                        <span></span>
                    </Box>
                </Box>

                {isLoggedIn ? (
                    ''
                ) : (
                    <Box sx={styles.expand}>
                        <ExpandMoreIcon sx={styles.expandMoreIcon} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
