import { LinearProgress } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { Box } from '@mui/material';

const CustomizedBars = ({ value, maxValue, label }: any) => {
    let normalizedValue = (value / maxValue) * 100;
    if (isNaN(normalizedValue)) normalizedValue = 0;
    const valueChart = 100 - normalizedValue;
    const offset = '20px';
    const height =
        normalizedValue <= 5
            ? `calc(${normalizedValue}% + ${offset}) !important`
            : `calc(${normalizedValue}% ) !important`;
    return (
        <Box
            style={{
                height: '100%',
                borderRadius: '5px',
                backgroundColor: baseColors.green.light,
                width: '100%',
            }}
        >
            <Box
                style={{
                    height: '100%',
                    width: '100%',
                    padding : '25px 10px 30px 10px',
                    boxSizing: 'border-box',
                }}
            >
                <LinearProgress
                    sx={{
                        borderRadius: '5px',
                        minWidth: 130,
                        width: '100%',
                        height: `100% !important`,
                        backgroundColor: 'transparent !important',
                        paddingTop: offset,
                        overflow: 'visible',

                        '& .colorPrimary': {
                            backgroundColor: normalizedValue === 10 ? 'blue' : 'red',
                        },

                        '& .MuiLinearProgress-bar': {
                            height: { height },
                            backgroundColor:
                                normalizedValue === 0
                                    ? baseColors.notStarted
                                    : normalizedValue === 100
                                    ? baseColors.completed
                                    : baseColors.inProgress + ' !important',
                            borderRadius: '5px',
                            marginTop: 'auto',
                            transform: 'translateX(0) !important',
                            transition: 'all .2s linear',
                        },
                        '& .MuiLinearProgress-bar::after': {
                            content: `"${label}"`,
                            fontSize: 13,
                            fontWeight: 600,
                            fontFamily: baseFontFamily.OpenSansRegular,
                            display: 'flex',
                            justifyContent: 'center',
                            height: '20px',
                            transform: `translateY(-${offset})`,
                        },
                        '& .MuiLinearProgress-bar::before': {
                            content: `"${value} / ${maxValue}"`,
                            fontSize: 18,
                            fontFamily : baseFontFamily.OpenSansBold,
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 400,
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            color: '#fff',
                        },
                    }}
                    variant="determinate"
                    value={valueChart}
                />
            </Box>
        </Box>
    );
};

export default CustomizedBars;
