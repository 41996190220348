import { useQuery } from '@apollo/client';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { LIST_AUTHORIZED_FEATURE_CODES } from 'src/graphql/queries/authorizations';
import { GET_EXPLICATIVE_VIDEO } from 'src/graphql/queries/explicativeVideo';
import { GET_CURRENT_USER } from 'src/graphql/queries/queries';
import { AuthorizationCode } from 'src/utils/constants/constants';
import { useAuthorizationContext } from './AuthorizationProvider';

export type ExplicativeVideoContent = {
    children?: ReactNode;
    isFirstLogin?: number;
    fileUrl?: string;
    disableExplicativeVideo?: Function;
};

export const explicativeVideoProps: ExplicativeVideoContent = {
    children: <></>,
    isFirstLogin: 0,
    fileUrl: '',
    disableExplicativeVideo: () => { }
};
export const ExplicativeVideoContext = createContext<ExplicativeVideoContent>(explicativeVideoProps);

export const ExplicativeVideoProvider = (props: ExplicativeVideoContent = explicativeVideoProps) => {
    const currentUserQuery = useQuery(GET_CURRENT_USER);
    const explicativeVideoQuery = useQuery(GET_EXPLICATIVE_VIDEO);
    let { children } = props;
    const [firstLogin, setFirstLogin] = useState(currentUserQuery.data?.currentUser.isFirstSession);
    const parseVideoUrl = () => {
        const explicativeVideo = explicativeVideoQuery.data?.getExplicativeVideos;
        return explicativeVideo ? explicativeVideo.fileUrl : '';
    };
    const [fileUrl, setFileUrl] = useState(parseVideoUrl());

    const handleAuthorization = () => {
        return codes?.indexOf(AuthorizationCode.FRONT_DISPLAY_EXPLICATIVE_VIDEO) !== -1;
    };
    const { codes } = useAuthorizationContext();
    const [hasAuthorization, setHasAuthorization] = useState<boolean>(() => handleAuthorization());

    useEffect(() => {
        if (hasAuthorization) {
            setFirstLogin(currentUserQuery.data?.currentUser.isFirstSession);
        } else {
            setFirstLogin(0);
        }
    }, [currentUserQuery.data]);

    useEffect(() => {
        const firstSession = currentUserQuery.data?.currentUser.isFirstSession;

        if (hasAuthorization && firstSession) {
            setFirstLogin(1);
        } else {
            setFirstLogin(0);
        }
    }, [hasAuthorization]);

    useEffect(() => {
        setHasAuthorization(handleAuthorization());
    }, [codes]);

    useEffect(() => {
        setFileUrl(parseVideoUrl());
    }, [explicativeVideoQuery.data]);

    return (
        <ExplicativeVideoContext.Provider
            value={{
                isFirstLogin: firstLogin,
                fileUrl: fileUrl,
                disableExplicativeVideo: () => {
                    setFirstLogin(false);
                    currentUserQuery.refetch();
                }
            }}
        >
            {children}
        </ExplicativeVideoContext.Provider>
    );
};

export const useExplicativeVideoContext = () => useContext(ExplicativeVideoContext);
