import React from 'react';

interface PlusIconButtonProps {
    size?: string;
}

const PlusIconButton: React.FC<PlusIconButtonProps> = ({ size }) => {
    return (
        <svg width={ size || "60"} height={ size || "60"}  viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={ size || "60"}  height={ size || "60"}  rx="30" fill="#3EABAB" />
            <path
                d="M39.9333 31.7947C40.4856 31.7947 40.9333 31.3469 40.9333 30.7947L40.9333 29.2054C40.9333 28.6531 40.4856 28.2054 39.9333 28.2054L32.7946 28.2054C32.2424 28.2054 31.7946 27.7577 31.7946 27.2054L31.7946 20.0667C31.7946 19.5144 31.3469 19.0667 30.7946 19.0667L29.2054 19.0667C28.6531 19.0667 28.2054 19.5144 28.2054 20.0667L28.2054 27.2054C28.2054 27.7577 27.7576 28.2054 27.2054 28.2054L20.0667 28.2054C19.5144 28.2054 19.0667 28.6531 19.0667 29.2054L19.0667 30.7947C19.0667 31.3469 19.5144 31.7947 20.0667 31.7947L27.2054 31.7947C27.7577 31.7947 28.2054 32.2424 28.2054 32.7947V39.9333C28.2054 40.4856 28.6531 40.9333 29.2054 40.9333H30.7946C31.3469 40.9333 31.7946 40.4856 31.7946 39.9333L31.7946 32.7947C31.7946 32.2424 32.2424 31.7947 32.7946 31.7947L39.9333 31.7947Z"
                fill="white"
            />
        </svg>
    );
};

export default PlusIconButton;
