import { Typography } from '@mui/material';
import { CustomButton } from 'src/components/Button/CustomButton';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';

interface Props {
    module: any;
    setModifyModule: any;
    readOnly: boolean;
}
const ShowModuleInformation = (props: Props) => {
    const { module, setModifyModule, readOnly } = props;

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            fontWeight={700}
                            fontSize={14}
                            color="#000000"
                        >
                            Titre du module :
                        </Typography>
                        <Spacer height={15}></Spacer>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            fontWeight={400}
                            fontSize={14}
                            color={baseColors.green.cancel}
                        >
                            {module.title}
                        </Typography>
                    </div>
                    {/* {!readOnly && ( */}
                    <CustomButton
                        width={'100px'}
                        height={'40px'}
                        label={'Modifier'}
                        fontSize={12}
                        lower={true}
                        onClick={() => setModifyModule(true)}
                    ></CustomButton>
                    {/* )} */}
                </div>

                <Spacer height={25}></Spacer>
                <Typography fontFamily={baseFontFamily.OpenSansRegular} fontWeight={700} fontSize={14} color="#000000">
                    Description :
                </Typography>
                <Spacer height={15}></Spacer>
                <Typography
                    fontFamily={baseFontFamily.OpenSansRegular}
                    fontWeight={400}
                    fontSize={14}
                    color={baseColors.green.cancel}
                >
                    {module.description}
                </Typography>
            </div>
        </>
    );
};

export default ShowModuleInformation;
