import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CustomButton } from 'src/components/Button/CustomButton';
import CustomModal from 'src/components/CustomModal/CustomModal';
import { CustomInputField } from 'src/components/InputField/CustomInputField';
import { GET_ALL_USERS } from 'src/graphql/queries/queries';
import { UserAdapter } from 'src/interfaces/adapter/UserAdapter';
import { User } from 'src/interfaces/interfaces';
import { AuthContext } from 'src/providers/AuthProvider';
import { baseColors, baseFontFamily, baseScrollbar } from 'src/utils/constants/baseStyles';
import { Categorie } from 'src/utils/constants/constants';
import CreateUser from './CreateUser';
import UserListTab from './UserListTab';
import './UserListTab.css';
import { ACTIVATE_USER } from 'src/graphql/mutations/user.mutation';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import ActionProvider, { ActionContext } from 'src/providers/ActionProvider';
import { Spacer } from 'src/components/Spacer/Spacer';
const UserSettingPanel = () => {
    const userListDefault = [
        {
            id: '-----',
            firstName: '----',
            lastName: '----',
            roles: ['STUDENT'],
            email: 'user@example.com',
            categorie: 'STUDENT',
        } as User,
    ];
    const { data, loading, error, refetch } = useQuery(GET_ALL_USERS);
    const [userList, setUserList] = useState<User[]>(userListDefault);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [role, setRole] = useState<string>('PROFESSOR');
    const [reload, setReload] = useState<boolean>(false);
    const { getDecodedToken } = useContext(AuthContext);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const {closeModal, setCloseModal} = useContext(ActionContext);
    const [activateUser, datas] = useMutation(ACTIVATE_USER, {
        onCompleted: (data) => {
            console.log(data, 'data completed');
            setCloseModal(true);
            if(data?.activateUser?.activated){
                showSuccessSnackBar("Utilisateur activé avec succès");
            }else{
                showSuccessSnackBar("Utilisateur désactivé avec succès");
            }
            setReload(true);
        },
        onError: (error) => {
            console.log(error, 'There is an error');
            showErrorSnackBar("Une erreur s'est produite lors de l'opération")
        },
    });

    useEffect(() => {
        if (getDecodedToken) {
            const decodedToken = getDecodedToken();
            const roles = decodedToken?.roles;
            console.log(roles);
            // On retourne le role de l'utilisateur connecté
            // Le role est composé d'array contenant son role et le role par defaut(ROLE_USER)
            // ex: ["ROLE_PROFESSOR", "ROLE_USER"]
            setRole((oldValue: any) => {
                return roles.find((role: string) => role != 'USER');
            });
        }
    }, []);

    useEffect(() => {
        if (data) {
            console.log(data);
            const userList = data.listUser.map((userData: any) => {
                return UserAdapter(userData);
            });
            setUserList(userList);
        }
    }, [data]);

    useEffect(() => {
        if (reload) {
            refetch();
            setReload(false);
        }
    }, [reload]);

    const createNewUser = () => {
        setIsOpened(true);
    };

    
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    fontFamily={baseFontFamily.BreeSerif}
                    fontWeight={400}
                    color={baseColors.green.lightPastel}
                    fontSize={18}
                >
                    Liste des utilisateurs
                </Typography>
                <CustomButton
                    width="auto"
                    label="Créer un nouvel utilisateur"
                    onClick={createNewUser}
                    fontSize={14}
                    lower={true}
                />
            </Box>
            <Box>
                <UserListTab loading={loading} users={userList} onActivate={(userData : {userId : string, activated : boolean})=>{
                    activateUser({
                        variables: {
                            userData,
                        },
                    });
                }}></UserListTab>
            </Box>

            <Spacer height={100} />
            {/* <!-- The  create user modal --> */}
            <CreateUser
                role={role}
                handleCreateNewUser={(user: User) => {
                    console.log('New created user: ', user);
                    setUserList((oldState: User[]) => {
                        return [...oldState, user];
                    });
                }}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                setReload={setReload}
            ></CreateUser>
        </Box>
    );
};

export default UserSettingPanel;
