import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';

export const UserConsentBody = () => {
    return (
        <div>
            <div>
                <CustomText fontSize={10.66}>
                    Dans le cadre de la fourniture des services, IVEHTE, en tant que responsable de traitement, met en
                    oeuvre un traitement des données à caractère personnel des utilisateurs relevant de catégories
                    particulières et notamment des données concernant la santé et le régime alimentaire.
                </CustomText>

                <Spacer height={10}></Spacer>
                <CustomText fontSize={10.66}>
                    Les traitements de vos données sont réalisés conformément à la réglementation sur la protection des
                    données.
                </CustomText>
                <Spacer height={10}></Spacer>
                <CustomText fontSize={10.66}>
                    Je consens expressément à ce que IVEHTE mette en oeuvre un traitement de données à caractère
                    personnel me concernant relevant de catégories particulières et notamment des données concernant ma
                    santé et mon régime alimentaire, selon les modalités et sous réserve de mes droits présentés à
                    l’article “Protection des données à caractère personnel” des CGU :
                </CustomText>
                <Spacer height={5}></Spacer>
            </div>
        </div>
    );
};
