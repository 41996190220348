import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { ErrorText } from 'src/components/Error/ErrorText';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { GlobalAvancementBarChart } from 'src/components/shared/GlobalAvancementBarChart/GlobalAvancementBarChart';
import { adaptSummaryProgression, SummaryProgression } from 'src/components/shared/SummaryProgression';
import { GET_SUMMARY_PROGRESSION_USER_CONNECTED } from 'src/graphql/queries/summary.queries';
import { AvancementContext } from 'src/providers/AvancementProvider';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

const IndividualAvancement: FC<any> = (props: any) => {
    const { loading, data, error, refetch } = useQuery(GET_SUMMARY_PROGRESSION_USER_CONNECTED);
    const [userSummaryProgression, setUserSummaryProgression] = useState<SummaryProgression[]>([]);
    const { reload, setReload } = useContext(AvancementContext);

    useEffect(() => {
        if (data?.getSummaryProgressionOfUserConnected) {
            setUserSummaryProgression(() => {
                return [adaptSummaryProgression(data.getSummaryProgressionOfUserConnected)];
            });
        }
    }, [data]);

    useEffect(() => {
        if(reload){
            refetch();
        }
        setReload(false);
    }, [reload]);

    if (error) {
        console.log('There is error in get_summary_progression_user_connected', error);
    }
    return (
        <>
            {error ? (
                <ErrorText>There is error when loading summaryProgression</ErrorText>
            ) : (
                <Box p="25px">
                    <Box>
                        <Typography fontFamily={baseFontFamily.BreeSerif} fontSize={16} color={baseColors.black}>
                            Progression de mon stage
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '75vh',
                            // paddingTop: 5,
                        }}
                    >
                        {loading ? (
                            <CustomCircleLoader width={30} />
                        ) : (
                            <GlobalAvancementBarChart data={userSummaryProgression} />
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};
export { IndividualAvancement };
