import { Box } from "@mui/material";
import { ErrorTextViewModel } from "./ErrorTextViewModel";
import { ReactNode } from "react";

type ErrorTextProps = {
    children: ReactNode,
    error: any
};

export const ErrorText = (props: any) => {
    const { children, error } = props;
    
    const errorTextViewModel = new ErrorTextViewModel();

    const styles = errorTextViewModel.getStyles();
    
    return (
        <Box sx={styles.errorBox}>
            {children}
        </Box>
    );
};