import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { LIST_AUTHORIZED_FEATURE_CODES } from 'src/graphql/queries/authorizations';
import { useQuery } from '@apollo/client';

export type AuthorizationType = {
    children?: ReactNode;
    codes?: string[];
    setCodes?: any;
};

const authorizationProps: AuthorizationType = {
    children: <></>,
    codes: [],
    setCodes: () => {},
};
export const AuthorizationContext = createContext<AuthorizationType>(authorizationProps);

export const AuthorizationProvider = (props: AuthorizationType) => {
    const { data } = useQuery(LIST_AUTHORIZED_FEATURE_CODES);
    const { children } = props;

    const parseData = (results: any) => {
        let codesData = results ? results : {};
        codesData =
            Object.keys(codesData).indexOf('listAuthorizedFeatureCodes') !== -1
                ? codesData.listAuthorizedFeatureCodes
                : {};
        const items = codesData?.items;
        return items ? items : [];
    };

    const [codes, setCodes] = useState<string[]>(() => {
        return parseData(data);
    });
    
    useEffect(() => {
        setCodes(parseData(data));
    }, [data]);

    return (
        <AuthorizationContext.Provider value={{ codes: codes, setCodes: setCodes }}>
            {children}
        </AuthorizationContext.Provider>
    );
};

export const useAuthorizationContext = () => useContext(AuthorizationContext);
