import React, { useEffect, useRef, useState } from 'react';
interface VideoProps {
    videoUrl: any | undefined;
    videoFile: any;
    onProgress: Function;
    progressionVideo?: any;
}

const Video = (props: VideoProps) => {
    const { videoUrl, videoFile, onProgress, progressionVideo } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const [progression, setProgression] = useState<number>(0);
    const [videoIsStarted, setVideoIsStarted] = useState<boolean>(true);

    let delta: any;
    let timeTrackingType: any = {
        currentTime: 0,
        watchedTime: 0,
    };
    const DIFF_SECONDS = 30;
    const [timeTracking, setTimeTracking] = useState<any>(timeTrackingType);
    const [lastUpdated, setLastUpdated] = useState<string>('currentTime');
    const [previousTime, setPreviousTime] = useState<number>(0.0);

    useEffect(() => {
        videoRef.current?.load();
    }, [videoUrl]);

    return (
        <video
            ref={videoRef}
            width="100%"
            height="90%"
            controls
            poster="evaluation2.png"
            style={{ alignSelf: 'center' }}
            onTimeUpdate={(event: any) => {
                if (!videoRef.current?.seeking) {
                    if (videoRef.current?.currentTime && videoRef.current?.duration) {
                        setProgression((videoRef.current?.currentTime / videoRef.current?.duration) * 100);
                        if (videoRef.current.currentTime - previousTime >= DIFF_SECONDS) {
                            onProgress(progression);
                            setPreviousTime(videoRef.current.currentTime);
                        }
                    }
                    if (videoRef.current) {
                        if (videoRef.current.currentTime > timeTracking.watchedTime) {
                            setTimeTracking((prevState: any) => ({
                                ...prevState.currentTime,
                                watchedTime: videoRef.current?.currentTime,
                            }));
                            setLastUpdated('watchedTime');
                        } else {
                            setTimeTracking((prevState: any) => ({
                                currentTime: videoRef.current?.currentTime,
                                ...prevState.watchedTime,
                            }));
                            setLastUpdated('currentTime');
                        }
                    }
                }
            }}
            onSeeking={() => {
                if (videoRef.current) {
                    if (timeTracking[lastUpdated] !== 0) {
                        delta = videoRef.current?.currentTime - timeTracking[lastUpdated];
                        //DOESN'T SHOW THIS IF STATEMENT(delta>0) FOR DEV ENVIRONMENT
                        if (process.env.REACT_APP_ENV !== 'dev') {
                            if (delta > 0) {
                                videoRef.current.pause();
                                videoRef.current.currentTime = timeTracking[lastUpdated];
                                videoRef.current.play();
                            }
                        }
                    }
                    setVideoIsStarted(false);
                }
            }}
            onCanPlay={() => {
                if (videoIsStarted) {
                    if (videoRef.current) {
                        videoRef.current.currentTime = (progressionVideo * videoRef.current.duration) / 100;
                        setPreviousTime(videoRef.current.currentTime);
                    }
                }
            }}
            onEnded={() => {
                //the video is finished 100%
                onProgress(100);
            }}
        >
            <source src={videoUrl} type={videoFile?.mimeType ?? 'video/mp4'} />
            Your browser does not support the video tag.
        </video>
    );
};

export default Video;
