import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import CustomizedBars from './CustomizedBar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles({
    root: {
        height: '100%',
        minHeight: '150px',
        width: '100%',
        display: 'flex',
        paddingTop: '30px',
        boxSizing: 'border-box',
        // justifyContent : 'center'
    },
    stats: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minWidth: '180px',
        width: '23%',
        padding: '0px 25px 0px 0px',
        boxSizing: 'border-box',
    },
});

export interface ProgressionBarChartData {
    value: number;
    total: number;
    label: string;
    legend: string;
}

export interface ProgressionBarChartProps {
    data: ProgressionBarChartData[];
}

function getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const ProgressionBarChart: FC<ProgressionBarChartProps> = ({ data }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {data.map(({ value, total, label, legend }, index) => ( 
                <div key={`bar#${index}`} className={classes.stats}>
                    <CustomizedBars label={label} value={value} maxValue={total} />
                    
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <KeyboardArrowUpIcon color="disabled" />
                        <Typography fontFamily={baseFontFamily.BreeSerif} fontSize={14} color={baseColors.black}>
                            {legend}
                        </Typography>
                    </div>
                </div>
            ))}
        </Box>
    );
};

export default ProgressionBarChart;
