import { makeStyles } from "@mui/styles";
import { baseColors } from "src/utils/constants/baseStyles";

const styles = {
    baseTable: {
        margin: '10px',
        width : '100%',
        borderCollapse: 'collapse' as const
    },
    rowTable: {
        textAlign: 'left' as const
    },
    cellTable: {
        padding: '10px',
        borderBottom: 'solid #BFC8C9 1px',
    },
    cellTableHeader: {
        padding: '10px',
        borderBottom: 'solid #BFC8C9 2px',
        borderTop: 'solid #BFC8C9 2px',
    },
    cellTableValue: {
        color: baseColors.green.primary,
        padding: '10px',
        borderBottom: 'solid #BFC8C9 1px',
    },
    done: {
        color: baseColors.green.primary
    },
    inProgress: {
        color: baseColors.orange.secondary
    },
    notStarted: {
        color: baseColors.red
    }
}

const summaryActionsTableStyle = makeStyles(styles);

export default summaryActionsTableStyle;