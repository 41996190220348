import { useMutation, useQuery } from '@apollo/client';
import Module from 'module';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { UPDATE_MODULE_TITLE_DESCRIPTION } from 'src/graphql/mutations/mutations';
import { GET_MODULE } from 'src/graphql/queries/module.queries';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
// import { selectCurrentModule, setCurrentModule } from 'src/redux/features/currentModule/currentModuleSlice';
import { selectCurrentModule, setCurrentModule, setCurrentModuleId, updateModule } from 'src/redux/features/listModules/listModulesSlice';
import ShowModuleInformation from './ShowModuleInformation';
import UpdateModuleInformation from './UpdateModuleInformation';

const ModuleInformation = (props: any) => {
    const dispatch = useDispatch();
    let { moduleId, readOnly } = props;

    // const { loading, data, error } = useQuery(GET_MODULE, { variables: { id: moduleId } });
    // const { currentModule, setCurrentModule, modules, setModules } = useCurrentModuleContext();
    const { modules, setModules } = useCurrentModuleContext();
    const currentModule = useSelector(selectCurrentModule);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);

    const [moduleData, setModuleData] = useState<any>();
    const [loadModuleData, setLoadModuleData] = useState(false);
    const [modifyModule, setModifyModule] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (currentModule) {
            setLoadModuleData(true);
            setModuleData(currentModule);
            setTitle(currentModule?.title);
            setDescription(currentModule.description);
            setLoadModuleData(false);
            setModules((oldState: Module[]) => {
                return oldState.map((module: Module) => {
                    // if moduleId !== currentModuleId return module
                    if (module.id !== currentModule.id) {
                        return module;
                        //sinon on retourne currentModule
                    } else {
                        return currentModule;
                    }
                });
            });
        }
    }, [currentModule]);

    const onTitleChange = (event: any) => {
        setTitle(event);
    };
    const onDescriptionChange = (event: any) => {
        setDescription(event);
    };
    const [saveModuleToBackend, dataMutation] = useMutation(UPDATE_MODULE_TITLE_DESCRIPTION);

    const saveModification = () => {
        saveModuleToBackend({
            variables: {
                input: { moduleId, title, description },
            },
            onCompleted: (data) => {
                if (data.updateTitleDescriptionOfModule.code === '200') {
                    showSuccessSnackBar();
                    setModifyModule(false);
                    setModuleData(data.updateTitleDescriptionOfModule.updatedModule);
                    // setCurrentModule((oldState: Module) => {
                    //     return {
                    //         ...oldState,
                    //         title: data.updateTitleDescriptionOfModule.updatedModule.title,
                    //         description: data.updateTitleDescriptionOfModule.updatedModule.description,
                    //     };
                    // });
                    //Update the module in the store
                    // const moduleId = data.updateTitleDescriptionOfModule.updatedModule.id;
                    const updatedModule = data.updateTitleDescriptionOfModule.updatedModule;
                    dispatch(updateModule({ moduleId: updatedModule.id, newValue: updatedModule }));
                    // dispatch(setCurrentModule(updatedModule));
                    // dispatch(setCurrentModuleId(updatedModule.id));
                } else {
                    showErrorSnackBar();
                }
            },
            onError: () => {
                showErrorSnackBar();
            },
        });
    };

    return (
        <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {/* {(loading || loadModuleData) && <CustomCircleLoader />} */}
            {!modifyModule && !loadModuleData && moduleData ? (
                <ShowModuleInformation
                    module={moduleData}
                    setModifyModule={setModifyModule}
                    readOnly={readOnly}
                ></ShowModuleInformation>
            ) : (
                <UpdateModuleInformation
                    title={title}
                    description={description}
                    onTitleChange={onTitleChange}
                    onDescriptionChange={onDescriptionChange}
                    saveModification={saveModification}
                    setModifyModule={setModifyModule}
                ></UpdateModuleInformation>
            )}
        </div>
    );
};

export default ModuleInformation;
