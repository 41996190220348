import {
    Modal,
    Box,
    TextField,
    TextareaAutosize,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { VideoUploader } from './VideoUploader';
import { ChangeEvent, useState, useEffect } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { CustomButton } from 'src/components/Button/CustomButton';
import { useMutation } from '@apollo/client';
import { ADD_VIDEO } from 'src/graphql/mutations/mutations';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { useDispatch } from 'react-redux';
import { Module } from 'src/interfaces/interfaces';
import { setCurrentModule, updateModule } from 'src/redux/features/listModules/listModulesSlice';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '70vh',
    minHeight: 335,
    borderRadius: 2,
    bgcolor: 'background.paper',
    p: 4,
};

export const EditVideo = (props: any) => {
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState<string>(props.fileName);
    const [originalName, setOriginalName] = useState<string>(props.originalName);
    const [title, setTitle] = useState<string>(props.title);
    const [category, setCategory] = useState<string>(props.category);
    const [description, setDescription] = useState<string>(props.description);
    const [isUploaded, setIsUploaded] = useState<boolean>(true);

    const handleClose = (event: any, reason: string) => {
        if (reason !== 'backdropClick') {
            props.setOpenModal(false);
        }
    };

    const moduleId = props.moduleId;
    const setReloadVideo = props.setReloadVideo;

    const [editVideo, { data, loading, error }] = useMutation(ADD_VIDEO, {
        onCompleted: (data) => {
            //Update the module in the Store
            const moduleId = data.addVideo.module.id;
            const moduleUpdated: Module = data.addVideo.module;
            dispatch(updateModule({ moduleId: moduleId, newValue: moduleUpdated }));
            // dispatch(setCurrentModule(moduleUpdated));
            setReloadVideo(true);
            props.setOpenModal(false);
        },
        onError: (error) => {
            alert('add video error');
        },
    });

    const handleSubmit = () => {
        editVideo({ variables: { fileName, originalName, category, title, description, moduleId } });
    };

    const getFile = (data: any) => {
        if (data.file) {
            setFileName(data.file.uniqueIdentifier + '.' + data.file.name.split('.').pop());
            setOriginalName(data.fileName);
        }
    };

    const getUploadState = (data: any) => {
        setIsUploaded(data);
    };

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    const onCategoryChange = (e: SelectChangeEvent<string>) => {
        setCategory(e.target.value);
    };

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={16}
                    color={baseColors.black}
                    textAlign={'center'}
                    marginBottom={5}
                >
                    Modification vidéo
                </Typography>
                <div style={{ width: '100%', height: '75%' }}>
                    <div style={{ float: 'left', width: '20%', height: '90%' }}>
                        <div style={{ width: '100%', height: '65px' }}>
                            <label>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    fontSize={12}
                                    color={baseColors.black}
                                >
                                    Titre :
                                </Typography>
                            </label>
                        </div>
                        <div style={{ width: '100%', height: '125px' }}>
                            <label>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    fontSize={12}
                                    color={baseColors.black}
                                >
                                    Description :
                                </Typography>
                            </label>
                        </div>
                        <div style={{ width: '100%', height: '65px' }}>
                            <label>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    fontSize={12}
                                    color={baseColors.black}
                                >
                                    Type :
                                </Typography>
                            </label>
                        </div>
                        <div style={{ width: '100%', height: '75px' }}>
                            <label>
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansRegular}
                                    fontSize={12}
                                    color={baseColors.black}
                                >
                                    Importer le fichier :
                                </Typography>
                            </label>
                        </div>
                    </div>
                    <div style={{ float: 'left', width: '80%', height: '90%', marginTop: '-5px' }}>
                        <div style={{ width: '100%', height: '65px' }}>
                            <TextField multiline name="title" value={title} onChange={onTitleChange} fullWidth />
                        </div>
                        <div style={{ width: '100%', height: '125px' }}>
                            <TextareaAutosize
                                style={{ width: '99.5%', height: '90%' }}
                                value={description}
                                onChange={onDescriptionChange}
                            />
                        </div>
                        <div style={{ width: '100%', height: '65px' }}>
                            <Select
                                inputProps={{ 'aria-label': 'Without label' }}
                                name="category"
                                onChange={onCategoryChange}
                                defaultValue={category}
                                fullWidth
                            >
                                <MenuItem value="video">Vidéo explicative</MenuItem>
                                <MenuItem value="video_annote">Vidéo annotée</MenuItem>
                            </Select>
                        </div>
                        <div style={{ width: '100%', height: '75px' }}>
                            <div style={{ width: '155px' }}>
                                <VideoUploader
                                    nameOfFile={originalName}
                                    onChange={getFile}
                                    onUploaded={getUploadState}
                                ></VideoUploader>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div style={{ width: '100%', height: '75px', textAlign: 'center' }}>
                        <Box style={{ textAlign: 'center' }}>
                            <CustomButton
                                label={' Annuler '}
                                width={150}
                                background={baseColors.green.cancel}
                                color={baseColors.white}
                                onClick={handleClose}
                                marginRight={'8px'}
                            ></CustomButton>
                            {loading === true ? (
                                <CustomCircleLoader width={30} />
                            ) : (
                                <CustomButton
                                    label={' Modifier '}
                                    width={150}
                                    disabled={
                                        !title || !description || !category || !fileName || !isUploaded || !originalName
                                    }
                                    onClick={handleSubmit}
                                ></CustomButton>
                            )}
                        </Box>
                    </div>
            </Box>
        </Modal>
    );
};
