import { Box } from '@mui/material';
import VideoContentViewModel from './VideoContentViewModel';
import { PlayButtonIcon } from 'src/components/Icon/PlayButtonIcon';
import { useRef, useState } from 'react';
import { transform } from 'typescript';
import { baseColors } from 'src/utils/constants/baseStyles';

interface VideoContentProps {
    width: string;
    height: string;
    src: string;
    isOnModule?: boolean;
    x?: string;
    y?: string;
}

const videoContentProps = {
    width: '50',
    height: '50',
    src: '',
    isOnModule: false,
    x: '0',
    y: '0',
};

const pausedView = {
    cursor: 'pointer',
    display: 'inline-block',
    textAlign: 'center',
    padding: '5px 25px',
};

const playedView = {
    cursor: 'pointer',
    display: 'inline-block',
    textAlign: 'center',
    padding: '5px 25px',
    opacity: 0,
};

export const VideoContent = (props: VideoContentProps = videoContentProps) => {
    const { width, height, src, isOnModule, x, y } = props;
    const viewModel = new VideoContentViewModel();
    const styles = viewModel.getStyles();
    const [isPlayed, setIsPlayed] = useState<boolean>(false);
    const [playIconStyle, setPlayIconStyle] = useState<any>(pausedView);
    const styleWrapper = isOnModule ? styles.onModuleWrapper : styles.wrapper;
    const styleContent = isOnModule ? styles.onModuleContent : styles.content;

    const videoRef = useRef<HTMLVideoElement>(null);

    const togglePlay = () => {
        if (!isPlayed) {
            setPlayIconStyle(playedView);
            videoRef.current?.play();
            setIsPlayed(true);
        } else {
            setPlayIconStyle(pausedView);
            videoRef.current?.pause();
            setIsPlayed(false);
        }
    };

    return (
        <Box sx={styleWrapper} width="100%">
            <video
                ref={videoRef}
                width={width}
                height={height}
                src={src}
                style={{ backgroundColor: 'white', borderRadius: 6 }}
            ></video>
            {x && y ? (
                <Box sx={{ transform: `translate(${x}%, ${y}%)`, ...styles.simpleContent }}>
                    <Box sx={playIconStyle} onClick={togglePlay}>
                        <PlayButtonIcon width="25px" height="25px" />
                    </Box>
                </Box>
            ) : (
                <Box sx={styleContent}>
                    <Box sx={playIconStyle} onClick={togglePlay}>
                        <PlayButtonIcon width="25px" height="25px" />
                    </Box>
                </Box>
            )}
        </Box>
    );
};
