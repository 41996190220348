import { gql } from "@apollo/client";

export const GET_EXPLICATIVE_VIDEO = gql`
    query GetExplicativeVideo {
        getExplicativeVideos {
            fileUrl
        }
    }
`;



