import { createContext, useContext } from "react";

import { Question } from "src/interfaces/interfaces";

type QuizzCreationContent = {
    addQuestion(question: Question): void,
    removeQuestion(question: Question): void,
    updateQuestion(id: string, updatedValues: any): void,
    initializeQuestions(questions: Question[]): void,
    questions: Question[]
}

export const QuizzCreationContext = createContext<QuizzCreationContent>({
    addQuestion: (question: Question) => { },
    removeQuestion: (question: Question) => { },
    updateQuestion: (id: string, updatedValues: any) => { },
    initializeQuestions: (questions: Question[]) => { },
    questions: []
});