
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_TOOL_BOX_MUTATION } from 'src/graphql/mutations/mutations';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';


const DeleteToolBoxModal = (props: any) => {
    const { modalDocVisible, toggleModal, refetch } = props;
    const handleClose = () => toggleModal(false);
    const [id, setId] = useState<string>(props.id);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [deleteTool, { data, loading, error }] = useMutation(DELETE_TOOL_BOX_MUTATION, {
        onCompleted: (data) => {
            console.log(data);
            const result = data.deleteToolBox;
            if (result?.status == '200') {
                refetch();
                showSuccessSnackBar('Suppression avec success!');
            } else {
                showErrorSnackBar('Une erreur est survenue lors de la suppression! Veuillez réessayer plus tard');
            }
            setIsDisabled(false);
            toggleModal();
        },
        onError: (error) => {
            showErrorSnackBar('Une erreur est survenue lors de la suppression! Veuillez réessayer plus tard');
        },
    });
    const handleDeleteFile = () => {
        setIsDisabled(true);
        deleteTool({ variables: { id } });
    };
    useEffect(() => {
        setId(props.id);
    });

    return (
        <CustomConfirm
            modalVisible={modalDocVisible ?? false}
            updateModalVisible={handleClose}
            yesLabel={'Oui'}
            noLabel={'Non'}
            message={'Voulez-vous vraiment supprimer ce fichier?'}
            disableBackdropClick={false}
            callBackconfirm={handleDeleteFile}
        ></CustomConfirm>
    );
};

export default DeleteToolBoxModal;
