class ProfileImageViewModel {
    private style = {
        image: {
            borderRadius: '100%',
            backgroundColor: '#989898',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    public getStyles() {
        return this.style;
    }
}

export default ProfileImageViewModel;
