import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Box, FormControlLabel, Grid, MenuItem } from '@mui/material';
import { Radio, RadioGroup, Select, TextField } from '@mui/material';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { CustomButton } from 'src/components/Button/CustomButton';
import CustomModal from 'src/components/CustomModal/CustomModal';
import { UploadPhoto } from 'src/components/shared/UploadPhoto/UploadPhoto';
import { CREATE_USER } from 'src/graphql/mutations/user.mutation';
import { LIST_ALL_PROMOTIONS } from 'src/graphql/queries/queries';
import { UserAdapter } from 'src/interfaces/adapter/UserAdapter';
import { AuthContext } from 'src/providers/AuthProvider';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { getRoleLabelFromRoleValue, validateForm } from './CreateUserHelper';
import InputSelectGender from './InputSelectGender';
import InputSelectPromotion from './InputSelectPromotion';
import InputSelectRole from './InputSelectRole';
import InputText from './InputText';
import { DEFAULT_FORM_TYPE, UserFormType } from './User.types';
import { Typography } from '@mui/material';

const CreateUser = (props: any) => {
    const { handleCreateNewUser, role, isOpened, setIsOpened, setReload } = props;
    const availableRole = [
        {
            label: 'Administrateur',
            value: 'ROLE_ADMIN',
        },
        {
            label: 'Professeur',
            value: 'ROLE_PROFESSOR',
        },
        {
            label: 'Étudiant',
            value: 'ROLE_STUDENT',
        },
    ];
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [userFormValue, setUserFormValue] = useState<UserFormType>(DEFAULT_FORM_TYPE);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const [createUser, data] = useMutation(CREATE_USER, {
        onCompleted: (data: any) => {
            const newId = data.createUser?.user?.id;
            if (!newId) showErrorSnackBar("Echec creation d'utilisateur: " + data.createUser?.message);
            else {
                showSuccessSnackBar("Creation d'utilisateur réussie");
                setUserFormValue(DEFAULT_FORM_TYPE);
                setReload(true);
                setIsOpened(false);
                handleCreateNewUser(UserAdapter(data.createUser.user));
            }
        },
        onError: (error: any) => {
            console.log(error);
            showErrorSnackBar('Creating user failed');
        },
    });
    const [promotionList, setPromotionList] = useState<any[]>([]);
    const { loading, data: dataPromotionListQuery, error } = useQuery(LIST_ALL_PROMOTIONS);
    const createNewUser = () => {
        const userData = {
            firstName: userFormValue.firstName,
            lastName: userFormValue.lastName,
            email: userFormValue.email,
            role: userFormValue.roles,
            promotion: userFormValue.promotionId,
            photoProfilContent: userFormValue.photoProfilContent,
        };
        createUser({
            variables: { userData },
        });
    };
    const handleChange = (key: string, value: any) => {
        setUserFormValue((oldValue: any) => {
            return {
                ...oldValue,
                [key]: value,
            };
        });
    };

    const renderInput = (key: string, label: string) => {
        return (
            <Grid container alignItems="center">
                <Grid item md={2} sm={12}>
                    <Typography fontSize={12}>
                        {label} :
                    </Typography>
                </Grid>
                <Grid item md={10} sm={12}>
                    <TextField
                        fullWidth
                        value={userFormValue[key as keyof UserFormType]}
                        onChange={(value) => {
                            handleChange(key, value.target.value);
                        }}
                        InputProps={{
                            style: { fontSize: '13px' }, // Adjust the fontSize value as per your requirement
                        }}
                    ></TextField>
                </Grid>
            </Grid>
        );
    };

    useLayoutEffect(() => {
        setIsFormValid(validateForm(userFormValue));
    }, [userFormValue]);

    useEffect(() => {
        if (dataPromotionListQuery) {
            setPromotionList((oldstate: any[]) => {
                return dataPromotionListQuery.getAllPromotion.map((promotion: any) => {
                    return {
                        id: promotion.id,
                        name: promotion.name,
                    };
                });
            });
        }
    }, [dataPromotionListQuery]);
    useEffect(() => {
        handleChange('promotionId', '');
    }, [userFormValue.roles]);
    return (
        <CustomModal
            width="40vw"
            open={isOpened}
            toggleModal={setIsOpened}
            title={`Créer un nouvel utilisateur : ${getRoleLabelFromRoleValue(availableRole, userFormValue.roles)}`}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, paddingRight: '2em', paddingLeft: '2em' }}>
                {/* <Box>
                    <Avatar
                        alt="Remy Sharp"
                        src={require('src/assets/icons/default-avatar.jpg')}
                        sx={{
                            width: '150px',
                            height: '150px',
                        }}
                    />
                </Box> */}
                <UploadPhoto
                    onUploadedPhoto={(photo: any) => {
                        handleChange('photoProfilContent', photo);
                    }}
                />
                {/* <InputText
                    currentValue={userFormValue.lastName}
                    onChange={(value: any) => {
                        handleChange('lastName', value.target.value);
                    }}
                    label="Nom"
                /> */}
                <Grid container alignItems="center">
                    {renderInput('lastName', 'Nom')}
                </Grid>
                {/* <Grid container alignItems="center"> */}
                {/* {renderInput('lastName', 'Nom')} */}

                {/* </Grid> */}
                <Grid container alignItems="center">
                    {renderInput('firstName', 'Prénom')}
                </Grid>
                <Grid container alignItems="center">
                    {renderInput('email', 'Email')}
                </Grid>

                <InputSelectGender
                    currentValue={userFormValue.gender}
                    onChange={(value: any) => {
                        handleChange('gender', value.target.value);
                    }}
                />
                {role == 'ROLE_ADMIN' && (
                    <InputSelectRole
                        currentValue={userFormValue.roles}
                        onChange={(value: any) => {
                            handleChange('roles', value.target.value);
                        }}
                        availableRole={availableRole}
                    />
                )}
                {userFormValue.roles == 'ROLE_STUDENT' && (
                    <InputSelectPromotion
                        promotionList={promotionList}
                        onChange={(value: any) => {
                            handleChange('promotionId', value.target.value);
                        }}
                        currentValue={userFormValue.promotionId}
                    />
                )}
                <Box>
                    <CustomButton
                        disabled={!isFormValid}
                        width="auto"
                        label="Créer un nouvel utilisateur"
                        onClick={createNewUser}
                        fontSize={14}
                        lower={true}
                    />
                </Box>
            </Box>
        </CustomModal>
    );
};

export default CreateUser;
