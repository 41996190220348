import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        minHeight: '66.5px',
        minWidth : '400px',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 8,
        // boxSizing : 'border-box',
        justifyItems: 'center',
        // p : '20px',
        marginBottom : '25px'
    },
});

export default useStyles;
