import { baseColors } from 'src/utils/constants/baseStyles';

class VideoContentViewModel {
    private styles = {
        wrapper: {
            position: 'relative',
            display: 'inline-block',
            // backgroundColor : 'purple'
        },
        content: {
            position: 'absolute',
            display: 'inline-block',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
            width: '100%',
            textAlign: 'center',
            zIndex: '999',
        },
        simpleContent: {
            position: 'absolute',
            display: 'inline-block',
            top: '50%',
            left: '50%',
            width: '100%',
            textAlign: 'center',
            zIndex: '999',
        },
        play: {
            cursor: 'pointer',
            display: 'inline-block',
            textAlign: 'center',
            padding: '5px 25px',
        },
        onModuleWrapper: {
            position: 'relative',
            display: 'inline-block',
        },
        onModuleContent: {
            position: 'absolute',
            display: 'inline-block',
            transform: 'translate(-68%,-50%)',
            top: '50%',
            left: '50%',
            width: '100%',
            textAlign: 'center',
            zIndex: '999',
        },
    };
    public getStyles() {
        return this.styles;
    }
}

export default VideoContentViewModel;
