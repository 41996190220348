import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { StudentData } from 'src/components/Cards/Interface/StudentData';
import { StudentCardList } from 'src/components/Cards/StudentCardList';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { LIST_STUDENTS_WITH_PROGRESSION } from 'src/graphql/queries/queries';

interface StudentListProps {
    promotionId?: string;
}

const StudentList: FC<StudentListProps> = ({ promotionId }) => {
    const [studentListData, setStudentListData] = useState<any[]>([]);
    const { loading, data, error } = useQuery(LIST_STUDENTS_WITH_PROGRESSION, {
        variables: { promotionId },
        skip: promotionId == '' || promotionId == null,
    });
    const computeProgression = (
        progressionVideoEducativeBilan: number,
        progressionFormEducativeBilan: number,
        progressionVideoEducativeWorkshop: number,
        progressionFormEducativeWorkshop: number,
    ) => {
        return (
            (progressionFormEducativeBilan +
                progressionVideoEducativeBilan +
                progressionFormEducativeWorkshop +
                progressionVideoEducativeWorkshop) /
            4
        );
    };
    if (error) {
        console.log(error);
    }

    useEffect(() => {
        if (data) {
            let studentList: any[] = [];
            studentList = data.listStudentWithProgression;
            studentList = studentList.map(({ student, educativeBilan, educativeWorkshop }) => {
                return {
                    id: student.id,
                    fullName: student.firstName + ' ' + student.lastName,
                    photo: '',
                    progress: computeProgression(
                        educativeBilan.seenVideos,
                        educativeBilan.filledForm,
                        educativeWorkshop.seenVideos,
                        educativeWorkshop.filledForm,
                    ),
                } as StudentData;
            });
            setStudentListData(studentList);
        }
    }, [data]);

    return <StudentCardList loading={loading} dataRows={studentListData} />;
};

export { StudentList };
