import { UserFormType } from './User.types';


export const validateForm = (userFormValue: UserFormType) => {
    const studentValidation =
        userFormValue.roles == 'ROLE_STUDENT' && userFormValue.promotionId !== null && userFormValue.promotionId !== '';
    const inputValidation =
        userFormValue.firstName !== '' && userFormValue.lastName !== '' && userFormValue.email !== '';
    return userFormValue.roles == 'ROLE_STUDENT' ? studentValidation && inputValidation : inputValidation;
};

export const getRoleLabelFromRoleValue = (availableRole: any[], roleValue: any) => {
    return availableRole.find((role) => role.value == roleValue)?.label;
};
