import { configureStore } from '@reduxjs/toolkit';
import counterSlice from './features/counter/counterSlice';
import listModulesSlice from './features/listModules/listModulesSlice';
// import currentModuleSlice from './features/currentModule/currentModuleSlice';

export const store: any = configureStore({
    reducer: {
        counterReducer: counterSlice,
        listModulesReducer: listModulesSlice,
        // currentModuleReducer: currentModuleSlice
    },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
