interface RecyclerIconProps {
    width: string;
    height: string;
}

const recyclerIconProps: RecyclerIconProps = {
    width: '21px',
    height: '21px',
};

export const RecyclerIcon = (props: RecyclerIconProps = recyclerIconProps) => {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 0C7.35 0 6 1.18125 6 2.625H3C1.35 2.625 0 3.80625 0 5.25H21C21 3.80625 19.65 2.625 18 2.625H15C15 1.18125 13.65 0 12 0H9ZM3 7.875V20.5012C3 20.79 3.24 21 3.57 21H17.46C17.79 21 18.03 20.79 18.03 20.5012V7.875H15.03V17.0625C15.03 17.7975 14.37 18.375 13.53 18.375C12.69 18.375 12.03 17.7975 12.03 17.0625V7.875H9.03V17.0625C9.03 17.7975 8.37 18.375 7.53 18.375C6.69 18.375 6.03 17.7975 6.03 17.0625V7.875H3.03H3Z"
                fill="red"
            />
        </svg>
    );
};
