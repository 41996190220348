import { Paper, Typography, Switch } from '@mui/material';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { User } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import TabViewModel from './TabViewModel';
import { useContext, useEffect, useState } from 'react';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { categories } from 'src/utils/constants/constants';
import { ACTIVATE_USER } from 'src/graphql/mutations/user.mutation';
import { useMutation, useQuery } from '@apollo/client';
import { useAuthorizationContext } from 'src/providers/AuthorizationProvider';
import { AuthorizationCode } from 'src/utils/constants/constants';
import { GET_CURRENT_USER } from 'src/graphql/queries/queries';
import { translateRole } from 'src/utils/helpers/helpers';
import { ActionContext } from 'src/providers/ActionProvider';

type UserListTabProps = {
    users: User[];
    loading: boolean;
    onActivate: (userData: { userId: string; activated: boolean }) => void;
};

function compareArrays(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    return arr1.every((item) => arr2.includes(item));
}

const UserListTab = (props: UserListTabProps) => {
    const { codes } = useAuthorizationContext();
    const { users, loading, onActivate } = props;

    const [isActivated, setIsActivated] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const {closeModal, setCloseModal} = useContext(ActionContext);
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState<boolean>(false);

    const isAuthorizedToActivateAdmin = codes?.includes(AuthorizationCode.FRONT_ACTIVATION_ADMIN);
    const isAuthorizedToActivateProfessor = codes?.includes(AuthorizationCode.FRONT_ACTIVATION_PROFESSOR);
    const isAuthorizedToActivateStudent = codes?.includes(AuthorizationCode.FRONT_ACTIVATION_STUDENT);

    const [currentUserId, setCurrentUserId] = useState<string>('');

    const query = useQuery(GET_CURRENT_USER);

    useEffect(() => {
        if (query.data) {
            setCurrentUserId(query.data?.currentUser?.id || '');
        } else {
            // Handle the error here
            console.error('Error occurred while fetching current user:', query.error);
        }
    }, [query]);

    useEffect(()=>{
        if(closeModal){
            setModalConfirmationVisible(false);
            setCloseModal(false);
        }
    }, [closeModal])

    const vm = new TabViewModel();
    // const style = vm.getStyle();
    const toggleModalConfirmation = (user: User) => {
        setIsActivated(user.activated);
        setUserId(user.id);
        setModalConfirmationVisible(!modalConfirmationVisible);
    };

    const handleChange = () => {
        const userData = {
            userId: userId,
            activated: !isActivated,
        };

        onActivate(userData);
    };

    const authorization = (user: User): boolean => {
        if (user.id === currentUserId) {
            return true;
        }
        if (user.roles) {
            if (user.roles.includes(categories.PROFESSOR) && isAuthorizedToActivateProfessor) {
                return false;
            } else if (user.roles.includes(categories.ADMIN) && isAuthorizedToActivateAdmin) {
                return false;
            } else if (
                (user.roles.includes(categories.STUDENT) || compareArrays(user.roles, [categories.USER])) &&
                isAuthorizedToActivateStudent
            ) {
                return false;
            }
        }
        return true;
    };

    const renderRow = (row: User) => {
        return (
            <tr className="tableRow">
                {/* <td className="tableCell">{row.id}</td> */}
                <td className="tableCell">
                    <Typography
                        fontFamily={baseFontFamily.OpenSansRegular}
                        color={baseColors.green.cancel}
                        fontSize={13}
                    >
                        {row.firstName}
                    </Typography>
                </td>
                <td className="tableCell">
                    <Typography
                        fontFamily={baseFontFamily.OpenSansRegular}
                        color={baseColors.green.cancel}
                        fontSize={13}
                    >
                        {row.lastName}
                    </Typography>
                </td>
                <td className="tableCell">
                    <Typography
                        fontFamily={baseFontFamily.OpenSansRegular}
                        color={baseColors.green.cancel}
                        fontSize={13}
                    >
                        {translateRole(row.roles[0])}
                    </Typography>
                </td>
                <td className="tableCell">
                    <Typography
                        fontFamily={baseFontFamily.OpenSansRegular}
                        color={baseColors.green.cancel}
                        fontSize={13}
                    >
                        {translateRole(row.categorie)}
                    </Typography>
                </td>
                <td className="tableCell">
                    <Switch
                        size="small"
                        checked={row?.activated}
                        onClick={() => {
                            toggleModalConfirmation(row);
                        }}
                        disabled={authorization(row)}
                    />
                </td>
            </tr>
        );
    };

    return (
        <Paper>
            <table className="table">
                <tr className="tableHeader">
                    {/* <th className="tableCell tableCellHeader" style={{ color: baseColors.green.primary }}>
                        Id
                    </th> */}
                    <th className="tableCell tableCellHeader">
                        <Typography
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.green.primary}
                            fontSize={13}
                        >
                            Nom
                        </Typography>
                    </th>
                    <th className="tableCell tableCellHeader">
                        <Typography
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.green.primary}
                            fontSize={13}
                        >
                            Prénom
                        </Typography>
                    </th>
                    <th className="tableCell tableCellHeader">
                        <Typography
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.green.primary}
                            fontSize={13}
                        >
                            Rôle
                        </Typography>
                    </th>
                    <th className="tableCell tableCellHeader" style={{ color: baseColors.green.primary }}>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.green.primary}
                            fontSize={13}
                        >
                            Catégorie
                        </Typography>
                    </th>
                    <th className="tableCell tableCellHeader" style={{ color: baseColors.green.primary }}>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.green.primary}
                            fontSize={13}
                        >
                            Action
                        </Typography>
                    </th>
                </tr>
                {loading ? <CustomCircleLoader width={30} /> : users.map((user) => renderRow(user))}
            </table>
            {isActivated ? (
                <CustomConfirm
                    modalVisible={modalConfirmationVisible}
                    updateModalVisible={setModalConfirmationVisible}
                    callBackconfirm={() => handleChange()}
                    message={'Voulez-vous vraiment désactiver cet utilisateur ?'}

                />
            ) : (
                <CustomConfirm
                    modalVisible={modalConfirmationVisible}
                    updateModalVisible={setModalConfirmationVisible}
                    callBackconfirm={() => handleChange()}
                    message={'Voulez-vous vraiment activer cet utilisateur ?'}
                />
            )}
        </Paper>
    );
};

export default UserListTab;
