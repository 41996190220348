import { baseColors } from 'src/utils/constants/baseStyles';

class HomeViewModel {
    private style = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            alignContent: 'space-between',
            height: '100%',
            // minWidth: '1368px',
            // maxWidth: '1368px',
            width: '1368px',
        },
        sidebarContent: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 1,
            alignItems: 'stretch',
            width: '100%',
            backgroundColor: baseColors.green.light,
            flexGrow: 1,
            minHeight: '100%',
            height: '100%',
            maxHeight: '100%',
            alignContent: 'stretch',
        },
    };

    public getStyles() {
        return this.style;
    }
}

export default HomeViewModel;
