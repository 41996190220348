import { BilanTabMenuIconName, ModuleType } from 'src/utils/constants/constants';

import { Box } from '@mui/material';
import { Module } from 'src/interfaces/interfaces';
import { TabPanelHandler } from './TabPanelHandler';
import { TabPanelViewModel } from './TabPanelViewModel';
import { useEffect } from 'react';
import { useModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { useCurrentModuleContext } from 'src/providers/ModuleListProvider';
import { useSelector } from 'react-redux';
import { selectCurrentModule } from 'src/redux/features/listModules/listModulesSlice';
// import { selectCurrentModule } from 'src/redux/features/currentModule/currentModuleSlice';

type ITabPanelContainerProps = {
    moduleType: ModuleType;
    // module: Module;
    setSelectedModuleId?: any;
};

export const TabPanelContainer = (props: ITabPanelContainerProps) => {
    const vm = new TabPanelViewModel();
    const styles = vm.getStyles();
    const handler = new TabPanelHandler();
    const { selectedPanel, setSelectedPanel, isCreatingNewModule } = useModuleDetailContext();
    const { moduleType, setSelectedModuleId } = props;
    // const { currentModule, setCurrentModule } = useCurrentModuleContext();
    // const module = currentModule;
    const module = useSelector(selectCurrentModule);
    // console.log({ module });
    return (
        <Box sx={(styles.panelContainer, { height: '100%' })}>
            {handler.handlePanelSelection({
                moduleType,
                selectedPanel,
                module,
                isCreatingNewModule,
                setSelectedPanel,
                setSelectedModuleId,
            })}
        </Box>
    );
};
