import { Avatar, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontFamily } from '../../utils/constants/baseStyles';
import { StudentCardItemProps } from './Interface/StudentCardItemProps';
import useStyles from './styles/studentCardItemStyles';
import { LinearProgress } from '@mui/material';
import { CustomText } from '../Text/CustomText';

export const StudentCardItem: FC<StudentCardItemProps> = (props) => {
    const { fullName, progress, photo, id, selected } = props;
    const classes = useStyles();

    const bgColor = selected ? baseColors.green.primary : baseColors.green.light;

    const getProgress = (progress: number, selected: boolean) => {
        if (progress === 100) {
            if (selected) {
                return (
                    <>
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            fontSize={10}
                            color={baseColors.green.primary}
                            style={{ fontWeight: 600 }}
                            fontWeight={700}
                        >
                            Validé
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            color="success"
                            style={{ marginRight: 30 }}
                            sx={{ borderRadius: 2, backgroundColor: baseColors.white }}
                        />
                    </>
                );
            }
            return (
                <>
                    <Typography
                        fontFamily={baseFontFamily.OpenSansRegular}
                        fontSize={10}
                        color={baseColors.green.primary}
                        fontWeight={700}
                    >
                        Validé
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        color="success"
                        style={{ marginRight: 30 }}
                        sx={{ borderRadius: 2, backgroundColor: baseColors.white }}
                    />
                </>
            );
        }
        if (progress < 100 && progress > 0) {
            return (
                <>
                    <Typography
                        fontFamily={baseFontFamily.OpenSansRegular}
                        fontSize={10}
                        color={baseColors.warning}
                        fontWeight={700}
                    >
                        En cours
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        color="warning"
                        style={{ marginRight: 30 }}
                        sx={{ borderRadius: 2, backgroundColor: baseColors.white }}
                    />
                </>
            );
        }
        return (
            <>
                <Typography
                    fontFamily={baseFontFamily.OpenSansRegular}
                    fontSize={10}
                    color={'#DE2A33'}
                    fontWeight={700}
                >
                    Non commencé
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    color="warning"
                    style={{ marginRight: 30 }}
                    sx={{ borderRadius: 2, backgroundColor: baseColors.white }}
                />
            </>
        );
    };

    return (
        <Grid
            style={{
                background: bgColor,
                borderColor: bgColor,
            }}
            className={classes.container}
            container
        >
            <Grid
                item
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
                justifyItems={'center'}
                flex={2}
            >
                <Avatar
                    alt="Remy Sharp"
                    style={{ alignSelf: 'center', marginLeft: '18%' }}
                    src={photo ?? photo}
                    sx={{ width: '50px', height: '50px' }}
                />
            </Grid>
            <Grid item style={{ marginLeft: 20 }} flex={4}>
                <Typography
                    fontFamily={baseFontFamily.OpenSansRegular}
                    fontSize={11.5}
                    color={baseColors.green.cancel}
                    fontWeight={600}
                >
                    {fullName}
                </Typography>
                {getProgress(progress, selected)}
            </Grid>
        </Grid>
    );
};
