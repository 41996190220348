export type UserFormType = {
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    roles: string;
    promotionId?: string;
    photoProfilContent: string;
};

export const DEFAULT_FORM_TYPE = {
    firstName: '',
    lastName: '',
    email: '',
    gender: 'homme',
    roles: 'ROLE_STUDENT',
    promotionId: '',
    photoProfilContent: "",
};