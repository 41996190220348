import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { ReactEventHandler, useState } from 'react';

interface InputSelectPromotionProps {
    currentValue?: String;
    promotionList: Array<any>;
    onChange: Function;
}
const InputSelectPromotion = (props: InputSelectPromotionProps) => {
    const { currentValue, promotionList, onChange } = props;
    // const [promotionId, setPromotionId] = useState<String>(props.currentValue);
    return (
        <Grid container alignItems="center">
            <Grid item md={2} sm={12}>
                <Typography fontSize={12}>Promotion : </Typography>
            </Grid>
            <Grid item md={10} sm={12}>
                <Select
                    value={currentValue}
                    label=""
                    onChange={(value) => {
                        onChange(value);
                    }}
                    fullWidth
                >
                    {promotionList.map((promotion) => {
                        return (
                            <MenuItem key={promotion.id} value={promotion.id}>
                                <Typography fontSize={12}>{promotion.name}</Typography>
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
        </Grid>
    );
};

export default InputSelectPromotion;
