import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { RecyclerIcon } from 'src/components/Icon/RecyclerIcon';

type DeleteActionButtonProps = {
    onClick: Function;
};
export const DeleteActionButton = ({ onClick }: DeleteActionButtonProps) => {
    return (
        <Box sx={{ marginX: '10px', cursor: 'pointer' }}>
            <LoadingButton onClick={() => onClick()}>
                <RecyclerIcon width="14px" height="14px" />
            </LoadingButton>
        </Box>
    );
};
