import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/components/Button/CustomButton';

import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import { GppGood } from '@mui/icons-material';

export const ActivateUserSuccess = (props: any) => {
    return (
        <Grid style={{
            backgroundColor : 'white',
            width : '500px',
            padding : '25px',
            borderRadius : '15px'
        }}>
            <Grid container justifyContent={'center'}>
                <GppGood
                    style={{ alignSelf: 'center', marginBottom: 10 }}
                    htmlColor={baseColors.green.primary}
                    fontSize={'large'}
                ></GppGood>
            </Grid>
            <CustomText fontFamily={baseFontFamily.BreeSerif} style={{ textAlign: 'center', marginBottom: 20 }}>
                Votre compte a été activé avec succès.
            </CustomText>
            <Grid container justifyContent={'center'}>
                <Link to={ROUTES.login} style={{ textDecoration: 'none' }}>
                    <CustomButton
                        size={'large'}
                        lower
                        width={'100%'}
                        onClick={() => {}}
                        label={'Se connecter'}
                    ></CustomButton>
                </Link>
            </Grid>
        </Grid>
    );
};
export default ActivateUserSuccess;
