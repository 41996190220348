import { baseColors } from "src/utils/constants/baseStyles";

export class ErrorTextViewModel{
    private styles = {
        errorBox: {
            padding: 1,
            color: baseColors.red
        }
    };

    public getStyles() {
        return this.styles;
    }
};