import { gql } from '@apollo/client';

const ADD_QUIZZ_TO_MODULE = gql`
    mutation AddQuizzToModule($input: AddQuizzToModuleInput!) {
        addQuizzToModule(addQuizzToModuleInput: $input) {
            quizz {
                id
                type
                text
                choices {
                    text
                }
            }
            video {
                id
            }
            videoAnnote {
                id
            }
        }
    }
`;

const CREATE_NEW_MODULE = gql`
    mutation CreateNewModule($input: CreateModuleInput!) {
        createModule(createModuleInput: $input) {
            message
            code
            module {
                id
                title
                description
            }
        }
    }
`;

export { ADD_QUIZZ_TO_MODULE, CREATE_NEW_MODULE };
