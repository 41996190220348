import { AttachFile } from '@mui/icons-material';
import { Grid, IconButton, TextareaAutosize } from '@mui/material';
import { ChangeEvent, createRef, FC, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { Document } from 'src/interfaces/interfaces';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { toast } from 'react-toastify';
import CustomModal from './CustomModal';
import { useMutation } from '@apollo/client';
import { UPDATE_TOOL_BOX_MUTATION } from 'src/graphql/mutations/mutations';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { ALLOWED_EXTENSION } from 'src/utils/constants/constants';

interface EditToolBoxModalProps {
    modalDocVisible: boolean;
    toggleModal?: any;
    setUpdateList?: any;
    title: string;
    description: string;
    originalName: string;
    id: string;
    refetch: any;
}

const defaultValue: Document = { name: '', fileContent: '' };
const defaulDoctype: string = 'application/pdf';

const EditToolBoxModal: FC<EditToolBoxModalProps> = (props) => {
    const { modalDocVisible, toggleModal, setUpdateList, refetch } = props;
    const [docName, setDocName] = useState<string>(props.originalName);
    const [id, setId] = useState<string>(props.id);
    const [title, setTitle] = useState<string>(props.title);
    const [description, setDescription] = useState<string>(props.description);

    const [addingNewDoc, setAddingNewDoc] = useState<boolean>(false);
    const documentRef = createRef<HTMLInputElement>();
    const [docType, setDocType] = useState<string>(defaulDoctype);
    const [document, setDocument] = useState<Document>(defaultValue);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const [isValid, setIsValid] = useState<boolean>(true);
    let docsType: any;

    useLayoutEffect(() => {
        setIsValid(title != '' && description != '');
    }, [title, description, docName]);

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            if (!ALLOWED_EXTENSION.includes(file.type)) {
                showErrorSnackBar(
                    'documents non supporté (veuiller importe des .word, .jpeg, .jpg, .png, .pdf, .csv, .txt, .pptx',
                );
            } else {
                setDocName(file.name);
                setDocType(file.type);
                docsType = file.type;
                const reader = new FileReader();
                reader.onload = _handleDocReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    const _handleDocReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = 'data:' + docsType + ';base64,' + btoa(binaryString);
        setDocument((old: Document) => ({ ...old, fileContent }));
    };

    const clearInput = () => {
        setDocument(defaultValue);
        setDocName('');
        setDocType(defaulDoctype);
    };

    const toolBoxData: any = {
        id,
        title,
        description,
        document,
    };

    const [updateToolBox, { loading, error, data }] = useMutation(UPDATE_TOOL_BOX_MUTATION, {
        onCompleted: (data: any) => {
            console.log(data);
            showSuccessSnackBar('Document modifié avec succès');
            clearInput();
            toggleModal();
            refetch();
            setAddingNewDoc(false);
        },
        onError: () => {
            setAddingNewDoc(false);
        },
    });
    useEffect(() => {
        setId(props.id);
        setTitle(props.title);
        setDescription(props.description);
        setDocName(props.originalName);
    }, [props]);
    return (
        <CustomModal
            width={500}
            title="Modification outil"
            open={modalDocVisible ?? false}
            toggleModal={toggleModal}
            height={512}
        >
            <Grid>
                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText style={{ marginTop: 10 }}>Titre du module :</CustomText>
                    </Grid>
                    <InputField
                        label={''}
                        onChange={(value: any) => {
                            setTitle(value);
                        }}
                        validator={isNotBlank}
                        errorMessage="Titre"
                        placeholder={'Titre du module'}
                        width={400}
                        value={title}
                        idSuffix="titile_module"
                    />
                </Grid>

                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText style={{ marginTop: 10 }}>Modifier le fichier :</CustomText>
                    </Grid>
                    <IconButton onClick={() => documentRef.current?.click()}>
                        <AttachFile />
                    </IconButton>
                    <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
                    <CustomText style={{ textAlign: 'center' }} fontSize={11}>
                        {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                    </CustomText>
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems={'self-start'}
                    justifyContent="space-between"
                    style={{ marginBottom: 10, marginTop: 20 }}
                >
                    <Grid item>
                        <CustomText> Description :</CustomText>
                    </Grid>
                    <Grid item flexGrow={1} style={{ marginBottom: 10, marginLeft: '13%' }}>
                        <InputField
                            height={200}
                            label=""
                            multiline
                            placeholder={'Déscritpion du module'}
                            onChange={(value: any) => {
                                setDescription(value);
                            }}
                            width={'100%'}
                            value={description}
                            idSuffix="descripition_module"
                        />
                    </Grid>
                </Grid>
                <input
                    type="hidden"
                    value={props.id}
                    onChange={(value: any) => {
                        setId(value);
                    }}
                />
                <Grid container justifyContent={'center'}>
                    <CustomLoadingButton
                        disabled={!isValid}
                        handleClick={() => {
                            if (!isValid) return;
                            setAddingNewDoc(true);
                            updateToolBox({
                                variables: {
                                    id: toolBoxData.id,
                                    title: toolBoxData.title,
                                    description: toolBoxData.description,
                                    fileContent: toolBoxData.document.fileContent,
                                },
                            });
                        }}
                        loading={addingNewDoc}
                        width={300}
                        label={'MODIFIER'}
                    />
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default EditToolBoxModal;
