import { baseColors } from "src/utils/constants/baseStyles";

interface SelectedWorkshopIconProps {
    width: string;
    height: string;
    color?: string;
}

const selectedWorkshopIconProps = {
    width: '50',
    height: '50',
    color: baseColors.green.light,
};

export const SelectedWorkshopIcon = (props: SelectedWorkshopIconProps = selectedWorkshopIconProps) => {
    const { width, height, color } = props;

    return (
        <>
            <svg width={width} height={height} viewBox="-5 -5 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_801_20739)">
                    <rect width="40" height="40" rx="6" fill={color} stroke='white' stroke-width="1.75" stroke-linecap="round" />
                    <g clipPath="url(#clip1_801_20739)">
                        <path
                            d="M29.615 15.0534C29.9063 15.0534 30.1424 15.2895 30.1424 15.5807V20.0001L25.9122 24.7154L25.7258 18.2821L27.3157 15.0534H29.615Z"
                            fill="#F39041"
                        />
                        <path
                            d="M12.8287 15.7602L10.8267 20.0003L11.5613 26.5959L8.2831 31.1291C8.22331 31.2117 8.09267 31.1698 8.09217 31.0678L8.00259 12.7568C8.00116 12.4645 8.23768 12.2269 8.52993 12.2269H10.8267L12.8287 15.7602Z"
                            fill="#F39041"
                        />
                        <path
                            d="M27.3157 8.69342V21.4135H19.0712L17.1526 14.6139L19.0712 8.69342H27.3157Z"
                            fill="#DCEAEC"
                        />
                        <path d="M10.8267 8.69342H19.0712V21.4135H10.8267V8.69342Z" fill="#DCEAEC" />
                        <path
                            d="M20.7201 18.6422V20.7066H19.0712L18.4758 18.282L19.0712 16.1161L19.9051 16.8458C20.4228 17.2991 20.7201 17.9539 20.7201 18.6422V18.6422Z"
                            fill="#00B5BC"
                        />
                        <path
                            d="M19.0711 16.1162V20.7068H13.6533V18.6423C13.6533 17.954 13.9506 17.2992 14.4683 16.846L15.9787 15.5245H18.3946L19.0711 16.1162Z"
                            fill="#64C7C7"
                        />
                        <path
                            d="M20.9556 12.0473C20.9556 11.7561 21.1917 11.52 21.4829 11.52H24.4327C24.7239 11.52 24.96 11.7561 24.96 12.0473V12.406C24.96 12.6972 24.7239 12.9333 24.4327 12.9333H21.4829C21.1917 12.9333 20.9556 12.6972 20.9556 12.406V12.0473Z"
                            fill="#00B5BC"
                        />
                        <path
                            d="M22.3689 14.8739C22.3689 14.5827 22.605 14.3466 22.8962 14.3466H24.4327C24.7239 14.3466 24.96 14.5827 24.96 14.8739V15.2326C24.96 15.5238 24.7239 15.7599 24.4327 15.7599H22.8962C22.605 15.7599 22.3689 15.5238 22.3689 15.2326V14.8739Z"
                            fill="#00B5BC"
                        />
                        <path
                            d="M19.5423 13.8755V14.8177C19.5423 15.3468 19.367 15.8358 19.0712 16.2297L18.4758 14.5392L19.0712 12.4636C19.367 12.8574 19.5423 13.3464 19.5423 13.8755Z"
                            fill="#FFD298"
                        />
                        <path
                            d="M19.0713 12.4636V16.2297C18.6412 16.8021 17.9567 17.1734 17.1869 17.1734C15.888 17.1734 14.8313 16.1167 14.8313 14.8178V13.8756C14.8313 12.5767 15.888 11.52 17.1869 11.52C17.9567 11.52 18.6412 11.8912 19.0713 12.4636Z"
                            fill="#FFE3C1"
                        />
                        <path
                            d="M31.3169 20.0002C31.6624 20.0002 31.9145 20.3269 31.827 20.6611L29.1422 30.9132C29.0815 31.1451 28.8719 31.3069 28.6321 31.3069H19.0713L17.6372 26.2189L19.0713 20.0002H31.3169Z"
                            fill="#F39041"
                        />
                        <path
                            d="M19.0712 20.0002V26.2189V31.3069H8.3377C8.16617 31.3069 8.0403 31.1457 8.08191 30.9793L10.7268 20.3996C10.7855 20.1648 10.9964 20.0002 11.2384 20.0002H19.0712Z"
                            fill="#EB6800"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_801_20739">
                        <rect width="40" height="40" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_801_20739">
                        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
