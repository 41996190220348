import { Box, CardMedia } from '@mui/material';
import { truncate } from './ModuleItem';
import { VideoPreview } from './VideoPreview';
import { VideoContent } from './VideoContent';
import VideoPreviewViewModel from './VideoPreviewViewModel';
import { Typography } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

export interface VideoUiData {
    module: any;
    selected: boolean;
    link?: string;
}

export interface VideoItemProps {
    data: VideoUiData;
}
export const videoItemProps: VideoItemProps = {
    data: {
        module: {},
        selected: false,
        link: '',
    },
};

export const VideoItem = (props: VideoItemProps = videoItemProps) => {
    const vm = new VideoPreviewViewModel();
    const styles = vm.getStyles();
    const { data } = props;
    const { module, selected, link } = data;

    return (
        <Box style={{ display:'flex', flexDirection : 'column'}}>
            <Box
                sx={{
                    ...styles.moduleIcon,
                }}
            >
                {link && link?.length !== 0 ? (
                    <VideoContent width="100%" height="75px" src={link} isOnModule={false} />
                ) : (
                    <VideoPreview width="100%" height="75px" alt="Unavailable" />
                )}
            </Box>
            <Box
                sx={{
                    ...styles.moduleText,
                    // '@media screen and (max-width: 1000px)': {
                    //     display: 'none',
                    //     width: 1,
                    // },
                }}
            >
                <Box>
                    {selected ? (
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            color={baseColors.white}
                            fontSize={12}
                            fontWeight={600}
                        >
                            {module.title}
                        </Typography>
                    ) : (
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            color={baseColors.green.cancel}
                            fontSize={12}
                            fontWeight={600}
                        >
                            {module.title}
                        </Typography>
                    )}
                </Box>
                <Box>
                    {selected ? (
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            color={baseColors.white}
                            fontSize={10}
                            fontWeight={400}
                        >
                            {truncate(module.description)}
                        </Typography>
                    ) : (
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            color={baseColors.green.dark}
                            fontSize={10}
                            fontWeight={400}
                        >
                            {truncate(module.description)}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
