import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { PencilIcon } from 'src/components/Icon/PencilIcon';

type EditActionButtonProps = {
    onClick: Function;
};

export const EditActionButton = ({ onClick }: EditActionButtonProps) => {
    return (
        <Box sx={{ marginX: '10px', cursor: 'pointer' }}>
            <LoadingButton onClick={() => onClick()}>
                <PencilIcon width="16px" height="14px" />
            </LoadingButton>
        </Box>
    );
};
