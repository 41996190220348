import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';

export const UserConsentHeader = () => {
    return (
        <>
            <CustomText fontSize={15.33} fontFamily={baseFontFamily.OpenSansBold}>
                Recueil de consentement
            </CustomText>

            <CustomText fontSize={10.66} marginBottom={3} fontFamily={baseFontFamily.OpenSansRegular}>
                Gérez vos données personnelles.
            </CustomText>
            <CustomText fontSize={10.66} fontFamily={baseFontFamily.OpenSansRegular}>
                Cochez les cases suivants pour pouvoir utiliser l'application IVEHTE
            </CustomText>
        </>
    );
};
