import { baseColors } from 'src/utils/constants/baseStyles';

class ModuleDetailViewModel {
    private styles = {
        detailContainer: {
            width: '80%',
            height: '100%',
            paddingLeft: '5px',
            boxSizing: 'border-box',
            boxShadow: 'none',
            // backgroundColor : 'red',
        },
        detailContent: {
            backgroundColor : baseColors.green.light, 
            width: 'auto',
            height: '100%',
            // maxHeight: '100%',
            flexGrow: '0',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            // padding: 1,
            // overflow: 'auto',
            boxShadow: 'none',
            borderRadius: '8px',
        },
        detailHeader: {
            height: 'auto',
            width: '100%',
            padding : '25px',
            boxSizing : 'border-box',
            display: 'flex',
            flexDirection: 'row',
        },
        moduleImage: {
            color: 'white',
            marginRight: '25px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        moduleInfos: {
            color: 'white',
            marginLeft: '1px',
            fontFamily: 'Helvetica',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        latestPathology: {
            backgroundColor: baseColors.green.light,
            paddingX: 3,
            paddingY: '5px',
            borderRadius: '100px',
            marginTop: '6px',
        },
        detailTab: {
            width: 'auto',
            height: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            // padding: '0px 17px 0px 17px',
            // backgroundColor : 'red',    
            boxSizing: 'border-box',
        },
        separator: {
            backgroundColor: baseColors.grey,
            width: '100%',
            height: '2px',
        },
        tabMenuContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        tabItem: {
            width: 'auto',
            height: '100%',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            cursor: 'pointer',
            flexGrow: 1,
        },
        indicator: {
            width: '100%',
            height: '3px',
            backgroundColor: baseColors.green.lightPastel,
            marginTop: '15px',
            transition: 'background-color 0.15s',
        },
        hiddenIndicator: {
            width: '100%',
            height: '3px',
            backgroundColor: 'white',
            borderRadius: '2px',
            marginTop: '15px',
            transition: 'background-color 0.15s',
        },
    };
    public getStyles() {
        return this.styles;
    }
}

export default ModuleDetailViewModel;
