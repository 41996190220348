import { FC, useState } from 'react';
import { baseColors, baseFontFamily } from '../../utils/constants/baseStyles';
import { VideoCardItemProps } from './Interface/VideoCardItemProps';
import useStyles from './styles/videoCardItemStyles';
import { CustomText } from '../Text/CustomText';
import { EditVideo } from 'src/pages/EducativeBilan/TabPanel/VideoPanel/EditVideo';
import { DeleteVideo } from 'src/pages/EducativeBilan/TabPanel/VideoPanel/DeleteVideo';
import { VideoContent } from 'src/pages/Home/Content/Module/VideoContent';
import { Box } from '@mui/material';
import { Spacer } from '../Spacer/Spacer';
import DeleteIconButton from '../Icon/DeleteIconButton';
import EditIconButton from '../Icon/EditIconButton';

export const VideoCardItem: FC<VideoCardItemProps> = (props) => {
    const title = props.title;
    const moduleId = props.moduleId;
    const readOnly = props.readOnly;
    const description = props.description;
    const category = props.category;
    const fileName = props.fileName;
    const originalName = props.originalName;
    const setReloadVideo = props.setReloadVideo;
    const fileUrl = props.fileUrl;

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <>
            <EditVideo
                moduleId={moduleId}
                title={title}
                description={description}
                category={category}
                fileName={fileName}
                originalName={originalName}
                open={openEdit}
                setOpenModal={setOpenEdit}
                setReloadVideo={setReloadVideo}
            />

            <DeleteVideo open={openDelete} setOpenModal={setOpenDelete} moduleId={moduleId} category={category} />
            <Box
                sx={{
                    background: baseColors.green.light,
                    minHeight: '66.5px',
                    minWidth: '400px',
                    width: '100%',
                    display: 'flex',
                    p: '20px',
                    borderRadius: 4,
                    boxSizing: 'border-box',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '25px',
                }}
            >
                <Box display="flex" alignItems="center">
                    <div>
                        <VideoContent width={'190px'} height={'100px'} src={fileUrl} />
                    </div>
                    <div
                        style={{
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        }}
                    >
                        <CustomText fontFamily={baseFontFamily.OpenSansRegular} fontSize={14}>
                            {description}
                        </CustomText>
                    </div>
                </Box>
                <Box display="flex" alignItems="center">
                    <div
                        onClick={() => {
                            setOpenEdit(true);
                        }}
                    >
                        <EditIconButton />
                    </div>
                    <Spacer width={'10px'} />
                    <div
                        onClick={() => {
                            setOpenDelete(true);
                        }}
                    >
                        <DeleteIconButton />
                    </div>
                </Box>
            </Box>
        </>
    );
};
