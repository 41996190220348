import { Box } from '@mui/material';
import { useSettingPanelContext } from 'src/providers/SettingPanelProvider';
import { baseScrollbar } from 'src/utils/constants/baseStyles';
import { SettingMenuIcon } from 'src/utils/constants/constants';
import SettingPanelViewModel from '../SettingViewModel';
import UserSettingPanel from './UserSettingPanel/UserSettingPanel';

const SubmenuSettingPanel = () => {
    const vm = new SettingPanelViewModel();
    const styles = vm.getStyles();

    const { selectedPanel } = useSettingPanelContext();

    const handleSelectedPanel = (data: any) => {
        let panel = <></>;
        switch (data.selectedPanel) {
            case SettingMenuIcon.USER:
                panel = <UserSettingPanel />;
                break;
        }
        return panel;
    };
    return (
        <Box
            sx={{
                ...styles.box,
                marginLeft: '10px',
                flexBasis: 'auto',
                flexGrow: 1,
                padding: 4,
                overflowY: 'auto',
                ...baseScrollbar,
            }}        >
            {handleSelectedPanel({ selectedPanel })}
        </Box>
    );
};

export { SubmenuSettingPanel };
