import { Module, Video } from '../interfaces';

export const ModuleAdapter = (moduleData: any) => {
    return {
        id: moduleData.id,
        title: moduleData.title,
        description: moduleData.description || '',
        video: moduleData.video || null,
        moduleIsValidated: moduleData.moduleIsValidated || false,
        videoAnnote: moduleData.videoAnnote || null,
        quizz: moduleData.quizz || [],
        type: moduleData.type || '',
        moduleType: moduleData.moduleType || '',
    } as Module;
};
