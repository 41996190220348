import React from 'react';

interface EditIconButtonProps {
    size?: string;
}

const EditIconButton: React.FC<EditIconButtonProps> = ({ size }) => {
    return (
        <svg
            width={size || '40'}
            height={size || '41'}
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="0.228516" width="40" height="40" rx="10" fill="#3EABAB" />
            <path
                d="M18.6621 25.6848C18.9008 25.4987 19.1172 25.2823 19.5501 24.8494L25.097 19.3025C24.342 18.9883 23.4479 18.4722 22.6022 17.6265C21.7565 16.7808 21.2403 15.8865 20.9261 15.1314L15.3791 20.6784L15.3791 20.6785C14.9462 21.1113 14.7298 21.3278 14.5437 21.5664C14.3241 21.8479 14.1359 22.1525 13.9823 22.4748C13.852 22.748 13.7553 23.0384 13.5617 23.6191L12.5408 26.6816C12.4456 26.9674 12.52 27.2825 12.733 27.4955C12.946 27.7086 13.2611 27.7829 13.5469 27.6877L16.6094 26.6668C17.1901 26.4733 17.4805 26.3765 17.7537 26.2463C18.076 26.0927 18.3806 25.9044 18.6621 25.6848Z"
                fill="white"
            />
            <path
                d="M26.6362 17.7633C27.7879 16.6115 27.7879 14.7441 26.6362 13.5923C25.4844 12.4406 23.617 12.4406 22.4652 13.5923L21.7999 14.2576C21.809 14.2851 21.8185 14.313 21.8283 14.3413C22.0721 15.0441 22.5322 15.9655 23.3977 16.831C24.2632 17.6965 25.1846 18.1566 25.8875 18.4005C25.9156 18.4102 25.9434 18.4196 25.9708 18.4287L26.6362 17.7633Z"
                fill="white"
            />
        </svg>
    );
};

export default EditIconButton;
