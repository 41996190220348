import { Box, Paper } from '@mui/material';

import { BilanTabMenuIconName, ModuleType } from 'src/utils/constants/constants';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { ModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import ModuleDetailPanel from './ModuleDetailPanel';
import ModuleListPanel from '../Home/Content/Module/ModuleListPanel';
import { useEffect, useState } from 'react';
import { CurrentModuleContext } from 'src/providers/ModuleListProvider';
import { Module } from 'src/interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { setFirstModule } from 'src/redux/features/listModules/listModulesSlice';

export const EducativeBilanPanel = (props: any) => {
    const dispatch = useDispatch();
    // useEffect(() => {
    //     console.log('use effect bilan');

    //     dispatch(setFirstModule);
    // }, []);
    const viewModel = new ContentPanelViewModel();
    const styles = viewModel.getStyles();
    const [selectedPanel, setSelectedPanel] = useState<string>(BilanTabMenuIconName.INFORMATION);
    const [isCreatingNewModule, setIsCreatingNewModule] = useState<boolean>(false);
    const [disableSoumettreButton, setDisableSoumettreButton] = useState<boolean>(false);
    const [isRefreshListModule, setIsRefreshListModule] = useState<boolean>(false);
    const [selectedModuleId, setSelectedModuleId] = useState<string>('');
    const [currentModule, setCurrentModule] = useState<Module>();
    const [modules, setModules] = useState<Module[]>([]);

    return (
        <Box sx={styles.rootPanel}>
            <Paper sx={styles.paperPanel}>
                <CurrentModuleContext.Provider
                    value={{
                        currentModule,
                        setCurrentModule,
                        modules,
                        setModules,
                    }}
                >
                    <ModuleDetailContext.Provider
                        value={{
                            selectedPanel,
                            setSelectedPanel,
                            isCreatingNewModule,
                            isRefreshListModule,
                            setIsRefreshListModule,
                            setIsCreatingNewModule,
                            disableSoumettreButton,
                            setDisableSoumettreButton,
                        }}
                    >
                        <ModuleListPanel
                            setIsCreatingNewModule={setIsCreatingNewModule}
                            moduleType={ModuleType.EDUCATIVE_BILAN}
                            // onSelectionChange={setSelectedModuleId}
                        />

                        <ModuleDetailPanel
                            moduleType={ModuleType.EDUCATIVE_BILAN}
                            // moduleId={selectedModuleId}
                            setSelectedModuleId={setSelectedModuleId}
                            disableSoumettreButton={disableSoumettreButton}
                            setDisableSoumettreButton={setDisableSoumettreButton}
                        />
                    </ModuleDetailContext.Provider>
                </CurrentModuleContext.Provider>
            </Paper>
        </Box>
    );
};
