import * as React from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useContext, useEffect, useState } from 'react';

import { SnackBarContext } from 'src/providers/SnackBarProvider';
import Snackbar from '@mui/material/Snackbar';

interface ICustomSnackbarProps {
    open: boolean,
    message: string,
    severity: string
};

const CustomSnackbarProps = {
    open: false,
    message: '',
    severity: 'success'
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar: React.FC<ICustomSnackbarProps> = (props: ICustomSnackbarProps = CustomSnackbarProps) => {
    const {open, message, severity, ...otherProps} = props;
    
    const { showSnackBar, setShowSnackBar, snackBarMessage, snackBarSeverity } = useContext(SnackBarContext);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackBar(false);
    };

    return (
        <Snackbar open={showSnackBar} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal:'right'}} {...otherProps}>
            <Alert onClose={handleClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                {snackBarMessage}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
