import { Grid, Radio } from '@mui/material';
import React, { FC } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';

interface CustomRadioProps {
    label?: string;
    checked?: boolean;
    onClick?: Function;
    color?: string;
    disabled?: boolean;
    fontFamily?: string;
    style?: React.CSSProperties;
    paddingTop?: number;
    labelSize?: number;
}
export const CustomRadio: FC<CustomRadioProps> = (props) => {
    const { paddingTop, style, label, checked, onClick, color, disabled, fontFamily, labelSize } = props;

    return (
        <Grid
            direction="row"
            container
            style={
                style
                    ? { ...style, paddingTop: paddingTop ? paddingTop : 5 }
                    : { paddingTop: paddingTop ? paddingTop : 5 }
            }
            alignContent={'flex-start'}
            alignItems="center"
        >
            <Grid item>
                <Radio
                    disabled={disabled && disabled}
                    onClick={() => (onClick ? onClick() : {})}
                    sx={{
                        color: color ? color : baseColors.green.primary,
                        '&.Mui-checked': {
                            color: color ? color : baseColors.green.primary,
                        },
                    }}
                    checked={checked ? checked : false}
                />
            </Grid>
            <Grid item flex={8}>
                <CustomText fontSize={labelSize ? labelSize : 14} fontFamily={fontFamily && fontFamily}>
                    {label ? label : 'Oui'}
                </CustomText>
            </Grid>
        </Grid>
    );
};
