import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomButton } from 'src/components/Button/CustomButton';
import { InputField } from 'src/components/InputField/InputField';
import { CREATE_NEW_MODULE } from 'src/graphql/mutations/ModuleMutation';
import { ModuleAdapter } from 'src/interfaces/adapter/ModuleAdapter';
import { Module } from 'src/interfaces/interfaces';
import { ModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
// import { selectCurrentModule, setCurrentModule } from 'src/redux/features/currentModule/currentModuleSlice';
import { addModule, setCurrentModule, selectCurrentModule, setCurrentModuleId } from 'src/redux/features/listModules/listModulesSlice';

const CreateNewModuleInformation = (props: any) => {
    const { moduleType, onCreatedModule } = props;
    const [module, setModule] = useState<Module>({
        id: '',
        title: '',
        description: '',
        moduleType: moduleType,
        video: {
            id: '',
            title: '',
            description: '',
        },
        moduleIsValidated: false,
        videoAnnote: {
            id: '',
            title: '',
            description: '',
        },
        quizz: '',
        type: '',
    });

    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const { setIsRefreshListModule } = useContext(ModuleDetailContext);
    const [createNewModule, mutationData] = useMutation(CREATE_NEW_MODULE, {
        onCompleted(data, clientOptions?) {
            // console.log(data);
            // window.location.reload();
            onCreatedModule(data?.createModule?.module);
        },
        onError(error, clientOptions?) {
            console.log(error);
        },
    });

    const update = (key: string, value: any) => {
        setModule((oldModule) => {
            return { ...oldModule, [key]: value };
        });
    };

    const dispatch = useDispatch();

    const saveModuleToBackend = () => {
        if (module.title !== '') {
            createNewModule({
                variables: {
                    input: {
                        title: module.title,
                        description: module.description,
                        type: module.moduleType,
                    },
                },
                onCompleted: (data) => {
                    //AddModule() for the new module to the store
                    console.log(data);
                    const newModule = ModuleAdapter(data.createModule.module)
                    dispatch(addModule(newModule));
                    // dispatch(setCurrentModule(ModuleAdapter(data.createModule.module)));
                    dispatch(setCurrentModuleId(newModule.id));
                    showSuccessSnackBar();
                    // setIsRefreshListModule?.(true);
                },
            });
        } else {
            showErrorSnackBar('Veuillez entrer un titre pour le module');
        }
    };
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <InputField
                    label={'Titre du module : '}
                    placeholder="Titre"
                    value={module.title}
                    onChange={(value: any) => update('title', value)}
                    width={'50%'}
                    height={'50px'}
                    labelSize={'14'}
                />

                <InputField
                    label={'Description : '}
                    placeholder="Description"
                    value={module.description}
                    onChange={(value: any) => update('description', value)}
                    width={'50%'}
                    height={'85px'}
                    labelSize={'14'}
                    multiline={true}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <CustomButton
                    width={'15%'}
                    label={'Suivant'}
                    fontSize={11}
                    lower={true}
                    onClick={saveModuleToBackend}
                ></CustomButton>
            </div>
        </>
    );
};

export default CreateNewModuleInformation;
