import { makeStyles } from '@mui/styles';
import { baseColors } from '../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        height: '100vh',
        backgroundColor: baseColors.green.light,
    },
    content: {
        height: '100vh',
        backgroundColor: baseColors.green.light,
    },
});

export default useStyles;
