import { baseColors } from "src/utils/constants/baseStyles";

class SettingPanelViewModel {
    private style = {
        box: {
            backgroundColor: 'white',
            height: '100%',
            borderRadius: 2,
            flexShrink: '0',
            boxSizing: 'border-box'
        },
        sidebarMenuItem: {
            display: 'flex',
            flexDirection: 'row',
            margin: '1px',
            marginY: '10px',
            marginLeft: '7px',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        title: {
            fontFamily: 'Helvetica, Open Sans',
            fontSize: '11pt',
            fontWeight: 'none',
            display: 'flex',
            alignItems: 'center',
        },
        titleBlock: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        icon: {
            display: 'flex',
            alignItems: 'center',

            //     backgroundColor: 'green',
        },
        indicator: {
            width: '4px',
            height: '75%',
            backgroundColor: baseColors.orange.dark,
            borderRadius: '2px',
        },
        hiddenIndicator: {
            width: '5px',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: '2px',
        },
    };
    public getStyles() {
        return this.style;
    }

    public handleIndicator(selected: boolean) {
        return selected ? this.style.indicator : this.style.hiddenIndicator;
    }
}

export default SettingPanelViewModel;