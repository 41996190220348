import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { DownloadIcon } from 'src/components/Icon/DownloadIcon';

type DownloadActionButtonProps = {
    onClick: Function;
};

export const DownloadActionButton = ({ onClick }: DownloadActionButtonProps) => {
    return (
        <Box sx={{ marginX: '10px', cursor: 'pointer' }}>
            <LoadingButton onClick={() => onClick()}>
                <DownloadIcon width="16px" height="14px" />
            </LoadingButton>
        </Box>
    );
};
