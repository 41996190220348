import { useQuery } from '@apollo/client';
import { Grid, List } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { GET_TRAME_STUDENT } from 'src/graphql/queries/queries';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { ContactUs } from './ContactUs';
import { ModuleType } from 'src/utils/constants/constants';
import { CustomText } from 'src/components/Text/CustomText';
import ListItemPdf from './ListItemPdf';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';

const SectionEvaluation: FC<any> = () => {
    const [open, setOpen] = useState(false);
    const [currentPdfResponseId, setCurrentPdfResponseId] = useState('default');
    const [trameList, setTrameList] = useState({
        educativeBilan: [],
        educativeWorkshop: [],
    });
    const moduleType = ModuleType.EDUCATIVE_BILAN;
    const {
        loading: loadingUserTrameInfo,
        data: userTrameData,
        error: errorGetUserTrameInfo,
    } = useQuery(GET_TRAME_STUDENT, {
        variables: { moduleType },
    });

    const adaptTrame = (trame: any) => {
        return {
            moduleId: trame.module.id,
            fileId: trame.pdfResponse.id,
            fileName: trame.pdfResponse.fileName,
            originalName: trame.pdfResponse.originalName,
            filePath: trame.pdfResponse.filePath,
            fileUrl: trame.pdfResponse.fileUrl,
            createdAt: trame.pdfResponse.createdAt,
        };
    };
    useEffect(() => {
        if (userTrameData?.getUserTrameByModuleId) {
            let data = userTrameData.getUserTrameByModuleId;
            setTrameList(() => {
                return {
                    educativeBilan: data.educativeBilan.map(adaptTrame),
                    educativeWorkshop: data.educativeWorkshop.map(adaptTrame),
                };
            });
        }
    }, [userTrameData]);

    return (
        <Grid container>
            {loadingUserTrameInfo ? (
                <CustomCircleLoader></CustomCircleLoader>
            ) : (
                <Grid item xs={12}>
                    <CustomText fontSize={13.333} fontFamily={baseFontFamily.BreeSerif}>
                        Bilan éducatif
                    </CustomText>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="contacts">
                        {trameList.educativeBilan.length <= 0 && <CustomText>Aucun fichier</CustomText>}
                        {trameList.educativeBilan.map((trame: any) => (
                            <ListItemPdf
                                key={trame.moduleId}
                                trame={trame}
                                setOpen={setOpen}
                                setCurrentPdfResponseId={setCurrentPdfResponseId}
                            ></ListItemPdf>
                        ))}
                    </List>
                    <CustomText fontSize={13.333} fontFamily={baseFontFamily.BreeSerif}>
                        Atelier éducatif
                    </CustomText>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="contacts">
                        {trameList.educativeWorkshop.length <= 0 && <CustomText>Aucun fichier</CustomText>}
                        {trameList.educativeWorkshop.map((trame: any) => (
                            <ListItemPdf
                                key={trame.moduleId}
                                trame={trame}
                                setOpen={setOpen}
                                setCurrentPdfResponseId={setCurrentPdfResponseId}
                            ></ListItemPdf>
                        ))}
                    </List>
                    <ContactUs fileId={currentPdfResponseId} open={open} setOpen={setOpen} />
                </Grid>
            )}
        </Grid>
    );
};

export default SectionEvaluation;
