import { Grid, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import { GppBad, GppGood } from '@mui/icons-material';

export const PasswordResetError = (props: any) => {
    return (
        <Grid>
            <Grid container justifyContent={'center'}>
                <GppBad
                    style={{ alignSelf: 'center', marginBottom: 10 }}
                    htmlColor={baseColors.orange.primary}
                    fontSize={'large'}
                ></GppBad>
            </Grid>
            <Grid container direction="column" justifyContent={'center'}>
                <CustomText
                    color={baseColors.orange.flash}
                    fontFamily={baseFontFamily.BreeSerif}
                    style={{ textAlign: 'center', marginBottom: 15 }}
                    fontSize={15}
                >
                    Il semble que ce lien que vous avez utilisé n'est plus valide.
                </CustomText>
                <Link
                    style={{
                        fontFamily: baseFontFamily.BreeSerif,
                        marginLeft: 10,
                        color: baseColors.green.primary,
                        fontSize : '18px'
                    }}
                    to={ROUTES.passwordForgot}
                >
                    cliquer ici pour renvoyer un lien vers votre e-mail
                </Link>
            </Grid>
        </Grid>
    );
};
export default PasswordResetError;
