import React, { FC, ReactNode, useState } from 'react';

import { AlertColor } from '@mui/material';
import { createContext } from 'react';

type SnackBarType = {
    showSnackBar: boolean;
    setShowSnackBar: (val: boolean) => void;
    snackBarMessage: string;
    setSnackBarMessage: (val: string) => void;
    snackBarSeverity: AlertColor;
    setSnackBarSeverity: (val: AlertColor) => void;
    showSuccessSnackBar: (message?: string) => void;
    showErrorSnackBar: (message?: string) => void;
};

export const SnackBarContext = createContext<SnackBarType>({
    showSnackBar: false,
    setShowSnackBar: () => {},
    snackBarMessage: 'Opération réalisée avec succès',
    setSnackBarMessage: () => {},
    snackBarSeverity: 'success',
    setSnackBarSeverity: () => { },
    showSuccessSnackBar: () => {},
    showErrorSnackBar: () => {}
});

type ISnackBarTypeProps = {
    children: ReactNode
};

const SnackBarProvider = (props: ISnackBarTypeProps) => {
    const { children } = props;
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>('Opération réalisée avec succès');
    const [snackBarSeverity, setSnackBarSeverity] = useState<AlertColor>('success');
    
    const showSuccessSnackBar = (message?: string) => {
        if (!message) {
            setSnackBarMessage('Opération réalisée avec succès');
        } else {
            setSnackBarMessage(message);
        }

        setSnackBarSeverity('success');
        setShowSnackBar(true);
    };

    const showErrorSnackBar = (message?: string) => {
        if (!message) {
            setSnackBarMessage('Une erreur est survenue');
        } else {
            setSnackBarMessage(message);
        }

        setSnackBarSeverity('error');
        setShowSnackBar(true);
    };

    return (
        <SnackBarContext.Provider
            value={{
                showSnackBar,
                setShowSnackBar,
                snackBarMessage,
                setSnackBarMessage,
                snackBarSeverity,
                setSnackBarSeverity,
                showSuccessSnackBar,
                showErrorSnackBar
            }}
        >
            {children}
        </SnackBarContext.Provider>
    );
};

export default SnackBarProvider;
