import { AttachFile } from '@mui/icons-material';
import { Grid, IconButton, TextareaAutosize } from '@mui/material';
import { createRef, FC, useContext, useLayoutEffect, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { CustomText } from 'src/components/Text/CustomText';
import { Document } from 'src/interfaces/interfaces';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from './CustomModal';
import { useMutation } from '@apollo/client';
import { ADD_TOOL_BOX_MUTATION } from 'src/graphql/mutations/mutations';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { ALLOWED_EXTENSION } from 'src/utils/constants/constants';

interface AttachFileModalProps {
    modalDocVisible: boolean;
    toggleModal?: any;
    setUpdateList?: any;
    refetch: any;
}

const defaultValue: Document = { name: '', fileContent: '' };
const defaulDoctype: string = 'application/pdf';

const AttachFileModal: FC<AttachFileModalProps> = (props) => {
    const { modalDocVisible, toggleModal, setUpdateList, refetch } = props;
    const [docName, setDocName] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [addingNewDoc, setAddingNewDoc] = useState<boolean>(false);
    const documentRef = createRef<HTMLInputElement>();
    const [docType, setDocType] = useState<string>(defaulDoctype);
    const [document, setDocument] = useState<Document>(defaultValue);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const [isValid, setIsValid] = useState<boolean>(false);
    let docsType: any;
    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            if (!ALLOWED_EXTENSION.includes(file.type)) {
                showErrorSnackBar(
                    'documents non supporté (veuiller importe des fichier .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .csv, .txt, .pptx',
                );
                setDocName('');
            } else {
                setDocName(file.name);
                setDocType(file.type);
                docsType = file.type;
                const reader = new FileReader();
                reader.onload = _handleDocReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };
    const _handleDocReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = 'data:' + docsType + ';base64,' + btoa(binaryString);
        setDocument((old: Document) => ({ ...old, fileContent }));
    };

    const clearInput = () => {
        setTitle('');
        setDescription('');
        setDocument(defaultValue);
        setDocName('');
        /*  setDocType(defaulDoctype); */
    };

    const [saveToolBox, { loading, error, data }] = useMutation(ADD_TOOL_BOX_MUTATION, {
        onCompleted: () => {
            showSuccessSnackBar('Document ajouté avec succès');
            setAddingNewDoc(false);
            clearInput();
            toggleModal();
            refetch();
        },
        onError: () => {
            setAddingNewDoc(false);
        },
    });

    useLayoutEffect(() => {
        setIsValid(title != '' && description != '' && docName != '');
    }, [title, description, docName]);

    return (
        <CustomModal
            width={500}
            title="Nouvel outil"
            open={modalDocVisible ?? false}
            toggleModal={toggleModal}
            height={512}
        >
            <Grid>
                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText style={{ marginTop: 10 }}>Titre du module :</CustomText>
                    </Grid>
                    <InputField
                        label={''}
                        onChange={(value: any) => {
                            setTitle(value);
                        }}
                        validator={isNotBlank}
                        errorMessage="Titre"
                        placeholder={'Titre du module'}
                        width={400}
                        value={title}
                        idSuffix="titile_module"
                    />
                </Grid>

                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText style={{ marginTop: 10 }}>Importer le fichier :</CustomText>
                    </Grid>
                    <IconButton onClick={() => documentRef.current?.click()}>
                        <AttachFile />
                    </IconButton>
                    <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
                    <CustomText style={{ textAlign: 'center' }} fontSize={11}>
                        {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                    </CustomText>
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems={'self-start'}
                    justifyContent="space-between"
                    style={{ marginBottom: 10, marginTop: 20 }}
                >
                    <Grid item>
                        <CustomText> Description :</CustomText>
                    </Grid>
                    <Grid item flexGrow={1} style={{ marginBottom: 10, marginLeft: '13%' }}>
                        <InputField
                            height={200}
                            label=""
                            multiline
                            placeholder={'Déscritpion du module'}
                            onChange={(value: any) => {
                                setDescription(value);
                            }}
                            width={'100%'}
                            value={description}
                            idSuffix="descripition_module"
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                    {loading === true ? (
                        <CustomCircleLoader width={30} />
                    ) : (
                        <CustomLoadingButton
                            disabled={!isValid}
                            handleClick={() => {
                                if (!isValid) return;
                                setAddingNewDoc(true);
                                saveToolBox({
                                    variables: {
                                        title: title,
                                        description: description,
                                        fileContent: document.fileContent,
                                    },
                                });
                            }}
                            loading={addingNewDoc}
                            width={300}
                            label={'ENREGISTRER'}
                        />
                    )}
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default AttachFileModal;
