import React, { FC } from 'react';
import { Button, DialogContentText, Typography, Grid } from '@mui/material';
import { Dialog } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { baseColors, baseFontFamily } from '../../utils/constants/baseStyles';

interface ConfirmDialogProps {
    title: string;
    message: string;
    onConfirm: Function;
    onCancel: Function;
    visible: boolean;
    cancelLabel?: string;
    confirmLabel?: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
    const { onCancel, title, message, onConfirm, visible, cancelLabel, confirmLabel } = props;

    return (
        <div>
            <Dialog
                open={visible}
                keepMounted
                onClose={() => onCancel()}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Typography fontSize={15}>{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography fontSize={14}>{message}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid md={6} sm={12} >
                        <Button style={{ color: baseColors.green.primary }} onClick={() => onCancel()}>
                            <Typography fontSize={13}>{cancelLabel ?? 'Rester'}</Typography>
                        </Button>
                    </Grid>
                    <Grid md={6} sm={12}>
                        <Button color={'error'} onClick={() => onConfirm()}>
                            <Typography fontSize={13}>{confirmLabel ?? 'Se déconnecter'}</Typography>
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};
