import { Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { GppBad } from '@mui/icons-material';

export const ActivateUserError = (props: any) => {
    return (
        <Grid
            style={{
                backgroundColor: 'white',
                width: '500px',
                padding: '25px',
                borderRadius: '15px',
            }}
        >
            <Grid container justifyContent={'center'}>
                <GppBad
                    style={{ alignSelf: 'center', marginBottom: 10 }}
                    htmlColor={baseColors.orange.primary}
                    fontSize={'large'}
                ></GppBad>
            </Grid>
            <Grid container direction="row" justifyContent={'center'}>
                <CustomText
                    color={baseColors.orange.flash}
                    fontFamily={baseFontFamily.BreeSerif}
                    style={{ textAlign: 'center', marginBottom: 10 }}
                >
                    Il semble que ce lien que vous avez utilisé n'est plus valide.
                </CustomText>
            </Grid>
        </Grid>
    );
};
export default ActivateUserError;
