import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { baseColors } from '../../utils/constants/baseStyles';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { specialCharToUnderscore } from 'src/utils/utils';

type AppProps = {
    label: string;
    placeholder: string;
    onChange: Function;
    width: any;
    height?: any;
    type?: string;
    disabled?: boolean;
    value?: string;
    fontSize?: string;
    labelSize?: string;
};

const useStyles = makeStyles({
    root: {
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
});

const CustomTextField = styled(TextField)({
    marginTop: 10,
    '& label.Mui-focused': {
        color: baseColors.green.primary,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: baseColors.green.primary,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: baseColors.grey,
            borderRadius: 10,
        },
        '&:hover fieldset': {
            borderColor: baseColors.black,
        },
        '&.Mui-focused fieldset': {
            borderColor: baseColors.green.primary,
        },
    },
});

export const InputFieldPassword = ({ disabled, type, value, label, placeholder, onChange, width, height, fontSize, labelSize }: AppProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const classes = useStyles();

    return (
        <div style={{ width: width }}>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    marginBottom: 20,
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Typography color={baseColors.black} fontSize={labelSize? labelSize : '15px'}>
                    {label}
                </Typography>

                <CustomTextField
                    value={value}
                    disabled={disabled && disabled}
                    id={specialCharToUnderscore(label ?? '')}
                    size="medium"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(event.target.value);
                    }}
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder={placeholder}
                    style={{
                        marginTop: 10,
                    }}
                    InputProps={{
                        style: { fontSize: fontSize ? fontSize : 15 },
                        className: classes.root,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                >
                                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </div>
    );
};
